import { FLUID_ROUTES } from 'utils/consts';
import { roundTo } from 'utils/helpers/format';
import { Package, PackageInstruction, PackageInstructionType } from 'utils/types';
import emptySplitApi, { PACKAGE_TAG_TYPE } from './emptySplitApi';

interface GetPackagesParams {}

interface GetPackageParams {
    id: string;
}

interface CreatePackageParams {
    name: string;
}

interface AddPackageInstructionBase {
    package_id: string;
    instruction_id: number;
    toggled: boolean;
    start_delay_mins?: number;
    duration_mins?: number;
}

interface AddPackageMedication extends AddPackageInstructionBase {
    type_id: PackageInstructionType.MEDICATION;
    frequency: string | null;
    route_id: string;
}
interface AddPackageDiagnostic extends AddPackageInstructionBase {
    type_id: PackageInstructionType.DIAGNOSTIC;
    frequency: string | null;
}

interface APIFluids {
    rate_mcl_per_hr: number | null; //Rate of FLUID, sent to database
}

interface FrontendFluids {
    rate_ml_per_hr: number | null; //Rate of FLUID infusion, set on pump
}

interface APIAddPackageFluid extends AddPackageInstructionBase, APIFluids {
    type_id: PackageInstructionType.FLUID;
    route_id: (typeof FLUID_ROUTES)[number];
    fluids_volume_ml: number;
    frequency: string | null;
}

interface AddPackageFluid extends AddPackageInstructionBase, FrontendFluids {
    type_id: PackageInstructionType.FLUID;
    route_id: (typeof FLUID_ROUTES)[number];
    fluids_volume_ml: number;
    frequency: string | null;
}

interface AddPackageOxygenTherapy extends AddPackageInstructionBase {
    type_id: PackageInstructionType.OXYGEN_THERAPY;
    quantity: number | null;
}

export const isInstanceOfAddPackageFluid = (item: any): item is AddPackageFluid => (item as AddPackageFluid).rate_ml_per_hr !== undefined;

export const frontendFluidToBackendFluid = (fluid: AddPackageFluid): APIAddPackageFluid => {
    const { rate_ml_per_hr, ...restofFluid } = fluid;
    return {
        ...restofFluid,
        rate_mcl_per_hr: rate_ml_per_hr ? roundTo(rate_ml_per_hr, 2) * 1000 : null,
    };
};

interface APICri {
    rate_mcl_per_hr: number | null; //Rate of FLUID, sent to database
}

interface FrontendCri {
    rate_ml_per_hr: number | null; //Rate of FLUID infusion, set on pump
}

interface AddPackageCRI extends AddPackageInstructionBase, FrontendCri {
    type_id: PackageInstructionType.CRI;
    dose?: number;
    fluids_id?: number;
    fluids_volume_ml?: number;
}

export const isInstanceOfAddPackageCri = (item: any): item is AddPackageCRI => (item as AddPackageCRI).rate_ml_per_hr !== undefined;

export const frontendCriToBackendCri = (fluid: AddPackageCRI): APIAddPackageCRI => {
    const { rate_ml_per_hr, ...restofCri } = fluid;
    return {
        ...restofCri,
        rate_mcl_per_hr: rate_ml_per_hr ? roundTo(rate_ml_per_hr, 2) * 1000 : null,
    };
};

interface APIAddPackageCRI extends AddPackageInstructionBase, APICri {
    type_id: PackageInstructionType.CRI;
}

interface AddPackageTask extends AddPackageInstructionBase {
    type_id: PackageInstructionType.TASK;
    frequency: string | null;
}

type AddPackageInstructionParams =
    | AddPackageMedication
    | AddPackageDiagnostic
    | AddPackageTask
    | AddPackageCRI
    | AddPackageFluid
    | AddPackageOxygenTherapy;

type RemovePackageInstructionParams = Pick<PackageInstruction, 'id' | 'package_id' | 'type_id'>;

const setPackageInstructionDefaults = (packageInstruction: AddPackageInstructionParams): AddPackageInstructionParams => {
    return {
        ...packageInstruction,
        toggled: packageInstruction.toggled ?? true,
        start_delay_mins: packageInstruction.start_delay_mins,
        duration_mins: packageInstruction.duration_mins,
    };
};

export const packageApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getPackages: builder.query<Package[], GetPackagesParams>({
            query: (searchQuery) => `package`,
            transformResponse: (response: { data: Package[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: PACKAGE_TAG_TYPE, id: 'LIST' }],
        }),
        getPackage: builder.query<Package, GetPackageParams>({
            query: ({ id }) => `package/${id}`,
            transformResponse: (response: { data: Package }) => {
                return response.data;
            },
            providesTags: (data, er, { id }) => {
                return [{ type: PACKAGE_TAG_TYPE, id }];
            },
        }),
        createPackage: builder.mutation<Package, CreatePackageParams>({
            query({ name }) {
                return {
                    url: `package`,
                    method: 'POST',
                    body: {
                        name,
                    },
                };
            },
            transformResponse: (response: { data: Package }) => {
                return response.data;
            },
            invalidatesTags: () => [{ type: PACKAGE_TAG_TYPE, id: 'LIST' }],
        }),
        addPackageInstruction: builder.mutation<void, AddPackageInstructionParams>({
            query(packageParams) {
                let { package_id, instruction_id, type_id, ...body } = setPackageInstructionDefaults(packageParams);

                if (isInstanceOfAddPackageFluid(body)) {
                    body = frontendFluidToBackendFluid(body);
                }

                return {
                    url: `package/${package_id}/add_to_package/${type_id}/${instruction_id}`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: (result, _, { package_id }) => {
                return [
                    { type: PACKAGE_TAG_TYPE, id: 'LIST' },
                    { type: PACKAGE_TAG_TYPE, id: package_id },
                ];
            },
        }),
        editPackageInstruction: builder.mutation<void, PackageInstruction>({
            query({ id, type_id, ...body }) {
                return {
                    url: `package/update_package_item/${type_id}/${id}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, _, { package_id }) => {
                return [
                    { type: PACKAGE_TAG_TYPE, id: 'LIST' },
                    { type: PACKAGE_TAG_TYPE, id: package_id },
                ];
            },
        }),
        removePackageInstruction: builder.mutation<void, RemovePackageInstructionParams>({
            query({ id, type_id }) {
                return {
                    url: `package/remove_from_package/${type_id}/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (result, _, { package_id }) => {
                return [
                    { type: PACKAGE_TAG_TYPE, id: 'LIST' },
                    { type: PACKAGE_TAG_TYPE, id: package_id },
                ];
            },
        }),
        deletePackage: builder.mutation<void, number>({
            query: (package_id) => ({
                url: `package/${package_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => {
                return [{ type: PACKAGE_TAG_TYPE, id: 'LIST' }];
            },
        }),
        editPackage: builder.mutation<void, { packageId: number; name: string }>({
            query: ({ packageId, name }) => ({
                url: `package/${packageId}`,
                method: 'PUT',
                body: { name },
            }),
            invalidatesTags: (result, _, { packageId }) => [{ type: PACKAGE_TAG_TYPE, id: packageId }],
        }),
    }),
});

export const {
    useGetPackagesQuery,
    useGetPackageQuery,
    useCreatePackageMutation,
    useAddPackageInstructionMutation,
    useRemovePackageInstructionMutation,
    useEditPackageInstructionMutation,
    useEditPackageMutation,
    useDeletePackageMutation,
} = packageApi;
