import { useMemo } from 'react';
import { useGetUserDataQuery } from 'services/auth.service';
import { BASE_QUERY_OPTIONS } from 'utils/consts';

const useIsUserAuthenticated = () => {
    let { data: loggedInUserData, isFetching } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    let isUserAuthenticated = useMemo(() => {
        return { loggedIn: typeof loggedInUserData !== 'undefined', isFetching };
    }, [loggedInUserData, isFetching]);

    return [isUserAuthenticated];
};

export default useIsUserAuthenticated;
