import { NoteMacro } from 'utils/types';
import emptySplitApi, { MACRO_TAG_TYPE } from './emptySplitApi';

export type CreateMacroParams = Omit<NoteMacro, 'id'>;

export type EditMacroParams = NoteMacro;

export const macrosApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getMacros: builder.query<NoteMacro[], void>({
            query: (searchQuery) => `macro`,
            transformResponse: (response: { data: NoteMacro[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: MACRO_TAG_TYPE, id: 'LIST' }],
        }),
        createMacro: builder.mutation<NoteMacro, CreateMacroParams>({
            query(body) {
                return {
                    url: `macro`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: { data: NoteMacro }) => {
                return response.data;
            },
            invalidatesTags: (result, error, id) => [{ type: MACRO_TAG_TYPE, id: 'LIST' }],
        }),
        editMacro: builder.mutation<NoteMacro, EditMacroParams>({
            query({ id, ...rest }) {
                return {
                    url: `macro/${id}`,
                    method: 'PUT',
                    body: rest,
                };
            },
            transformResponse: (response: { data: NoteMacro }) => {
                return response.data;
            },
            invalidatesTags: (result, error, id) => [{ type: MACRO_TAG_TYPE, id: 'LIST' }],
        }),
    }),
});

export const { useGetMacrosQuery, useCreateMacroMutation, useEditMacroMutation } = macrosApi;
