export const rdvmEmailAndPhoneWarningConfig = (emailWarning: boolean, phoneWarning: boolean, onOk: (...args: any[]) => any) => {
    const warnings = [emailWarning ? 'Email' : null, phoneWarning ? 'Phone' : null].filter((i) => i !== null);
    const textFields = warnings.join(' and ');
    return {
        title: `${textFields} Already in Use`,
        content: (
            <>
                <p>An rDVM with this {textFields.toLowerCase()} already exists.</p>
                <p>If this is an error, click "Cancel" to return to editing the rDVM's information.</p>
            </>
        ),
        onOk: onOk,
        okText: 'Save',
    };
};
