import { Button, Col, Row, Space, SpacedContent } from 'components/lib';
import { format } from 'utils/helpers';
import { roundTo } from 'utils/helpers/format';
import { PackageInstruction, PackageInstructionType } from 'utils/types';
import instructionStyles from './instruction.module.css';

interface InstructionDetailProps {
    instruction: PackageInstruction;
    handleEdit: (instruction: PackageInstruction) => void;
    handleRemove: (instruction: PackageInstruction) => void;
}

const InstructionDetail: React.FC<InstructionDetailProps> = ({ instruction, handleEdit, handleRemove }) => {
    const hasFrequency = () => {
        return (
            instruction.type_id === PackageInstructionType.DIAGNOSTIC ||
            instruction.type_id === PackageInstructionType.MEDICATION ||
            instruction.type_id === PackageInstructionType.TASK
        );
    };

    return (
        <div style={{ padding: '0 0 0 1rem' }}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <div className={instructionStyles.title}>{instruction.name}</div>
                <Row>
                    <Col xs={24} xl={21}>
                        <Space direction='vertical' style={{ width: 'calc(100% - 100px)', maxWidth: 400 }}>
                            <SpacedContent>
                                <b>Toggled:</b>
                                <div>{instruction.toggled ? 'True' : 'False'}</div>
                            </SpacedContent>
                            <SpacedContent>
                                <b>Starting:</b>
                                <div>{format.minutesToHours(instruction.start_delay_mins, 'Immediately')}</div>
                            </SpacedContent>
                            <SpacedContent>
                                <b>Duration:</b>
                                <div>{format.minutesToHours(instruction.duration_mins, 'Default')}</div>
                            </SpacedContent>
                            {instruction.type_id === PackageInstructionType.NON_MEDICAL && (
                                <SpacedContent>
                                    <b>Quantity:</b>
                                    <div>{instruction.quantity}</div>
                                </SpacedContent>
                            )}
                            {hasFrequency() && (
                                <SpacedContent>
                                    <b>Frequency:</b>
                                    <div>{instruction.frequency}</div>
                                </SpacedContent>
                            )}
                            {instruction.type_id === PackageInstructionType.MEDICATION ||
                                (instruction.type_id === PackageInstructionType.FLUID && (
                                    <SpacedContent>
                                        <b>Route:</b>
                                        <div>{instruction.route_id}</div>
                                    </SpacedContent>
                                ))}
                            {instruction.type_id === PackageInstructionType.FLUID && (
                                <>
                                    <SpacedContent>
                                        <b>Volume:</b>
                                        <div>{instruction.fluids_volume_ml && `${instruction.fluids_volume_ml} ml`}</div>
                                    </SpacedContent>
                                    <SpacedContent>
                                        <b>Fluid rate:</b>
                                        <div>
                                            {instruction.rate_mcl_per_hr && `${roundTo(instruction.rate_mcl_per_hr / 1000, 2)} ml/hr`}
                                        </div>
                                    </SpacedContent>
                                </>
                            )}
                        </Space>
                    </Col>
                    <Col xs={24} xl={3}>
                        <Space direction='vertical'>
                            <Button block onClick={() => handleEdit(instruction)}>
                                Edit
                            </Button>
                            <Button danger block onClick={() => handleRemove(instruction)}>
                                Remove
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Space>
        </div>
    );
};

export default InstructionDetail;
