import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Tag, Typography } from 'antd';
import { Button, Space, SpacedContent, Table } from 'components/lib';
import { useCallback, useMemo, useState } from 'react';
import { useGetComplaintsQuery } from 'services/complaints.service';
import { Complaint, QueryResponse } from 'utils/types';
import ComplaintsDetailDrawer from './ComplaintsDetailDrawer';
import NewComplaintModal from './NewComplaintModal';

const ComplaintsListing: React.FC = () => {
    const [openSidedrawer, setOpenSidedrawer] = useState(false);
    const [openNewComplaintModal, setOpenNewComplaintModal] = useState(false);
    const [complaintToEdit, setComplaintToEdit] = useState<Complaint | null>(null);
    const [search, setSearch] = useState('');

    const { data: complaints } = useGetComplaintsQuery<QueryResponse<Complaint[]>>();

    const filteredData = complaints?.filter((complaint) => {
        if (search === '') return complaint;

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return complaint.common_name.match(regex) || complaint.medical_name?.match(regex);
    });

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'common_name',
                key: 'common_name',
                sorter: true,
                width: 200,
                defaultSortOrder: 'ascend',
            },
            {
                title: 'Medical Name',
                dataIndex: 'medical_name',
                key: 'medical_name',
                sorter: true,
                width: 200,

                ellipsis: true,
            },
            {
                title: 'Differentials',
                dataIndex: 'differentials',
                key: 'differentials',
                ellipsis: true,
                render: (diff: { name: string }[]) => {
                    return diff.map((d) => {
                        return <Tag>{d.name}</Tag>;
                    });
                },
            },
        ];
    }, []);

    const handleSave = useCallback(() => {
        setOpenSidedrawer(false);
    }, []);

    if (typeof complaints === 'undefined') {
        return null;
    }

    return (
        <>
            <Space direction='vertical' full>
                <Typography.Text>{complaints?.length} items</Typography.Text>

                <SpacedContent>
                    <Space>
                        <Input
                            placeholder='Search Complaints'
                            suffix={<SearchOutlined />}
                            value={search}
                            style={{ width: 280 }}
                            onChange={({ target }) => {
                                setSearch(target.value);
                            }}
                        />
                    </Space>

                    <Button onClick={() => setOpenNewComplaintModal(true)}>
                        Add Complaint <PlusOutlined />
                    </Button>
                </SpacedContent>

                <Table columns={columns} data={filteredData ?? []} onChange={() => {}}></Table>
            </Space>

            {complaintToEdit && (
                <ComplaintsDetailDrawer
                    title={complaintToEdit.common_name}
                    isOpen={openSidedrawer}
                    setIsOpen={setOpenSidedrawer}
                    complaintId={complaintToEdit.id}
                    differentials={complaintToEdit.differentials}
                    onSave={handleSave}
                />
            )}
            <NewComplaintModal isOpen={openNewComplaintModal} setIsOpen={setOpenNewComplaintModal} />
        </>
    );
};

export default ComplaintsListing;
