import { COMPLAINTS } from 'mock.data';
import { Complaint } from 'utils/types';
import emptySplitApi, { COMPLAINT_TAG_TYPE } from './emptySplitApi';

export type NewComplaintParams = Pick<Complaint, 'common_name' | 'medical_name'>;

export const complaintsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getComplaints: builder.query<Complaint[], void>({
            query: (searchQuery) => `complaint`,
            transformResponse: (response: { data: Complaint[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: COMPLAINT_TAG_TYPE, id: 'LIST' }],
        }),
        createComplaint: builder.mutation<Complaint[], NewComplaintParams>({
            query(body) {
                return {
                    url: `complaint`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: { data: Complaint[] }) => {
                return response.data;
            },
            invalidatesTags: (result, error, id) => [{ type: COMPLAINT_TAG_TYPE, id: 'LIST' }],
        }),
        getComplaint: builder.query<Complaint, { id: string }>({
            async queryFn({ id }, _queryApi, _extraOptions, fetchWithBQ) {
                let complaint = COMPLAINTS.find((c) => c.id === id);
                return { data: complaint as Complaint };
            },
        }),
        deleteComplaint: builder.query<{ id: number }, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                return { data: { id: -1 } };
            },
        }),
        editComplaint: builder.query<Complaint, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                return { data: {} as Complaint };
            },
        }),
    }),
});

export const { useGetComplaintsQuery, useGetComplaintQuery, useCreateComplaintMutation } = complaintsApi;
