import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Button, Space } from 'components/lib';
import { useSuccessMessage } from 'hooks';
import { useRemovePackageInstructionMutation } from 'services/packages.service';
import { BaseModalProps, PackageInstruction } from 'utils/types';

interface RemovePackageInstructionProps extends BaseModalProps {
    instruction: PackageInstruction | null;
}

const RemovePackageInstruction: React.FC<RemovePackageInstructionProps> = ({ isOpen, setIsOpen, instruction }) => {
    const [removePackageInstruction, { isSuccess, isError }] = useRemovePackageInstructionMutation();

    const handleClose = () => {
        setIsOpen(false);
    };

    useSuccessMessage({
        success: {
            status: isSuccess,
            message: `'${instruction?.name}' has been successfully removed.`,
            callback: handleClose,
        },
        error: {
            status: isError,
            message: `It seems there has been an issue removing the order '${instruction?.name}', please try again.`,
        },
    });

    const handleDelete = () => {
        let { id, package_id, type_id } = instruction as PackageInstruction;

        removePackageInstruction({ id, package_id, type_id });
    };

    if (instruction === null) {
        return null;
    }

    return (
        <Modal
            title='Remove Order'
            open={isOpen}
            onCancel={handleClose}
            footer={[
                <Button key='cancel' onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key='Confirm' onClick={handleDelete} danger>
                    Remove <DeleteOutlined />
                </Button>,
            ]}
        >
            <div style={{ textAlign: 'center' }}>
                <Space direction='vertical'>
                    <div>
                        Are you sure you want to remove <b>{instruction.name}</b> from this package?
                    </div>
                </Space>
            </div>
        </Modal>
    );
};

export default RemovePackageInstruction;
