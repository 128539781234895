import { PriceOverride } from 'utils/types';
import emptySplitApi, { FLUIDS_TAG_TYPE, PRICE_OVERRIDE_TAG_TYPE } from './emptySplitApi';
import { LocationSpecificItem } from './locationSpecificItems.service';

export interface FluidsCategory {
    name: string;
}

export interface Fluid {
    id: number;
    name: string;
    abbreviation?: string;
    category: string;
    base_price_cents: number;
    product_category_id?: number | null;
    product_category_name?: number;
    supplemental_id: number;
    supplemental_name: string;
    sort_rank?: number;
    has_commission: boolean;
    default_duration_mins?: number;
    default_rate_mcl_per_hr?: number | null;
    default_volume_ml?: number;
    is_pricing_relative: boolean;
    avalara_tax_code: string | null;
    serial?: boolean;
    serial_hours?: number;
    base_serial_price_cents: number;
}

export interface CreateFluid extends Omit<Fluid, 'product_category_name' | 'supplemental_name' | 'id'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}
export interface UpdateFluid extends Omit<Fluid, 'product_category_name' | 'supplemental_name'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}

const fluidsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        listFluidsCategories: builder.query<FluidsCategory[], void>({
            query: () => `fluids_category`,
            transformResponse: (response: { data: FluidsCategory[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: FLUIDS_TAG_TYPE, id: 'CATEGORIES_LIST' }],
        }),
        listFluids: builder.query<Fluid[], void>({
            query: () => 'fluids',
            transformResponse: (response: { data: Fluid[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: FLUIDS_TAG_TYPE, id: 'LIST' }],
        }),
        getFluidsPriceOverrides: builder.query<PriceOverride[], number>({
            query: (id) => `fluids/${id}/price`,
            transformResponse: (response: { data: PriceOverride[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: PRICE_OVERRIDE_TAG_TYPE, id }],
        }),
        createFluid: builder.mutation<void, CreateFluid>({
            query: ({ base_price_cents, ...body }) => ({
                url: 'fluids',
                method: 'POST',
                body: {
                    ...body,
                    base_price_cents: base_price_cents,
                },
            }),
            invalidatesTags: () => [{ type: FLUIDS_TAG_TYPE, id: 'LIST' }],
        }),
        updateFluid: builder.mutation<void, UpdateFluid>({
            query: ({ id, ...body }) => ({
                url: `fluids/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: () => [{ type: FLUIDS_TAG_TYPE, id: 'LIST' }],
        }),
        deleteFluid: builder.mutation<void, number>({
            query: (fluids_id) => ({
                url: `fluids/${fluids_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => [{ type: FLUIDS_TAG_TYPE, id: 'LIST' }],
        }),
    }),
});

export const {
    useListFluidsCategoriesQuery,
    useListFluidsQuery,
    useGetFluidsPriceOverridesQuery,
    useCreateFluidMutation,
    useUpdateFluidMutation,
    useDeleteFluidMutation,
} = fluidsApi;
