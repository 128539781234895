import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { JSONifyQueryString } from 'utils/helpers/format';
import { ROUTES } from 'utils/types';
import Loading from '../lib/loading';

interface UnprotectedRouteProps {
    component: React.ReactNode;
    isUserFetching: boolean;
    isUserLoggedIn: boolean;
    path: string;
    exact?: boolean;
}

const UnprotectedRoute: React.FC<UnprotectedRouteProps> = ({ component: Component, isUserFetching, isUserLoggedIn, ...rest }: any) => {
    const history = useHistory();
    let { search: redirectSearch } = useLocation();
    const searchParams = useQuery();

    useEffect(() => {
        if (isUserLoggedIn) {
            let queries = JSONifyQueryString<{ redirect: string }>(redirectSearch);
            if (queries.redirect) {
                searchParams.delete('redirect');
                const search = searchParams.toString();
                history.push({ pathname: queries.redirect, search });
            } else {
                history.push(ROUTES.HOSPITALS_LISTING);
            }
        }
    }, [isUserLoggedIn]);

    return (
        <Route
            {...rest}
            render={(props) => {
                return !isUserFetching ? <Component {...props} /> : <Loading />;
            }}
        />
    );
};

export default UnprotectedRoute;
