import { Hospital } from 'utils/types';

export const selectByKey = (id: number | string, arr: any, { defaultVal = null, key = 'id' } = { defaultVal: null, key: 'id' }) => {
    return arr.find((item: any) => item[key] === id) ?? defaultVal;
};

export function sortRows<T extends Record<string, any>>(key: keyof T) {
    return (a: T, b: T) => {
        let aValue = a[key];
        let bValue = b[key];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            aValue = aValue.toLocaleLowerCase();
            bValue = bValue.toLocaleLowerCase();
        }

        if (aValue === '' || aValue === null) return 1;
        if (bValue === '' || bValue === null) return -1;
        if (aValue === bValue) return 0;

        if (aValue && bValue) {
            if (aValue < bValue) {
                return -1;
            }
            if (aValue > bValue) {
                return 1;
            }
        }
        return 0;
    };
}

export const getHospitalFromHospitalId = (hospitals: Hospital[] | undefined, hospitalId: number | undefined) => {
    return hospitals?.find((item) => item.id.toString() === hospitalId?.toString());
};

export const calculatePriceCentsFromMarkup = (basePrice: number, markupPercentPoints: number, adjustmentPercentPoints: number) => {
    return Math.round((adjustmentPercentPoints / 100 + markupPercentPoints / 100 + 1) * basePrice);
};
