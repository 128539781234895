import { AlertFilled } from '@ant-design/icons';
import { Checkbox, Divider, Form, FormInstance, Input, InputNumber, Radio, Select } from 'antd';
import { RequiredLabelStar } from 'components/lib';
import { HiddenInput } from 'components/lib/formFields/HiddenInput';
import ProductCategorySelect from 'components/lib/ProductCategorySelect';
import { useState } from 'react';
import { Medication } from 'services/medicines.service';
import { INSTRUCTION_FREQUENCY_REGEX } from 'utils/consts';
import MedicationFormSelect from '../MedicationFormSelect';
import MedicationRouteSelect from '../MedicationRouteSelect';
import MedicationUnitSelect, { convertFromMicro } from '../MedicationUnitSelect';
import './CreateOrUpdateMedicationModal.css';

interface MedicineTabProps {
    medicationToUpdate: Medication | null;
    form: FormInstance;
}

export const MedicineTab = ({ medicationToUpdate, form }: MedicineTabProps) => {
    const [noLowDose, setNoLowDose] = useState(false);
    const [noHighDose, setNoHighDose] = useState(false);

    const isSimpleOrTGH = (calculator_type: string) => calculator_type === 'simple' || calculator_type === 'prepackaged';
    const getDefaultDoseSuffix = (calculator_type: string, numerator_unit: string | null, denominator_unit: string) => {
        if (calculator_type === 'complex') {
            if (numerator_unit) {
                return `${convertFromMicro(numerator_unit)}/kg`;
            }
        } else {
            if (denominator_unit) {
                return convertFromMicro(denominator_unit);
            }
        }

        return '';
    };

    return (
        <>
            {medicationToUpdate && <HiddenInput fieldName='id' initialValue={medicationToUpdate.id} />}
            <HiddenInput fieldName='default_duration_mins' initialValue={null} />

            <Form.Item label='Name' name='name' initialValue={medicationToUpdate?.name} rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item label='Generic Name' name='generic_name' initialValue={medicationToUpdate?.generic_name ?? null}>
                <Input />
            </Form.Item>

            <Form.Item label='Brand' name='brand_name' initialValue={medicationToUpdate?.brand_name ?? null}>
                <Input />
            </Form.Item>

            <Form.Item label='Form' name='form' initialValue={medicationToUpdate?.form ?? null} rules={[{ required: true }]}>
                <MedicationFormSelect
                    defaultValue={medicationToUpdate?.form ?? null}
                    onChange={(value) => form.setFieldsValue({ form: value })}
                />
            </Form.Item>

            <Form.Item
                label='Controlled Drug'
                name='controlled_drug'
                initialValue={medicationToUpdate?.controlled_drug ?? false}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label='Dosing Unit'
                name='dosing_unit'
                initialValue={medicationToUpdate?.dosing_unit ?? null}
                rules={[{ required: true }]}
            >
                <MedicationUnitSelect
                    defaultValue={medicationToUpdate?.dosing_unit ?? null}
                    onChange={(value) => form.setFieldsValue({ dosing_unit: value })}
                />
            </Form.Item>

            <Form.Item
                label='Calculator Type'
                name='calculator_type'
                initialValue={medicationToUpdate?.calculator_type ?? null}
                rules={[{ required: true }]}
            >
                <Select
                    disabled={!!medicationToUpdate}
                    options={[
                        { label: 'Complex', value: 'complex' },
                        { label: 'Simple', value: 'simple' },
                        { label: 'Prepackaged/TGH', value: 'prepackaged' },
                    ]}
                />
            </Form.Item>

            <Form.Item label='Numerator Value/Unit' dependencies={['calculator_type', 'dosing_unit', 'denominator_unit']}>
                {({ getFieldValue, getFieldsValue, setFieldsValue }) => (
                    <Input.Group compact>
                        <Form.Item name='numerator_value' initialValue={medicationToUpdate?.numerator_value ?? null} noStyle>
                            <InputNumber
                                disabled={isSimpleOrTGH(getFieldValue('calculator_type')) || !!medicationToUpdate}
                                placeholder='value'
                                style={{ width: '30%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            name='numerator_unit'
                            initialValue={medicationToUpdate?.numerator_unit ?? null}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        const { calculator_type, dosing_unit, denominator_unit } = getFieldsValue();

                                        if (calculator_type === 'complex') {
                                            if (!value) {
                                                return Promise.reject(
                                                    new Error('Numerator Unit is required if calculator type is complex'),
                                                );
                                            }

                                            if (value !== dosing_unit && denominator_unit !== dosing_unit) {
                                                return Promise.reject(
                                                    new Error('Numerator Unit or Denominator Unit should match the Dosing Unit'),
                                                );
                                            }

                                            if (value === denominator_unit) {
                                                return Promise.reject(new Error('Numerator Unit cannot be the same as Denominator Unit'));
                                            }
                                        }

                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            noStyle
                        >
                            <MedicationUnitSelect
                                defaultValue={medicationToUpdate?.numerator_unit ?? null}
                                disabled={isSimpleOrTGH(getFieldValue('calculator_type')) || !!medicationToUpdate}
                                placeholder='unit'
                                style={{ width: '70%' }}
                                onChange={(value) => setFieldsValue({ numerator_unit: value })}
                            />
                        </Form.Item>
                    </Input.Group>
                )}
            </Form.Item>

            <Form.Item
                label={<RequiredLabelStar label='Denominator Value/Unit' />}
                dependencies={['calculator_type', 'dosing_unit', 'numerator_unit']}
            >
                {({ getFieldValue, getFieldsValue, setFieldsValue }) => (
                    <Input.Group compact>
                        <Form.Item
                            name='denominator_value'
                            initialValue={medicationToUpdate?.denominator_value ?? null}
                            rules={[{ required: true }]}
                            noStyle
                        >
                            <InputNumber disabled={!!medicationToUpdate} placeholder='value' style={{ width: '30%' }} />
                        </Form.Item>
                        <Form.Item
                            name='denominator_unit'
                            initialValue={medicationToUpdate?.denominator_unit ?? getFieldValue('dosing_unit') ?? null}
                            rules={[
                                {
                                    validator: (_, value) => {
                                        const { calculator_type, dosing_unit, numerator_unit } = getFieldsValue();

                                        if (calculator_type === 'complex') {
                                            if (!value) {
                                                return Promise.reject(new Error('Denominator Unit is required'));
                                            }

                                            if (value !== dosing_unit && numerator_unit !== dosing_unit) {
                                                return Promise.reject(
                                                    new Error('Denominator Unit or Numerator Unit should match the Dosing Unit'),
                                                );
                                            }

                                            if (value === numerator_unit) {
                                                return Promise.reject(new Error('Denominator Unit cannot be the same as Numerator Unit'));
                                            }
                                        }

                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            noStyle
                        >
                            <MedicationUnitSelect
                                defaultValue={medicationToUpdate?.denominator_unit ?? getFieldValue('dosing_unit') ?? null}
                                disabled={!!medicationToUpdate || isSimpleOrTGH(getFieldValue('calculator_type'))}
                                placeholder='unit'
                                style={{ width: '70%' }}
                                onChange={(value) => setFieldsValue({ denominator_unit: value })}
                            />
                        </Form.Item>
                    </Input.Group>
                )}
            </Form.Item>

            <Divider />

            <Form.Item label='Product Category' name='product_category_id' initialValue={medicationToUpdate?.product_category_id ?? null}>
                <ProductCategorySelect
                    defaultValue={medicationToUpdate?.product_category_id ?? null}
                    onChange={(value) => form.setFieldsValue({ product_category_id: value })}
                />
            </Form.Item>

            <Form.Item
                label='Vetspire ID'
                name='vetspire_id'
                initialValue={medicationToUpdate?.vetspire_id ?? null}
                dependencies={['controlled_drug']}
                rules={[
                    {
                        validator: (_, value) => {
                            if (!value && !!form.getFieldValue('controlled_drug')) {
                                return Promise.reject(new Error('Vetspire Id is required if the drug is controlled'));
                            }

                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <InputNumber />
            </Form.Item>

            <Form.Item label='Sort Rank' name='sort_rank' initialValue={medicationToUpdate?.sort_rank ?? 0} rules={[{ required: true }]}>
                <InputNumber />
            </Form.Item>

            <Form.Item
                label='Has Commission'
                name='has_commission'
                initialValue={medicationToUpdate?.has_commission ?? true}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <section>
                <Divider orientation='left' orientationMargin='0'>
                    Default
                </Divider>

                <Form.Item
                    label='Frequency'
                    name='default_frequency'
                    initialValue={medicationToUpdate?.default_frequency ?? null}
                    rules={[{ pattern: INSTRUCTION_FREQUENCY_REGEX }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label='Route' name='default_route' initialValue={medicationToUpdate?.default_route ?? null}>
                    <MedicationRouteSelect
                        defaultValue={medicationToUpdate?.default_route ?? null}
                        onChange={(value) => form.setFieldsValue({ default_route: value })}
                    />
                </Form.Item>

                <Form.Item label='Dose' dependencies={['numerator_unit', 'denominator_unit', 'calculator_type']}>
                    {() => (
                        <Form.Item
                            name='default_dose'
                            initialValue={medicationToUpdate?.default_dose ? medicationToUpdate?.default_dose / 1000 : null}
                            noStyle
                        >
                            <Input
                                suffix={getDefaultDoseSuffix(
                                    form.getFieldValue('calculator_type'),
                                    form.getFieldValue('numerator_unit'),
                                    form.getFieldValue('denominator_unit'),
                                )}
                            />
                        </Form.Item>
                    )}
                </Form.Item>

                <Form.Item dependencies={['calculator_type', 'numerator_unit', 'denominator_unit']} label='CRI Unit'>
                    {() => {
                        const { denominator_unit, numerator_unit, calculator_type } = form.getFieldsValue();

                        if (
                            (medicationToUpdate?.denominator_unit ?? denominator_unit)?.includes('ml') &&
                            (medicationToUpdate?.numerator_unit ?? numerator_unit)?.includes('mg') &&
                            (medicationToUpdate?.calculator_type ?? calculator_type) === 'complex'
                        ) {
                            return (
                                <Form.Item initialValue={medicationToUpdate?.default_cri_unit ?? null} name='default_cri_unit' noStyle>
                                    <Select
                                        allowClear
                                        options={[
                                            { label: 'mg/kg/hr (Standard)', value: 'mg/kg/hr' },
                                            { label: 'mcg/kg/min', value: 'mcg/kg/min' },
                                        ]}
                                    />
                                </Form.Item>
                            );
                        }

                        return <Input disabled />;
                    }}
                </Form.Item>
            </section>

            <section className='medication-modal__dose-alert-section'>
                <Divider orientation='left' orientationMargin='0'>
                    <span className='medication-modal__dose-alert-title'>
                        <AlertFilled /> Low/High Doses
                    </span>
                </Divider>

                <Form.Item label='Low Dose' dependencies={['high_dose_alert', 'calculator_type']}>
                    {() => (
                        <>
                            <Form.Item
                                name='low_dose_alert'
                                initialValue={medicationToUpdate?.low_dose_alert ?? null}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!value && form.getFieldValue('high_dose_alert') && !noLowDose) {
                                                return Promise.reject(new Error('The low dose is required if the high dose value is set'));
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                noStyle
                            >
                                <InputNumber disabled={isSimpleOrTGH(form.getFieldValue('calculator_type'))} style={{ width: '100%' }} />
                            </Form.Item>
                            <Checkbox
                                disabled={isSimpleOrTGH(form.getFieldValue('calculator_type'))}
                                onChange={(e) => setNoLowDose(e.target.checked)}
                            >
                                There is no limit for low
                            </Checkbox>
                        </>
                    )}
                </Form.Item>

                <Form.Item label='High Dose' dependencies={['low_dose_alert', 'calculator_type']}>
                    {() => (
                        <>
                            <Form.Item
                                name='high_dose_alert'
                                initialValue={medicationToUpdate?.high_dose_alert ?? null}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!value && form.getFieldValue('low_dose_alert') && !noHighDose) {
                                                return Promise.reject(new Error('The high dose is required if the low dose value is set'));
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                                noStyle
                            >
                                <InputNumber disabled={isSimpleOrTGH(form.getFieldValue('calculator_type'))} style={{ width: '100%' }} />
                            </Form.Item>
                            <Checkbox
                                disabled={isSimpleOrTGH(form.getFieldValue('calculator_type'))}
                                onChange={(e) => setNoHighDose(e.target.checked)}
                            >
                                There is no limit for high
                            </Checkbox>
                        </>
                    )}
                </Form.Item>
            </section>
        </>
    );
};
