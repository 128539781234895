import { DownOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Menu, message, Modal, Tabs, Typography } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { rdvmEmailAndPhoneWarningConfig } from 'components/lib/ConfirmModalConfig';
import React, { useState } from 'react';
import { useGetUserDataQuery } from 'services/auth.service';
import { useGetHospitalsQuery } from 'services/hospitals.service';
import { useCheckRdvmEmailAndPhoneWarningMutation, useCreateRdvmMutation, useValidateRdvmMutation } from 'services/rdvm.service';
import { BASE_QUERY_OPTIONS } from 'utils/consts';
import { BaseModalProps, Hospital, QueryResponse, RdvmClinicCreation } from 'utils/types';
import { checkIfUserHasNecessaryPermission, USER_PERMISSIONS } from 'utils/userPermissions';
import AdvancedTabPane from './AdvancedTabPane';
import ClinicTabPane from './ClinicTabPane';
import './index.css';
import RelationshipTabPane from './RelationshipTabPane';

interface NewClinicmodalProps extends BaseModalProps {}

const initialState = {
    display_name: null,
    email: null,
    phone_number: null,
    fax_number: null,
    street: null,
    city: null,
    state: null,
    zipcode: null,
    note: null,
    website: null,
    status: 'Open',
    parent_organization: null,
    facility_type: null,
    emergency_care_offerings: null,
    hours_category: null,
    weekend_operations: null,
    associated_hospital_id: null,
    discount_type: null,
    discount_amount: null,
    discount_fixed: null,
    discount_percentage: null,
    discount_other: null,
    has_call_forwarding: null,
    relationship_status: null,
    priority: null,
    description: null,
    is_validated: false,
};

const NewClinicModal: React.FC<NewClinicmodalProps> = ({ isOpen, setIsOpen }) => {
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState('clinic');
    const [shouldValidateAfterCreation, setShouldValidateAfterCreation] = useState(false);
    const [checkRdvmEmailAndPhoneWarning] = useCheckRdvmEmailAndPhoneWarningMutation();
    const [createRdvm, { data }] = useCreateRdvmMutation();
    const [validateRdvm] = useValidateRdvmMutation();
    const { data: hospitals } = useGetHospitalsQuery<QueryResponse<Hospital[]>>({});
    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const userHasPrimaryLocationEditPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.primary_location_edit,
    );

    const userHasValidatePermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_clinics_validate,
    );

    const handleClose = () => {
        setIsOpen(false);
        setShouldValidateAfterCreation(false);
        form.resetFields();
    };

    const handleFormSubmit = () => {
        const errors = form.getFieldsError(['display_name', 'email', 'state', 'status']);
        const hasErrors = errors.some((field) => field.errors.length > 0);

        if (hasErrors) {
            setActiveTab('clinic');
            message.error('Please complete all required fields.');
        } else {
            form.submit();
        }
    };

    const onFinish = (values: RdvmClinicCreation) => {
        const onOk = () => {
            const { is_validated, ...submissionValues } = values;
            submissionValues.fax_number = submissionValues.fax_number ? submissionValues.fax_number : null;
            let has_call_forwarding = submissionValues.has_call_forwarding === 'true';

            let discount_fixed = null;
            let discount_percentage = null;
            let discount_other = null;
            const discount_amount = submissionValues.discount_amount;

            if (discount_amount) {
                if (submissionValues.discount_type === 'Percentage') {
                    discount_percentage = +discount_amount;
                } else if (submissionValues.discount_type === 'Other') {
                    discount_other = discount_amount;
                } else if (submissionValues.discount_type === 'Fixed amount') {
                    discount_fixed = +discount_amount;
                }
            }

            delete submissionValues.discount_amount;

            createRdvm({ ...submissionValues, has_call_forwarding, discount_fixed, discount_percentage, discount_other })
                .unwrap()
                .then((createdRdvm) => {
                    message.success('Clinic created successfully');
                    if (shouldValidateAfterCreation) {
                        validateRdvm({ clinic_id: createdRdvm.data.clinic_id, is_validated: true })
                            .unwrap()
                            .catch((error: any) => {
                                message.error('Error validating clinic.');
                                console.error('Validate clinic error:', error);
                            });
                    }
                    handleClose();
                })
                .catch((error) => {
                    message.error('Error creating clinic');
                    console.error('Create clinic error:', error);
                });
        };
        checkRdvmEmailAndPhoneWarning({ body: values })
            .unwrap()
            .then((resp) => {
                if (resp.email.emit_warning || resp.phone.emit_warning) {
                    Modal.confirm(rdvmEmailAndPhoneWarningConfig(resp.email.emit_warning, resp.phone.emit_warning, onOk));
                } else {
                    onOk();
                }
            })
            .catch(() => {
                message.error('Something went wrong');
            });
    };

    const saveMenu = (
        <Menu>
            <Menu.Item
                key='1'
                onClick={() => {
                    handleFormSubmit();
                    setShouldValidateAfterCreation(true);
                    form.submit();
                }}
            >
                Save and Lock
            </Menu.Item>
        </Menu>
    );

    return (
        <Modal
            title='New Clinic'
            open={isOpen}
            onOk={handleFormSubmit}
            okText='Save'
            onCancel={handleClose}
            footer={
                <Col>
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Button key='back' onClick={handleClose} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        {userHasValidatePermission ? (
                            <Dropdown.Button
                                type='primary'
                                icon={<DownOutlined />}
                                overlay={saveMenu}
                                onClick={() => {
                                    handleFormSubmit();
                                    setShouldValidateAfterCreation(false);
                                    form.submit();
                                }}
                            >
                                Save
                            </Dropdown.Button>
                        ) : (
                            <Button
                                type='primary'
                                onClick={() => {
                                    handleFormSubmit();
                                    setShouldValidateAfterCreation(false);
                                    form.submit();
                                }}
                            >
                                Save
                            </Button>
                        )}
                    </div>
                </Col>
            }
        >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography.Text>
                    <UnlockOutlined style={{ color: '#FAAD14' }} />
                    <span style={{ marginLeft: 8 }}>Unlocked</span>
                </Typography.Text>
            </div>

            <Form
                form={form}
                onFinish={onFinish}
                layout='horizontal'
                labelAlign='left'
                labelCol={{ span: 7 }}
                labelWrap
                wrapperCol={{ span: 25 }}
                colon={false}
                className='clinic-modal'
                initialValues={initialState}
            >
                <Tabs activeKey={activeTab} onChange={setActiveTab}>
                    <TabPane tab='Clinic' key='clinic' forceRender>
                        <ClinicTabPane form={form} />
                    </TabPane>
                    <TabPane tab='Advanced' key='advanced' forceRender>
                        <AdvancedTabPane />
                    </TabPane>
                    <TabPane tab='Relationship' key='relationship' forceRender>
                        <RelationshipTabPane
                            hospitals={hospitals}
                            userHasPrimaryLocationEditPermission={userHasPrimaryLocationEditPermission}
                            form={form}
                            isCreating
                        />
                    </TabPane>
                </Tabs>
            </Form>
        </Modal>
    );
};

export default NewClinicModal;
