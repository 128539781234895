import { Form, FormInstance, Radio, Switch } from 'antd';
import TimeField from 'components/lib/formFields/TimeField';
import { PackageInstructionType } from 'utils/types';
import { NEW_PACKAGE_INSTRUCTION_FIELDS } from '../utils';
import instructionDetailStyles from './package-instruction-form.module.css';
import PackageCriForm from './PackageCriForm';
import PackageDiagnosticForm from './PackageDiagnosticForm';
import PackageFluidForm from './PackageFluidForm';
import PackageMedicineForm from './PackageMedicineForm';
import PackageNonMedicalForm from './PackageNonMedicalForm';
import PackageTaskForm from './PackageTaskForm';

interface PackageInstructionFormProps {
    form: FormInstance;
    instructionType: PackageInstructionType;
    instructionName: string;
}

const PackageInstructionForm: React.FC<PackageInstructionFormProps> = (props) => {
    const forms: {
        [key in PackageInstructionType]: JSX.Element;
    } = {
        [PackageInstructionType.DIAGNOSTIC]: <PackageDiagnosticForm {...props} />,
        [PackageInstructionType.MEDICATION]: <PackageMedicineForm {...props} />,
        [PackageInstructionType.FLUID]: <PackageFluidForm {...props} />,
        [PackageInstructionType.CRI]: <PackageCriForm {...props} />,
        [PackageInstructionType.TASK]: <PackageTaskForm {...props} />,
        [PackageInstructionType.NON_MEDICAL]: <PackageNonMedicalForm {...props} />,
        [PackageInstructionType.OXYGEN_THERAPY]: <PackageFluidForm {...props} />,
        [PackageInstructionType.PACKAGE]: <h1>No Implementation</h1>,
    };

    return (
        <div style={{ marginTop: '24px' }}>
            <Form.Item name='toggled' label='Toggled'>
                <Switch defaultChecked={props.form.getFieldValue('toggled') ?? true} />
            </Form.Item>

            <Form.Item
                label='Required'
                name='required'
                initialValue={props.form.getFieldValue('required') ?? false}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label='No Charge'
                name='no_charge'
                initialValue={props.form.getFieldValue('no_charge') ?? false}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            {forms[props.instructionType]}

            <TimeField
                label='Starting after'
                minutesName={NEW_PACKAGE_INSTRUCTION_FIELDS.START_DELAY_MINS}
                hoursName={NEW_PACKAGE_INSTRUCTION_FIELDS.START_DELAY_HOURS}
                daysName={NEW_PACKAGE_INSTRUCTION_FIELDS.START_DELAY_DAYS}
                fieldClassName={instructionDetailStyles.triInput}
            />
            <TimeField
                label='Order Duration'
                minutesName={NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_MINS}
                hoursName={NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_HOURS}
                daysName={NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_DAYS}
                fieldClassName={instructionDetailStyles.triInput}
            />
        </div>
    );
};

export default PackageInstructionForm;
