import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

interface CheckCrossProps {
    checked: boolean;
}

const CheckCross: React.FC<CheckCrossProps> = ({ checked }) => {
    return checked ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />;
};

export default CheckCross;
