import { Form, Input, Radio } from 'antd';
import { HiddenInput } from 'components/lib/formFields/HiddenInput';
import { Supplemental } from 'services/supplemental.service';

interface SupplementalTabProps {
    supplementalToUpdate: Supplemental | null;
}

export const SupplementalTab = ({ supplementalToUpdate }: SupplementalTabProps) => {
    return (
        <>
            {supplementalToUpdate && <HiddenInput fieldName='id' initialValue={supplementalToUpdate.id} />}

            <Form.Item label='Name' name='name' initialValue={supplementalToUpdate?.name} rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item
                label='Is Recurring'
                name='recurring'
                initialValue={supplementalToUpdate?.recurring ?? true}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
        </>
    );
};
