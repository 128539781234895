import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tooltip, Typography } from 'antd';
import { Avatar, Button, Markdown, Space, SpacedContent } from 'components/lib';
import moment from 'moment';
import { useState } from 'react';
import { MeetingNotes } from 'utils/types';
import EditClinicNoteModal from './EditClinicNoteModal';
import NewClinicNoteModal from './NewClinicNoteModal';

interface NotesProps {
    meetingNotes?: MeetingNotes[];
}

const Author = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
    return (
        <>
            <Avatar content={`${firstName} ${lastName}`} />
            {firstName} {lastName}{' '}
        </>
    );
};

const NoteCard = ({ meetingNote }: { meetingNote: MeetingNotes }) => {
    const [openEditNoteModal, setOpenEditNoteModal] = useState(false);

    return (
        <>
            <Card
                key={meetingNote.created_at}
                title={<Author firstName={meetingNote.user_first_name} lastName={meetingNote.user_last_name} />}
                extra={
                    <Row style={{ width: 500 }}>
                        <Col span={11}>
                            <Typography.Text strong>
                                Meeting Type: <span style={{ fontWeight: 'normal' }}>{meetingNote.mtg_type}</span>
                            </Typography.Text>
                        </Col>
                        <Col span={11}>
                            <Typography.Text strong>
                                Date: <span style={{ fontWeight: 'normal' }}>{moment.unix(meetingNote.mtg_date).format('LL')}</span>
                            </Typography.Text>
                        </Col>

                        <Col span={2} style={{ textAlign: 'end' }}>
                            <Tooltip title='Edit'>
                                <Button onClick={() => setOpenEditNoteModal(true)} icon={<EditOutlined />} />
                            </Tooltip>
                        </Col>

                        <Col span={2} />
                    </Row>
                }
                style={{ marginBottom: 10 }}
            >
                <Markdown content={meetingNote.note} />
            </Card>

            <EditClinicNoteModal isOpen={openEditNoteModal} setIsOpen={setOpenEditNoteModal} initialNoteState={meetingNote} />
        </>
    );
};

const Notes: React.FC<NotesProps> = ({ meetingNotes }: NotesProps) => {
    const [openNewClinicNoteModal, setOpenNewClinicNoteModal] = useState(false);

    return (
        <>
            <Space direction='vertical' style={{ width: '100%' }}>
                <SpacedContent>
                    <div></div>
                    <Button onClick={() => setOpenNewClinicNoteModal(true)}>
                        Add Note <PlusOutlined />
                    </Button>
                </SpacedContent>
                <div>
                    <Space direction='vertical' style={{ width: '100%' }}>
                        {meetingNotes
                            ?.slice()
                            .sort((a, b) => b.mtg_date - a.mtg_date)
                            .map((meetingNote) => (
                                <NoteCard key={meetingNote.id} meetingNote={meetingNote} />
                            ))}
                    </Space>
                </div>
            </Space>
            <NewClinicNoteModal isOpen={openNewClinicNoteModal} setIsOpen={setOpenNewClinicNoteModal} />
        </>
    );
};

export default Notes;
