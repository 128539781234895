import {
    DeleteOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    FileDoneOutlined,
    PlusOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { Dropdown, Input, Menu, Tag, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { Breadcrumb, Button, Content, Space, SpacedContent, Table, Title } from 'components/lib';
import { useTableFilters } from 'hooks';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDeletePackageMutation, useGetPackagesQuery } from 'services/packages.service';
import { PACKAGE_INSTRUCTION_COLORS } from 'utils/consts';
import { format } from 'utils/helpers';
import { sortRows } from 'utils/helpers/general';
import { Package, ROUTES } from 'utils/types';
import AddPackageModal from './AddPackageModal';
import './PackageListing.css';

const PackagesListing: React.FC = () => {
    const [addPackageModalOpen, setAddPackageModalOpen] = useState(false);
    const [search, setSearch] = useState('');
    let history = useHistory();

    let { data: packages, refetch } = useGetPackagesQuery({});
    const [deletePackage] = useDeletePackageMutation();

    const { handleChange } = useTableFilters({
        onFilter: refetch,
    });

    const viewPackage = (id: number) => {
        history.push(ROUTES.addID(ROUTES.PACKAGE_DETAIL, `${id}`));
    };

    const filteredData = packages?.filter((pack) => {
        if (search === '') return pack;

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return pack.name.match(regex);
    });

    const confirmDeletePackage = (packageId: number, packageName: string) => {
        confirm({
            title: 'Delete package',
            icon: <ExclamationCircleOutlined style={{ color: '#E7202E' }} />,
            content: <p>Are you sure you want to delete {packageName} package?</p>,
            okText: 'Delete',
            okType: 'danger',
            okButtonProps: {
                type: 'primary',
                style: { backgroundColor: '#E7202E' },
            },
            cancelText: 'Cancel',
            onOk() {
                deletePackage(packageId);
            },
        });
    };

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: sortRows('name'),
                width: 200,
                defaultSortOrder: 'ascend',
                render: (name: string) => format.capitalizeStr(name),
            },
            {
                title: 'Instructions',
                dataIndex: 'instruction',
                key: 'instruction',
                defaultSortOrder: 'ascend',
                render: (a: undefined, _package: Package) =>
                    _package.instructions.map((instruction) => {
                        return (
                            <Tag color={PACKAGE_INSTRUCTION_COLORS[instruction.type_id]} key={instruction.id} icon={<FileDoneOutlined />}>
                                {instruction.name}
                            </Tag>
                        );
                    }),
            },
            {
                title: '',
                dataIndex: 'id',
                key: 'action',
                width: 100,
                render: (id: number, _package: Package) => {
                    return (
                        <Dropdown
                            key={id}
                            overlay={
                                <Menu>
                                    <Menu.Item onClick={() => viewPackage(id)}>
                                        <SpacedContent>
                                            View <EyeOutlined />
                                        </SpacedContent>
                                    </Menu.Item>
                                    <Menu.Item onClick={() => confirmDeletePackage(id, _package.name)}>
                                        <SpacedContent>
                                            Delete <DeleteOutlined />
                                        </SpacedContent>
                                    </Menu.Item>
                                </Menu>
                            }
                            placement='bottomRight'
                            arrow
                        >
                            <Button>
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    );
                },
            },
        ];
    }, []);

    if (typeof packages === 'undefined') {
        return null;
    }

    return (
        <>
            <Breadcrumb path={['Packages', 'Dashboard']} />
            <Content>
                <Title level={2}>Packages</Title>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Typography.Text>{packages.length} items</Typography.Text>
                    <SpacedContent>
                        <Space>
                            <Input
                                placeholder='Search Package'
                                suffix={<SearchOutlined />}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Space>
                    </SpacedContent>
                    <SpacedContent>
                        <div className='package-item-type-subtitle'>
                            <div>
                                <b>Medications:</b>
                                <span className='package-item-type-color package-medication-item' />
                            </div>
                            |
                            <div>
                                <b>Diagnostics:</b>
                                <span className='package-item-type-color package-diagnostic-item' />
                            </div>
                            |
                            <div>
                                <b>Fluids:</b>
                                <span className='package-item-type-color package-fluids-item' />
                            </div>
                            |
                            <div>
                                <b>Tasks:</b>
                                <span className='package-item-type-color package-tasks-item' />
                            </div>
                            |
                            <div>
                                <b>Non-medicals:</b>
                                <span className='package-item-type-color package-non-medical-item' />
                            </div>
                        </div>
                        <Button onClick={() => setAddPackageModalOpen(true)}>
                            New Package <PlusOutlined />
                        </Button>
                    </SpacedContent>

                    <Table columns={columns} data={filteredData ?? []} onChange={handleChange} />
                </Space>
            </Content>
            <AddPackageModal isOpen={addPackageModalOpen} setIsOpen={setAddPackageModalOpen} />
        </>
    );
};

export default PackagesListing;
