import { Select } from 'antd';
import React from 'react';
import { useListSupplementalsQuery } from 'services/supplemental.service';

interface SupplementalSelectProps {
    onChange: (value: any) => void;
    defaultValue?: number | null;
}

const SupplementalSelect: React.FC<SupplementalSelectProps> = ({ onChange, defaultValue }) => {
    const { data, isLoading } = useListSupplementalsQuery();

    return (
        <Select defaultValue={defaultValue} loading={isLoading} onChange={onChange} allowClear>
            {data?.map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                    {name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SupplementalSelect;
