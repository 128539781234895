export const formatCurrency = (numberToFormat: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(Math.max(numberToFormat, 0));
};

/**
 *
 * https://stackoverflow.com/a/8358141
 */
export const formatPhoneNumber = (phoneNumberString: string | undefined) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
};

export const formatDate = (date: Date) => {
    const strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = date.getDate();
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
};

const getFirstLetter = (word: string = ''): string => {
    if (word.length) {
        return word[0];
    }
    return '';
};

export const getInitials = (name: string = '') => {
    let resName = '';
    let splitName = name.split(' ');
    resName = getFirstLetter(splitName[0]);

    if (resName.length) {
        getFirstLetter(splitName[1]);
    }
    return resName;
};

export const stringToHslColor = (str: string, saturation: number, lightness: number) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
};

export const capitalizeStr = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const minutesToHours = (mins: number, zero = 'Now') => {
    let days = Math.floor(mins / 1440);
    let hours = Math.floor((mins % 1440) / 60);
    let minutes = mins % 60;

    let dateArr = [];

    if (!mins) {
        return zero;
    }

    if (days) {
        dateArr.push(`${days} day${days > 1 ? 's' : ''}`);
    }
    if (hours) {
        dateArr.push(`${hours} hour${days > 1 ? 's' : ''}`);
    }
    if (minutes) {
        dateArr.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
    }

    if (dateArr.length === 1) {
        return dateArr[0];
    } else if (dateArr.length === 2) {
        return `${dateArr[0]} and ${dateArr[1]}`;
    } else if (dateArr.length === 3) {
        return `${dateArr[0]}, ${dateArr[1]} and ${dateArr[2]}`;
    }
};

export const timeUnitsToMins = (days = 0, hours = 0, minutes = 0) => {
    return days * 1440 + hours * 60 + minutes || null;
};

export const minsToTimeUnits = (minutes = 0) => {
    let days = Math.floor(minutes / 1440);
    let hours = Math.floor((minutes % 1440) / 60);
    let mins = Math.floor((minutes % 1440) % 60);
    return {
        days,
        hours,
        mins,
    };
};

export function roundTo(value: number, digits: number): number;
export function roundTo(value: number | null, digits: number): number | null;
export function roundTo(value: number | null, digits: number): number | null {
    if (typeof value === 'number') {
        return Math.round(value * 10 ** digits) / 10 ** digits;
    }
    if (typeof value === 'string') {
        return Math.round(parseFloat(value) * 10 ** digits) / 10 ** digits;
    }
    return value;
}

export const JSONifyQueryString = <T>(qs: string) => {
    let queries = {};

    if (qs.length) {
        qs = decodeURIComponent(qs);
        queries = JSON.parse('{"' + qs.replace(/\?/g, '').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    }

    return queries as T;
};

export const pluralize = (count: number, singular: string, plural: string) => (count > 1 ? plural : singular);
