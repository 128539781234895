import { useMemo } from 'react';

const useDependantBreadcrumbs = (initialPath: string[], render: (obj: any) => string = (obj) => obj, dependency: any) => {
    const breadcrumbs = useMemo(() => {
        let crumbs = [...initialPath];

        if (typeof dependency !== 'undefined' && dependency) {
            crumbs.push(render(dependency));
        }

        return crumbs;
    }, [dependency]);

    return [breadcrumbs];
};

export default useDependantBreadcrumbs;
