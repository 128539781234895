import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Button, Space } from 'components/lib';
import { BaseModalProps, Hospital } from 'utils/types';

interface RemoveHospitalModalProps extends BaseModalProps {
    hospital: Hospital | null;
}

const RemoveHospitalModal: React.FC<RemoveHospitalModalProps> = ({ isOpen, setIsOpen, hospital }) => {
    const handleClose = () => {
        setIsOpen(false);
    };

    const handleDelete = () => {
        handleClose();
    };

    const handleFail = () => {};

    if (hospital === null) {
        return null;
    }

    return (
        <Modal
            title='Archive Hospital'
            open={isOpen}
            onCancel={handleClose}
            footer={[
                <Button key='cancel' onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key='Confirm' onClick={handleDelete} danger>
                    Archive <DeleteOutlined />
                </Button>,
            ]}
        >
            <div style={{ textAlign: 'center' }}>
                <Space direction='vertical'>
                    <div>
                        Are you sure you want to archive the <b>{hospital.display_name}</b> VEG hospital? Once it has been archived, all of
                        it's PIMs data will also be archived.
                    </div>
                </Space>
            </div>
        </Modal>
    );
};

export default RemoveHospitalModal;
