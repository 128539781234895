import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu, message, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { Breadcrumb, Button, Content, Space, SpacedContent, Table, Title } from 'components/lib';
import { ElementRef, useMemo, useRef, useState } from 'react';
import { Diagnostic, useDeleteDiagnosticMutation, useGetDiagnosticsQuery, useGetIdexxRefLabsQuery } from 'services/diagnostics.service';
import { format } from 'utils/helpers';
import { formatCurrency, roundTo } from 'utils/helpers/format';
import { sortRows } from 'utils/helpers/general';
import CreateOrUpdateDiagnosticModal from './CreateOrUpdateDiagnosticModal';

interface DiagnosticsListingProps {
    category?: string;
}

const DiagnosticsListing: React.FC<DiagnosticsListingProps> = ({ category }) => {
    const diagnosticModalRef = useRef<ElementRef<typeof CreateOrUpdateDiagnosticModal>>(null);
    const [search, setSearch] = useState('');

    const { data: diagnosticsList } = useGetDiagnosticsQuery();
    const { data: refLabsList } = useGetIdexxRefLabsQuery();
    const [deleteDiagnostic] = useDeleteDiagnosticMutation();

    const pageTitle = category === 'ref_labs' ? 'Idexx External Labs' : 'Diagnostics';

    const confirmDeleteDiagnostic = (diagnostic: Diagnostic) => {
        confirm({
            title: 'Delete Diagnostic',
            icon: <ExclamationCircleOutlined style={{ color: '#e7202e' }} />,
            content: <p>Are you sure you want to delete {diagnostic.name}?</p>,
            okText: 'Confirm',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                deleteDiagnostic(diagnostic.id).then((res) => {
                    if ('error' in res) {
                        message.error(`There was an error deleting ${diagnostic.name}`);
                        return;
                    }

                    message.success(`${diagnostic.name} successfully deleted`);
                });
            },
        });
    };

    const data = category === 'ref_labs' ? refLabsList : diagnosticsList;
    const filteredData = useMemo(() => {
        const searchString = search.replace(/[-[\]{}()*+?.,\\^$|#]/g, '');

        if (!searchString) return data;

        return data?.filter((diag) => {
            const regex = new RegExp(searchString, 'gi');
            const stringToSearch = `${diag.name} ${diag.idexx_code}`;

            return stringToSearch.match(regex);
        });
    }, [data, search]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 350,
            sorter: sortRows('name'),
            defaultSortOrder: 'ascend',
            render: (name: string, record: any) => {
                if (category === 'ref_labs') {
                    return `${format.capitalizeStr(name)} (${record.idexx_code})`;
                }

                return format.capitalizeStr(name);
            },
        },
        {
            title: 'Diagnostic Category',
            dataIndex: 'category',
            key: 'category',
            sorter: sortRows('category'),
            ellipsis: true,
            render: (category: string) => format.capitalizeStr(category ?? ''),
        },
        {
            title: 'Base Unit Price',
            dataIndex: 'base_price_cents',
            key: 'base_price_cents',
            sorter: sortRows('base_price_cents'),
            render: (basePrice: number) => formatCurrency(roundTo(basePrice / 100, 2)),
        },
        {
            title: 'Sort Rank',
            dataIndex: 'sort_rank',
            key: 'sort_rank',
            width: 120,
            sorter: sortRows('sort_rank'),
        },
        {
            title: 'Product Category',
            dataIndex: 'product_category_name',
            key: 'product_category_name',
            sorter: sortRows('product_category_name'),
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: 100,
            render: (id: string, diagnostic: Diagnostic) => {
                return (
                    <Dropdown
                        key={id}
                        overlay={
                            <Menu>
                                <Menu.Item onClick={() => diagnosticModalRef.current?.setDiagnosticToUpdate(diagnostic)}>
                                    <SpacedContent>
                                        Edit <EditOutlined />
                                    </SpacedContent>
                                </Menu.Item>
                                {category !== 'ref_labs' && (
                                    <Menu.Item onClick={() => confirmDeleteDiagnostic(diagnostic)}>
                                        <SpacedContent>
                                            Remove <DeleteOutlined />
                                        </SpacedContent>
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                        placement='bottomRight'
                        arrow
                    >
                        <Button>
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <Breadcrumb path={[pageTitle, 'Dashboard']} />
            <Content>
                <Title level={2}>{pageTitle}</Title>
                <Space direction='vertical'>
                    <Typography.Text>{data?.length} items</Typography.Text>
                    <SpacedContent>
                        <Space>
                            <Input
                                placeholder={`Search ${pageTitle}`}
                                suffix={<SearchOutlined />}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Space>
                        {category !== 'ref_labs' && (
                            <Button onClick={() => diagnosticModalRef.current?.openModal()}>
                                New Diagnostic <PlusOutlined />
                            </Button>
                        )}
                    </SpacedContent>

                    <Table columns={columns} data={filteredData ?? []} />
                </Space>
                <CreateOrUpdateDiagnosticModal ref={diagnosticModalRef} isRefLab={category === 'ref_labs'} />
            </Content>
        </>
    );
};

export default DiagnosticsListing;
