import { Select, SelectProps } from 'antd';
import React from 'react';

export const microDoseMapping = [
    { value: 'micro-bag', label: 'bag', showAsPricingUnit: true },
    { value: 'micro-bottle', label: 'bottle', showOnSelect: true, showAsPricingUnit: true },
    { value: 'micro-vial', label: 'vial', showAsPricingUnit: true },
    { value: 'micro-capsule', label: 'capsule', showOnSelect: true },
    { value: 'micro-dose', label: 'dose', showOnSelect: true },
    { value: 'micro-drop', label: 'drop', showOnSelect: true },
    { value: 'micro-g', label: 'g', showOnSelect: true },
    { value: 'micro-kit', label: 'kit', showOnSelect: true },
    { value: 'micro-mcg', label: 'mcg', showOnSelect: true },
    { value: 'micro-mEq', label: 'mEq', showOnSelect: true },
    { value: 'micro-mg', label: 'mg', showOnSelect: true },
    { value: 'micro-ml', label: 'ml', showOnSelect: true },
    { value: 'micro-packet', label: 'packet', showOnSelect: true },
    { value: 'micro-puff', label: 'puff', showOnSelect: true },
    { value: 'micro-spray', label: 'spray', showOnSelect: true },
    { value: 'micro-syringe', label: 'syringe', showOnSelect: true },
    { value: 'micro-tablet', label: 'tablet', showOnSelect: true },
    { value: 'micro-tube', label: 'tube', showOnSelect: true },
    { value: 'micro-u', label: 'u', showOnSelect: true },
    { value: 'micro-wipe', label: 'wipe', showOnSelect: true },
    { value: 'micro-tube/kg', label: 'tube/kg' },
    { value: 'micro-bottle/kg', label: 'bottle/kg' },
    { value: 'micro-mEq/kg', label: 'mEq/kg' },
    { value: 'micro-mg/kg', label: 'mg/kg' },
    { value: 'micro-g/kg', label: 'g/kg' },
    { value: 'micro-puff/kg', label: 'puff/kg' },
    { value: 'micro-spray/kg', label: 'spray/kg' },
    { value: 'micro-drop/kg', label: 'drop/kg' },
    { value: 'micro-ml/kg', label: 'ml/kg' },
    { value: 'micro-u/kg', label: 'u/kg' },
    { value: 'micro-syringe/kg', label: 'syringe/kg' },
    { value: 'micro-mcg/kg', label: 'mcg/kg' },
    { value: 'micro-dose/kg', label: 'dose/kg' },
    { value: 'micro-capsule/kg', label: 'capsule/kg' },
    { value: 'micro-tablet/kg', label: 'tablet/kg' },
    { value: 'micro-wipe/kg', label: 'wipe/kg' },
    { value: 'micro-packet/kg', label: 'packet/kg' },
    { value: 'micro-kit/kg', label: 'kit/kg' },
    { value: 'micro-tube/kg/hr', label: 'tube/kg/hr' },
    { value: 'micro-bottle/kg/hr', label: 'bottle/kg/hr' },
    { value: 'micro-mEq/kg/hr', label: 'mEq/kg/hr' },
    { value: 'micro-mg/kg/hr', label: 'mg/kg/hr' },
    { value: 'micro-g/kg/hr', label: 'g/kg/hr' },
    { value: 'micro-puff/kg/hr', label: 'puff/kg/hr' },
    { value: 'micro-spray/kg/hr', label: 'spray/kg/hr' },
    { value: 'micro-drop/kg/hr', label: 'drop/kg/hr' },
    { value: 'micro-ml/kg/hr', label: 'ml/kg/hr' },
    { value: 'micro-u/kg/hr', label: 'u/kg/hr' },
    { value: 'micro-syringe/kg/hr', label: 'syringe/kg/hr' },
    { value: 'micro-mcg/kg/hr', label: 'mcg/kg/hr' },
    { value: 'micro-dose/kg/hr', label: 'dose/kg/hr' },
    { value: 'micro-capsule/kg/hr', label: 'capsule/kg/hr' },
    { value: 'micro-tablet/kg/hr', label: 'tablet/kg/hr' },
    { value: 'micro-wipe/kg/hr', label: 'wipe/kg/hr' },
    { value: 'micro-packet/kg/hr', label: 'packet/kg/hr' },
    { value: 'micro-kit/kg/hr', label: 'kit/kg/hr' },
];

export const convertFromMicro = (microUnit?: string | null): string | undefined => {
    if (!microUnit) return;

    return microDoseMapping.find((unit) => unit.value === microUnit)?.label;
};

export const convertToMicro = (unit?: string | null): string | undefined => {
    if (!unit) return;

    return microDoseMapping.find((microU) => microU.label === unit)?.value;
};

const MedicationUnitSelect: React.FC<SelectProps> = (props) => {
    return <Select {...props} options={microDoseMapping.filter((unit) => unit.showOnSelect)} allowClear showSearch />;
};

export default MedicationUnitSelect;
