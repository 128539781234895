import { CLINICS, HOSPITAL_MEDICINES } from 'mock.data';
import { roundTo } from 'utils/helpers/format';
import { Clinic, Hospital, HospitalCrematorium, HospitalMedicine, VegUser } from 'utils/types';
import emptySplitApi, { HOSPITAL_TAG_TYPE } from './emptySplitApi';

interface GetHospitalsParams {
    query?: string;
    order?: string;
    state?: string;
}

interface GetHospitalParams {
    id: string;
}

interface GetHospitalClinicsParams {
    query: string;
    order: string;
    hopsitalId: string;
}

interface GetHospitalUsers {
    hospitalId: string;
    query: string;
}

export interface CreateOrEditHospitalParams extends Omit<Hospital, 'id' | 'stripe_account_id_last_6' | 'stripe_public_key_last_6'> {
    hospital_id: number | string;
    stripe_account_id: string | null;
    stripe_public_key: string | null;
    crematorium: HospitalCrematorium;
}

export const hospitalsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getHospitals: builder.query<Hospital[], GetHospitalsParams>({
            query: (searchQuery) => `hospital`,
            transformResponse: (response: { data: Hospital[] }) => {
                return response.data.map((hospital) => {
                    return {
                        ...hospital,
                        address: `${hospital.street}, ${hospital.city}, ${hospital.state}, ${hospital.zipcode}.`,
                        pricing_markup: roundTo(hospital.pricing_markup / 100, 2),
                    };
                });
            },
            providesTags: (result, error, id) => [{ type: HOSPITAL_TAG_TYPE, id: 'LIST' }],
        }),
        getHospital: builder.query<Hospital, GetHospitalParams>({
            query: ({ id }) => `hospital/${id}`,
            transformResponse: (response: { data: Hospital }) => {
                return {
                    ...response.data,
                    pricing_markup: roundTo(response.data.pricing_markup / 100, 2),
                };
            },
            providesTags: (result, error, { id }) => [{ type: HOSPITAL_TAG_TYPE, id }],
        }),
        createHospital: builder.mutation<Hospital, CreateOrEditHospitalParams>({
            query(body) {
                return {
                    url: `hospital`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: { data: Hospital }) => {
                return response.data;
            },
            invalidatesTags: () => [{ type: 'Hospital', id: 'LIST' }],
        }),
        editHospital: builder.mutation<Hospital, CreateOrEditHospitalParams>({
            query({ hospital_id, ...rest }) {
                return {
                    url: `hospital/${hospital_id}`,
                    method: 'PUT',
                    body: rest,
                };
            },
            transformResponse: (response: { data: Hospital }) => {
                return response.data;
            },
            invalidatesTags: (res, error) => [{ type: HOSPITAL_TAG_TYPE }],
        }),
        deleteHospital: builder.query<[], { id: string }>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                return { data: [] };
            },
        }),
        getHospitalClinics: builder.query<Clinic[], GetHospitalClinicsParams>({
            async queryFn({ query, order, hopsitalId }, { getState }, _extraOptions, fetchWithBQ) {
                let formattedClinics = CLINICS.map((clinic) => {
                    return {
                        ...clinic,
                        address: `${clinic.street}, ${clinic.city}, ${clinic.state}, ${clinic.zip_code}.`,
                    };
                })
                    .filter((clinic) => {
                        if (!query.length) {
                            return true;
                        }
                        if (clinic.name.toLowerCase().includes(query.toLowerCase())) {
                            return true;
                        }
                        return false;
                    })
                    .sort((a: Clinic, b: Clinic) => {
                        // This is a bit terrible but just o simulate server side ordering
                        if (order && order.length) {
                            if (order[0] === '-') {
                                let orderStr: keyof Clinic = order.substring(1) as keyof Clinic;
                                let aVal = a[orderStr];
                                let bVal = b[orderStr];
                                if (typeof aVal === 'string' && typeof bVal === 'string') {
                                    return aVal.localeCompare(bVal);
                                } else if (typeof aVal === 'number' && typeof bVal === 'number') {
                                    return aVal - bVal;
                                }
                            } else {
                                let orderStr: keyof Clinic = order as keyof Clinic;
                                let aVal = a[orderStr];
                                let bVal = b[orderStr];
                                if (typeof aVal === 'string' && typeof bVal === 'string') {
                                    return bVal.localeCompare(aVal);
                                } else if (typeof aVal === 'number' && typeof bVal === 'number') {
                                    return bVal - aVal;
                                }
                            }
                        }
                        return 0;
                    });

                if (hopsitalId) {
                    formattedClinics = formattedClinics.filter((c) => {
                        return c.hospital_Id === hopsitalId;
                    });
                }

                return { data: formattedClinics };
            },
        }),
        addHospitalClinic: builder.mutation<Clinic, Omit<Clinic, 'id'>>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                let data = {} as Clinic;
                return { data };
            },
        }),
        getHospitalUsers: builder.query<VegUser[], GetHospitalUsers>({
            async queryFn({ hospitalId, query = '' }, _queryApi, _extraOptions) {
                return { data: [] };
            },
        }),
        getHospitalMedicines: builder.query<HospitalMedicine[], { hospitalId: string }>({
            async queryFn(_arg, _queryApi, _extraOptions) {
                return { data: HOSPITAL_MEDICINES };
            },
        }),
        setHospitalLoginCode: builder.mutation<Hospital, string>({
            query(hospital_id) {
                return {
                    url: `hospital/${hospital_id}/login-code`,
                    method: 'PUT',
                    body: {},
                };
            },
            transformResponse: (response: { data: Hospital }) => {
                return response.data;
            },
            invalidatesTags: (res, error) => [{ type: HOSPITAL_TAG_TYPE }],
        }),
    }),
});

export const {
    useLazyGetHospitalClinicsQuery,
    useGetHospitalsQuery,
    useGetHospitalQuery,
    useAddHospitalClinicMutation,
    useLazyGetHospitalUsersQuery,
    useLazyGetHospitalMedicinesQuery,
    useCreateHospitalMutation,
    useEditHospitalMutation,
    useSetHospitalLoginCodeMutation,
} = hospitalsApi;
