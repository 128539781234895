import { Form, message } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { CreateOrUpdateCatalogModal } from 'components/lib/CreateOrUpdateCatalogModal';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Supplemental,
    useCreateSupplementalMutation,
    useGetSupplementalPriceOverridesQuery,
    useUpdateSupplementalMutation,
} from 'services/supplemental.service';
import { SupplementalTab } from './SupplementalTab';

interface CreateOrUpdateSupplementalProps {}

interface CreateOrUpdateSupplementalModalRef {
    openModal: () => void;
    setSupplementalToUpdate: (supplemental: Supplemental) => void;
}

const CreateOrUpdateSupplementalModal: React.ForwardRefRenderFunction<
    CreateOrUpdateSupplementalModalRef,
    CreateOrUpdateSupplementalProps
> = (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [supplementalToUpdate, setSupplementalToUpdate] = useState<Supplemental | null>(null);
    const [form] = Form.useForm();

    const {
        data: initialPriceOverrides,
        isFetching: initialPriceOverridesFetching,
        isError: initialPriceOverridesError,
    } = useGetSupplementalPriceOverridesQuery(supplementalToUpdate?.id as number, {
        skip: !supplementalToUpdate,
        refetchOnMountOrArgChange: true,
    });

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setIsOpen(true);
        },
        setSupplementalToUpdate: (supplemental: Supplemental) => {
            setIsOpen(true);
            setSupplementalToUpdate(supplemental);
        },
    }));

    const [createSupplemental, { isLoading: createSupplementalLoading }] = useCreateSupplementalMutation();
    const [updateSupplemental, { isLoading: updateSupplementalLoading }] = useUpdateSupplementalMutation();

    const handleCloseModal = () => {
        setIsOpen(false);
        setSupplementalToUpdate(null);
        form.resetFields();
    };

    const handleSubmit = (values: any) => {
        const { base_serial_price_cents, serial_hours, ...otherValues } = values;
        if ('id' in values) {
            updateSupplemental(otherValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error updating ${values.name}`);
                    return;
                }

                message.success(`${values.name} successfully updated`);
                handleCloseModal();
            });
        } else {
            createSupplemental(otherValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error in adding ${values.name}`);
                    return;
                }

                message.success(`${values.name} successfully created`);
                handleCloseModal();
            });
        }
    };

    return (
        <CreateOrUpdateCatalogModal
            modalTitle={`${supplementalToUpdate ? 'Edit' : 'New'} Supplemental`}
            isOpen={isOpen}
            catalogItemToUpdate={supplementalToUpdate}
            initialPriceOverrides={initialPriceOverrides || []}
            catalogItemTab={
                <TabPane tab='Supplemental' key='catalog_item' forceRender>
                    <SupplementalTab supplementalToUpdate={supplementalToUpdate} />
                </TabPane>
            }
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            form={form}
            createCatalogItemLoading={createSupplementalLoading}
            updateCatalogItemLoading={updateSupplementalLoading}
            initialPriceOverridesFetching={initialPriceOverridesFetching}
            initialPriceOverridesError={initialPriceOverridesError}
            typeId='S'
        />
    );
};

export default forwardRef(CreateOrUpdateSupplementalModal);
