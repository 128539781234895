import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu, message, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { Breadcrumb, Button, Content, Space, SpacedContent, Table, Title } from 'components/lib';
import { ElementRef, useRef, useState } from 'react';
import { Task, useDeleteTaskMutation, useGetTasksQuery } from 'services/tasks.service';
import { format } from 'utils/helpers';
import { formatCurrency, roundTo } from 'utils/helpers/format';
import { sortRows } from 'utils/helpers/general';
import CreateOrUpdateTaskModal from './CreateOrUpdateTaskModal';

const TasksListing: React.FC = () => {
    const [search, setSearch] = useState('');
    const taskModalRef = useRef<ElementRef<typeof CreateOrUpdateTaskModal>>(null);

    const { data: tasksList } = useGetTasksQuery();
    const [deleteTask] = useDeleteTaskMutation();

    const confirmDeleteTask = (task: Task) => {
        confirm({
            title: 'Delete Fluid',
            icon: <ExclamationCircleOutlined style={{ color: '#e7202e' }} />,
            content: <p>Are you sure you want to delete {task.name}?</p>,
            okText: 'Confirm',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                deleteTask(task.id).then((res) => {
                    if ('error' in res) {
                        message.error(`There was an error deleting ${task.name}`);
                        return;
                    }

                    message.success(`${task.name} successfully deleted`);
                });
            },
        });
    };

    const filteredData = tasksList?.filter((task) => {
        if (search === '') return task;

        const regex = new RegExp(search, 'gi');

        return task.name.match(regex);
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 350,
            sorter: sortRows('name'),
            defaultSortOrder: 'ascend',
            render: (name: string) => format.capitalizeStr(name),
        },
        {
            title: 'Base Unit Price',
            dataIndex: 'base_price_cents',
            key: 'base_price_cents',
            sorter: sortRows('base_price_cents'),
            render: (basePrice: number) => formatCurrency(roundTo(basePrice / 100, 2)),
        },
        {
            title: 'Sort Rank',
            dataIndex: 'sort_rank',
            key: 'sort_rank',
            width: 120,
            sorter: sortRows('sort_rank'),
        },
        {
            title: 'Product Category',
            dataIndex: 'product_category_name',
            key: 'product_category_name',
            sorter: sortRows('product_category_name'),
        },
        {
            title: '',
            key: 'action',
            dataIndex: 'id',
            width: 100,
            render: (id: string, task: Task) => {
                return (
                    <Dropdown
                        key={id}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    onClick={() => {
                                        taskModalRef.current?.setTaskToUpdate(task);
                                    }}
                                >
                                    <SpacedContent>
                                        Edit <EditOutlined />
                                    </SpacedContent>
                                </Menu.Item>
                                <Menu.Item
                                    onClick={() => {
                                        confirmDeleteTask(task);
                                    }}
                                >
                                    <SpacedContent>
                                        Remove <DeleteOutlined />
                                    </SpacedContent>
                                </Menu.Item>
                            </Menu>
                        }
                        placement='bottomRight'
                        arrow
                    >
                        <Button>
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <Breadcrumb path={['Tasks', 'Dashboard']} />
            <Content>
                <Title level={2}>Tasks</Title>
                <Space direction='vertical' full>
                    <Typography.Text>{tasksList?.length} items</Typography.Text>
                    <SpacedContent>
                        <Space>
                            <Input
                                placeholder='Search Task'
                                suffix={<SearchOutlined />}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Space>
                        <Button
                            onClick={() => {
                                taskModalRef.current?.openModal();
                            }}
                        >
                            New Task <PlusOutlined />
                        </Button>
                    </SpacedContent>
                    <Table columns={columns} data={filteredData ?? []} />
                </Space>
                <CreateOrUpdateTaskModal ref={taskModalRef} />
            </Content>
        </>
    );
};

export default TasksListing;
