import { Select, SelectProps } from 'antd';
import React from 'react';
import { useListMedicationFormsQuery } from 'services/medicines.service';

const MedicationFormSelect: React.FC<SelectProps> = (props) => {
    const { data, isLoading } = useListMedicationFormsQuery();

    return (
        <Select {...props} loading={isLoading} allowClear showSearch>
            {data?.map(({ name }) => (
                <Select.Option key={name} value={name}>
                    {name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default MedicationFormSelect;
