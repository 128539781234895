import { Form, Input, Modal } from 'antd';
import { useSuccessMessage } from 'hooks';
import { NewDifferentialParams, useCreateDifferentialMutation } from 'services/differentials.service';
import { BaseModalProps } from 'utils/types';

enum NEW_DIFFERENTIAL_FIELDS {
    NAME = 'name',
}

interface NewDifferentialModalProps extends BaseModalProps {}

const initialState: NewDifferentialParams = {
    [NEW_DIFFERENTIAL_FIELDS.NAME]: '',
};

const NewDifferentialModal: React.FC<NewDifferentialModalProps> = ({ isOpen, setIsOpen }) => {
    const [form] = Form.useForm();
    const [createDifferential, { isSuccess, isError }] = useCreateDifferentialMutation();

    useSuccessMessage({
        success: {
            status: isSuccess,
            message: `New differential'${form.getFieldValue(NEW_DIFFERENTIAL_FIELDS.NAME)}' has been succesfully created.`,
            callback: () => {
                handleClose();
                form.resetFields();
            },
        },
        error: {
            status: isError,
            message: `It seems there has been an issue adding the new differential '${form.getFieldValue(
                NEW_DIFFERENTIAL_FIELDS.NAME,
            )}', please try again.`,
        },
    });

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSave = (values: NewDifferentialParams) => {
        createDifferential(values);
    };

    const handleFail = () => {};

    return (
        <Modal title='Add New Differential' open={isOpen} onOk={form.submit} onCancel={handleClose}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={initialState}
                onFinish={handleSave}
                onFinishFailed={handleFail}
            >
                <Form.Item
                    label='Name'
                    name={NEW_DIFFERENTIAL_FIELDS.NAME}
                    rules={[
                        {
                            required: true,
                            message: "Please ensure the differential's name has been completed.",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default NewDifferentialModal;
