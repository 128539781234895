import { AutoComplete, Form, FormInstance } from 'antd';
import React from 'react';
import { useGetTaxCodesQuery } from 'services/avalataTaxCode.service';

interface TaxCodeTabProps {
    form: FormInstance;
    taxCode?: string | null;
}

const TaxCodeTab: React.FC<TaxCodeTabProps> = ({ form, taxCode }) => {
    const catalogItemName = Form.useWatch('name', form);

    const { data } = useGetTaxCodesQuery();

    return (
        <>
            <Form.Item label='Name' className='no-margin'>
                {catalogItemName}
            </Form.Item>
            <Form.Item initialValue={taxCode ?? null} label='Avalara Tax Code' name='avalara_tax_code' className='no-margin'>
                <AutoComplete
                    options={data?.map((item) => ({
                        value: item.tax_code,
                    }))}
                    filterOption={(inputValue, option) => (option?.value as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
            </Form.Item>
        </>
    );
};

export default TaxCodeTab;
