import { DatePicker, Form, Modal, Select } from 'antd';
import { TextEditor } from 'components/lib';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import moment from 'moment';
import { useState } from 'react';
import { useUpdateMeetingNoteMutation } from 'services/rdvm.service';
import { MEETING_TYPES } from 'utils/consts';
import { BaseModalProps, MeetingNotes, MeetingNotesCreation } from 'utils/types';

interface NewClinicNoteModalProps extends BaseModalProps {
    initialNoteState: MeetingNotes;
}

const EditClinicNoteModal: React.FC<NewClinicNoteModalProps> = ({ isOpen, setIsOpen, initialNoteState }) => {
    const [initialNote, setInitialnote] = useState(EditorState.createWithContent(ContentState.createFromText(initialNoteState.note)));
    const [updateMeetingNote] = useUpdateMeetingNoteMutation();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        let markDown = draftToMarkdown(convertToRaw(initialNote.getCurrentContent()));

        const editedNote: MeetingNotesCreation = {
            clinic_id: initialNoteState.clinic_id,
            ...values,
            note: markDown,
            mtg_date: +moment(values.mtg_date).format('X'),
        };

        updateMeetingNote({ id: initialNoteState.id, body: editedNote });
        handleClose();
    };

    const handleClose = () => {
        setIsOpen(false);
        setInitialnote(EditorState.createWithContent(ContentState.createFromText(initialNoteState.note)));
        form.resetFields();
    };

    const handleChange = (editorState: EditorState) => {
        setInitialnote(editorState);
    };

    const layout = {
        labelCol: { span: 4 },
    };

    return (
        <Modal title='Edit Note' open={isOpen} onOk={form.submit} onCancel={handleClose}>
            <Form form={form} {...layout} onFinish={onFinish}>
                <Form.Item label='Date' name='mtg_date' initialValue={moment.unix(initialNoteState.mtg_date)}>
                    <DatePicker placeholder='Select Date' format={'YYYY-MM-DD'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label='Type' name='mtg_type' initialValue={initialNoteState.mtg_type}>
                    <Select placeholder='Select Type' allowClear>
                        {MEETING_TYPES.map((type) => (
                            <Select.Option key={type} value={type}>
                                {type}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label='Note' name='note' initialValue={initialNote}>
                    <TextEditor content={initialNote} initialState={initialNote} onChange={handleChange} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditClinicNoteModal;
