import { FileDoneOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { Table } from 'components/lib';
import { format } from 'utils/helpers';
import { PackageInstruction } from 'utils/types';
import instructionListStyles from './index.module.css';

import EditPackageInstructionModal from '../EditPackageInstructionModal';
import RemovePackageInstruction from '../RemovePackageInstruction';
import InstructionDetail from './InstructionDetail';

import { useModalWithState } from 'hooks';
import { PACKAGE_INSTRUCTION_COLORS } from 'utils/consts';
interface InstructionTableProps {
    instructions: PackageInstruction[];
}

const InstructionTable: React.FC<InstructionTableProps> = ({ instructions }) => {
    const [instructionToRemove, removeInstructionModalOpen, openRemoveInstructionModal, closeRemoveInstructionModal] =
        useModalWithState<PackageInstruction>();
    const [instructionToEdit, editInstructionModalOpen, openEditInstructionModal, closeEditInstructionModal] =
        useModalWithState<PackageInstruction>();
    const columns = [
        {
            title: 'Order',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, instruction: PackageInstruction) => {
                return (
                    <Tag color={PACKAGE_INSTRUCTION_COLORS[instruction.type_id]} key={instruction.id} icon={<FileDoneOutlined />}>
                        {name}
                    </Tag>
                );
            },
        },
        {
            title: 'Starting',
            dataIndex: 'start_delay_mins',
            key: 'start_delay_mins',
            render: (starting: number) => {
                return format.minutesToHours(starting, 'Immediately');
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration_mins',
            key: 'duration_mins',
            render: (duration: number) => {
                return format.minutesToHours(duration, 'Default');
            },
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
        },
    ];

    return (
        <>
            <div className={instructionListStyles.listHeader}>Orders</div>
            {/* <Collapse
        defaultActiveKey={["1"]}
        onChange={() => {}}
        expandIconPosition="right"
      >
        {instructions.map((instruction) => {
          return (
            <Collapse.Panel
              header={dataExtract.getPackageInstructionName(instruction)}
              key={instruction.id}
              extra={renderFrequency(instruction)}
            >
              <InstructionsListItem />
            </Collapse.Panel>
          );
        })}
      </Collapse> */}
            <div className={instructionListStyles.tableWrapper}>
                <Table
                    data={instructions}
                    columns={columns}
                    paginationPosition={false}
                    rowKey='id'
                    expandable={{
                        expandedRowRender: (instruction: PackageInstruction) => {
                            return (
                                <InstructionDetail
                                    instruction={instruction}
                                    handleRemove={openRemoveInstructionModal}
                                    handleEdit={openEditInstructionModal}
                                />
                            );
                        },
                    }}
                />
            </div>
            <RemovePackageInstruction
                isOpen={removeInstructionModalOpen}
                instruction={instructionToRemove}
                setIsOpen={closeRemoveInstructionModal}
            />
            <EditPackageInstructionModal
                isOpen={editInstructionModalOpen}
                instruction={instructionToEdit}
                setIsOpen={closeEditInstructionModal}
            />
        </>
    );
};

export default InstructionTable;
