import { ReconciliationOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { Breadcrumb, Content, SpacedContent, Title } from 'components/lib';
import ComplaintsListing from './ComplaintsListing';
import DifferentialsListing from './DifferentialsListing';

const CDsListing: React.FC = () => {
    return (
        <>
            <Breadcrumb path={['Management', 'Differentials & Complaints']} />
            <Content>
                <Title level={2}>
                    <SpacedContent>Complaints & Differentials</SpacedContent>
                </Title>
                <Tabs defaultActiveKey='1'>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <ReconciliationOutlined />
                                Complaints
                            </span>
                        }
                        key='1'
                    >
                        <ComplaintsListing />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <ReconciliationOutlined />
                                Differentials
                            </span>
                        }
                        key='2'
                    >
                        <DifferentialsListing />
                    </Tabs.TabPane>
                </Tabs>
            </Content>
        </>
    );
};

export default CDsListing;
