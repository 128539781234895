import { EditOutlined, PhoneOutlined, ReconciliationOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row, Space, Tabs } from 'antd';
import { Breadcrumb, Button, Content, PageTitle } from 'components/lib';
import { useDependantBreadcrumbs } from 'hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetHospitalQuery } from 'services/hospitals.service';
import { format } from 'utils/helpers';
import { Hospital, QueryResponse, ROUTES } from 'utils/types';
import ManageHospitalModal from '../HospitalsListing/ManageHospitalModal';
import WhiteboardResetButton from '../HospitalsListing/WhiteboardResetButton';
import HospitalEmployeeTable from './HospitalEmployeeTable';
import HospitalMedicineTable from './HospitalMedicineTable';
import hospitalDetailStyles from './index.module.css';
import RemoveHospitalModal from './RemoveHospitalModal';

const HospitalDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [removeHospitalModal, setRemoveHospitalModal] = useState(false);
    const [editHospitalModal, setEditHospitalModal] = useState(false);

    let { data: hospital } = useGetHospitalQuery<QueryResponse<Hospital>>({ id }, { refetchOnMountOrArgChange: true });

    let [breadcrumbs] = useDependantBreadcrumbs(['Hospitals'], (hospital) => hospital.display_name, hospital);

    if (typeof hospital === 'undefined') {
        return null;
    }

    return (
        <>
            <Breadcrumb path={breadcrumbs} />
            <Content>
                <div>
                    <Row>
                        <Col className={`gutter-row ${hospitalDetailStyles.contactSection}`} xs={24} xl={11}>
                            <PageTitle backRoute={ROUTES.HOSPITALS_LISTING} title={hospital.display_name}></PageTitle>
                        </Col>
                        <Col className={`gutter-row ${hospitalDetailStyles.contactSection}`} xs={24} xl={9}>
                            <div className={hospitalDetailStyles.addressSection}>
                                {hospital?.street}, {hospital?.city}, {hospital?.state}, {hospital?.zipcode}.
                            </div>
                            <Row>
                                <Col className='gutter-row' xs={24} md={8} xl={12}>
                                    <PhoneOutlined className={hospitalDetailStyles.contactIcon} />
                                    {format.formatPhoneNumber(hospital?.phone_number)}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='gutter-row' xs={24} md={8} xl={12}>
                                    {hospital?.email}
                                </Col>
                            </Row>
                        </Col>
                        <Col className={`gutter-row ${hospitalDetailStyles.contactSection}`} xs={24} xl={4}>
                            <Space direction='vertical'>
                                <Button block onClick={() => setEditHospitalModal(true)}>
                                    Edit <EditOutlined />
                                </Button>

                                <WhiteboardResetButton hospitalId={id} />
                            </Space>
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey='1'>
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <UserOutlined />
                                    Employees
                                </span>
                            }
                            key='1'
                        >
                            <HospitalEmployeeTable hospital={hospital} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                <span>
                                    <ReconciliationOutlined />
                                    Medicine
                                </span>
                            }
                            key='2'
                        >
                            <HospitalMedicineTable hospital={hospital} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </Content>
            <RemoveHospitalModal hospital={hospital} setIsOpen={setRemoveHospitalModal} isOpen={removeHospitalModal} />
            <ManageHospitalModal hospital={hospital} setIsOpen={setEditHospitalModal} isOpen={editHospitalModal} />
        </>
    );
};

export default HospitalDetail;
