import { Form, Modal } from 'antd';
import { useSuccessMessage } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useAddPackageInstructionMutation } from 'services/packages.service';
import { minsToTimeUnits, timeUnitsToMins } from 'utils/helpers/format';
import { BaseModalProps, Instruction, PackageInstructionType } from 'utils/types';
import PackageInstructionForm from '../../includes/PackageInstructionForm';
import { NEW_PACKAGE_INSTRUCTION_FIELDS } from '../../utils';
import InstructionTypeStep from './InstructionTypeStep';

interface NewInstructionWizardProps extends BaseModalProps {
    package_id: string;
}

const NewInstructionWizard: React.FC<NewInstructionWizardProps> = ({ isOpen, setIsOpen, package_id }) => {
    const [form] = Form.useForm();
    const [selectedOrder, setSelectedOrder] = useState<Instruction | null>(null);

    const [addPackageInstruction, { isSuccess, isError, isLoading }] = useAddPackageInstructionMutation();

    // Reset the wizard
    useEffect(() => {
        if (!isOpen) {
            form.resetFields();
            setSelectedOrder(null);
        }
    }, [isOpen]);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            duration_days: null,
            duration_hours: null,
            duration_mins: null,
            start_delay_days: null,
            start_delay_hours: null,
            start_delay_mins: null,
        });

        if (selectedOrder?.type_id === PackageInstructionType.MEDICATION) {
            form.setFieldsValue({
                [NEW_PACKAGE_INSTRUCTION_FIELDS.FREQUENCY]: selectedOrder.default_frequency,
                [NEW_PACKAGE_INSTRUCTION_FIELDS.ADMINISTRATION_ROUTE]: selectedOrder.default_route,
            });
        } else if (selectedOrder?.type_id === PackageInstructionType.DIAGNOSTIC) {
            form.setFieldsValue({
                [NEW_PACKAGE_INSTRUCTION_FIELDS.FREQUENCY]: selectedOrder.default_frequency,
            });
        } else if (selectedOrder?.type_id === PackageInstructionType.FLUID) {
            const {
                days: default_days,
                hours: default_hours,
                mins: default_minutes,
            } = minsToTimeUnits(selectedOrder.default_duration_mins ?? 0);

            form.setFieldsValue({
                [NEW_PACKAGE_INSTRUCTION_FIELDS.FLUID_RATE]: selectedOrder.default_rate_ml_per_hr,
                [NEW_PACKAGE_INSTRUCTION_FIELDS.ADMINISTRATION_ROUTE]: 'IV',
                [NEW_PACKAGE_INSTRUCTION_FIELDS.FLUID_VOLUME]: selectedOrder.default_volume_ml,
                [NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_DAYS]: default_days,
                [NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_HOURS]: default_hours,
                [NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_MINS]: default_minutes,
            });
        }
    }, [selectedOrder]);

    const handleClose = () => {
        // So that the callback doesn't happen before the message is shown
        setTimeout(() => {
            setIsOpen(false);
        });
    };

    // Only run null checks when order updated.
    let { selectedOrderType, selectedOrderName, selectedOrderNameForm } = useMemo(() => {
        let orderDefaults = {
            selectedOrderType: selectedOrder?.type_id ?? PackageInstructionType.DIAGNOSTIC,
            selectedOrderName: selectedOrder?.name ?? '',
            selectedOrderNameForm: selectedOrder?.name ?? '',
        };
        if (selectedOrder?.type_id === PackageInstructionType.MEDICATION) {
            orderDefaults.selectedOrderNameForm += ` (${selectedOrder?.form})`;
        }

        return orderDefaults;
    }, [selectedOrder]);

    useSuccessMessage({
        success: {
            status: isSuccess,
            message: `'${selectedOrderName}' has been successfully added as an instruction.`,
            callback: handleClose,
        },
        error: {
            status: isError,
            message: `It seems there has been an issue adding the instruction '${selectedOrderName}', please try again.`,
        },
    });

    const handleSave = (values: any) => {
        const { start_delay_days, start_delay_hours, start_delay_mins, duration_days, duration_hours, duration_mins, ...body } = values;

        const start_delay_mins_final = timeUnitsToMins(start_delay_days, start_delay_hours, start_delay_mins);
        const orderDuration = timeUnitsToMins(duration_days, duration_hours, duration_mins);
        const type_id = selectedOrder?.type_id === 'F' && 'quantity' in values ? 'OT' : selectedOrder?.type_id;

        const packageValues = {
            package_id,
            instruction_id: selectedOrder?.id,
            type_id,
            start_delay_mins: start_delay_mins_final,
            duration_mins: orderDuration,
            ...body,
        };

        addPackageInstruction(packageValues);
    };

    return (
        <Modal
            title='New Order'
            open={isOpen}
            onCancel={handleClose}
            onOk={form.submit}
            okButtonProps={{ loading: isLoading }}
            width={600}
            destroyOnClose
        >
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 6 }}
                initialValues={form.getFieldsValue()}
                wrapperCol={{ span: 18 }}
                onFinish={handleSave}
            >
                <InstructionTypeStep handleChange={setSelectedOrder} selectedInstruction={selectedOrderName} />

                {selectedOrder && (
                    <PackageInstructionForm form={form} instructionType={selectedOrderType} instructionName={selectedOrderNameForm} />
                )}
            </Form>
        </Modal>
    );
};

export default NewInstructionWizard;
