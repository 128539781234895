import { Modal } from 'antd';
import { BaseModalProps, VegUser } from 'utils/types';

interface UnassignEmployeeModalProps extends BaseModalProps {
    employee: VegUser | null;
    hospitalName: string;
}

const UnassignEmployeeModal: React.FC<UnassignEmployeeModalProps> = ({ isOpen, setIsOpen, employee }) => {
    const handleClose = () => {
        setIsOpen(false);
    };

    const handleEdit = () => {
        handleClose();
    };

    if (employee === null) {
        return null;
    }

    return (
        <Modal title='Unassign Employee' open={isOpen} onCancel={handleClose} onOk={handleEdit}>
            <div style={{ alignItems: 'center', width: '100%' }}>
                Are you sure you want to unassign <b>{`${employee.first_name} ${employee.last_name}`}</b> from this hospital?
            </div>
        </Modal>
    );
};

export default UnassignEmployeeModal;
