import { Layout } from 'antd';

const Content: React.FC = ({ children }) => {
    return (
        <Layout.Content
            className='site-layout-background'
            style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                height: 'calc(100vh - 142px)',
                overflowY: 'auto',
            }}
        >
            {children}
        </Layout.Content>
    );
};

export default Content;
