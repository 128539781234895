import { Form, message } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { CreateOrUpdateCatalogModal } from 'components/lib/CreateOrUpdateCatalogModal';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    NonMedical,
    useCreateNonMedicalMutation,
    useGetNonMedicalPriceOverridesQuery,
    useUpdateNonMedicalMutation,
} from 'services/nonMedical.service';
import { NonMedicalTab } from './NonMedicalTab';

interface CreateOrUpdateNonMedicalProps {}

interface CreateOrUpdateNonMedicalModalRef {
    openModal: () => void;
    setNonMedicalToUpdate: (nonMedical: NonMedical) => void;
}

const CreateOrUpdateNonMedicalModal: React.ForwardRefRenderFunction<CreateOrUpdateNonMedicalModalRef, CreateOrUpdateNonMedicalProps> = (
    props,
    ref,
) => {
    const [isOpen, setIsOpen] = useState(false);
    const [nonMedicalToUpdate, setNonMedicalToUpdate] = useState<NonMedical | null>(null);
    const [form] = Form.useForm();

    const {
        data: initialPriceOverrides,
        isFetching: initialPriceOverridesFetching,
        isError: initialPriceOverridesError,
    } = useGetNonMedicalPriceOverridesQuery(nonMedicalToUpdate?.id as number, {
        skip: !nonMedicalToUpdate,
        refetchOnMountOrArgChange: true,
    });

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setIsOpen(true);
        },
        setNonMedicalToUpdate: (nonMedical: NonMedical) => {
            setIsOpen(true);
            setNonMedicalToUpdate(nonMedical);
        },
    }));

    const [createNonMedical, { isLoading: createNonMedicalLoading }] = useCreateNonMedicalMutation();
    const [updateNonMedical, { isLoading: updateNonMedicalLoading }] = useUpdateNonMedicalMutation();

    const handleCloseModal = () => {
        setIsOpen(false);
        setNonMedicalToUpdate(null);
        form.resetFields();
    };

    const handleSubmit = (values: any) => {
        const { base_serial_price_cents, serial_hours, ...otherValues } = values;
        const formattedValues = {
            ...otherValues,
            product_category_id: values.product_category_id ?? null,
            specific_locations: values.specific_locations ?? [],
        };

        if ('id' in formattedValues) {
            updateNonMedical(formattedValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error updating ${values.name}`);
                    return;
                }

                message.success(`${values.name} successfully updated`);
                handleCloseModal();
            });
        } else {
            createNonMedical(formattedValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error in adding ${values.name}`);
                    return;
                }

                message.success(`${values.name} successfully created`);
                handleCloseModal();
            });
        }
    };

    return (
        <CreateOrUpdateCatalogModal
            modalTitle={`${nonMedicalToUpdate ? 'Edit' : 'New'} Non-Medical`}
            isOpen={isOpen}
            catalogItemToUpdate={nonMedicalToUpdate}
            initialPriceOverrides={initialPriceOverrides || []}
            catalogItemTab={
                <TabPane tab='Non-Medical' key='catalog_item' forceRender>
                    <NonMedicalTab nonMedicalToUpdate={nonMedicalToUpdate} form={form} />
                </TabPane>
            }
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            form={form}
            createCatalogItemLoading={createNonMedicalLoading}
            updateCatalogItemLoading={updateNonMedicalLoading}
            initialPriceOverridesFetching={initialPriceOverridesFetching}
            initialPriceOverridesError={initialPriceOverridesError}
            typeId='N'
        />
    );
};

export default forwardRef(CreateOrUpdateNonMedicalModal);
