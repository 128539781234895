import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, FormInstance, Input, Radio, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { CreateOrEditHospitalParams } from 'services/hospitals.service';
import { US_STATES } from 'utils/consts';
import { HospitalCrematorium, REGULAR_EXPRESSIONS, Urn } from 'utils/types';

interface CrematoriumTabPaneProps {
    form: FormInstance<CreateOrEditHospitalParams>;
    crematorium?: HospitalCrematorium;
}

const CrematoriumTabPane: React.FC<CrematoriumTabPaneProps> = ({ crematorium, form }) => {
    const [urns, setUrns] = useState<Partial<Urn>[]>(crematorium?.urns ?? []);

    const addItems = () => {
        setUrns((oldState) => {
            if (oldState.some((i) => !i.id || !i.name)) {
                return oldState;
            }

            return [...oldState, { id: undefined, name: undefined }];
        });
    };

    const columns: ColumnsType<Partial<Urn>> = [
        {
            title: 'ID',
            dataIndex: 'urn_id',
            key: 'urn_id',
            width: '10%',
            render: (_, record, index) => (
                <Form.Item
                    name={['crematorium', 'urns', index, 'urn_id']}
                    initialValue={record.id}
                    rules={[{ required: true, message: 'Urn ID is required' }]}
                >
                    <Input
                        placeholder='ID'
                        onChange={(e) => {
                            setUrns((oldState) =>
                                oldState.map((urns, i) => {
                                    return i === index ? { ...urns, id: e.target.value } : urns;
                                }),
                            );
                        }}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Urn Name',
            dataIndex: 'urn_name',
            key: 'urn_name',
            width: '25%',
            render: (_, record, index) => (
                <Form.Item
                    name={['crematorium', 'urns', index, 'urn_name']}
                    initialValue={record.name}
                    rules={[{ required: true, message: 'Urn name is required' }]}
                >
                    <Input
                        placeholder='Enter name'
                        onChange={(e) => {
                            setUrns((oldState) =>
                                oldState.map((urns, i) => {
                                    return i === index ? { ...urns, name: e.target.value } : urns;
                                }),
                            );
                        }}
                    />
                </Form.Item>
            ),
        },
        {
            title: '',
            width: '5%',
            render: (_, record, index) => (
                <Button
                    className='location-specific-items__delete-button'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        setUrns((oldState) =>
                            oldState.filter((item, i) =>
                                record.name || record.id ? item.name !== record.name || item.id !== record.id : i !== index,
                            ),
                        );
                    }}
                />
            ),
        },
    ];

    useEffect(() => {
        urns.forEach((urn, index) => {
            form.setFieldValue(['crematorium', 'urns', index, 'urn_id'], urn.id);
            form.setFieldValue(['crematorium', 'urns', index, 'urn_name'], urn.name);
        });
    }, [urns]);

    return (
        <>
            <Form.Item
                label='Name'
                name={['crematorium', 'name']}
                rules={[
                    {
                        required: true,
                        message: 'Please ensure the name has been entered.',
                    },
                ]}
                initialValue={crematorium?.name}
            >
                <Input />
            </Form.Item>
            <Form.Item label='Street' name={['crematorium', 'street']} initialValue={crematorium?.street ?? null}>
                <Input />
            </Form.Item>
            <Form.Item label='City' name={['crematorium', 'city']} initialValue={crematorium?.city ?? null}>
                <Input />
            </Form.Item>
            <Form.Item label='State' name={['crematorium', 'state']} initialValue={crematorium?.state ?? null}>
                <Select>
                    {US_STATES.map((sta) => (
                        <Select.Option key={sta.abbreviation} value={sta.abbreviation}>
                            {sta.abbreviation}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                label='Zip Code'
                name={['crematorium', 'zipcode']}
                rules={[
                    {
                        pattern: RegExp(REGULAR_EXPRESSIONS.ZIP_CODE),
                        message: "Please ensure the crematorium's zip code has the correct format.",
                    },
                ]}
                initialValue={crematorium?.zipcode ?? null}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label='Phone No.'
                name={['crematorium', 'phone_number']}
                rules={[
                    {
                        pattern: RegExp(REGULAR_EXPRESSIONS.PHONE_NO),
                        message: "Please ensure the crematorium's phone number has the correct format.",
                    },
                ]}
                initialValue={crematorium?.phone_number ?? null}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label='Fax'
                name={['crematorium', 'fax_number']}
                rules={[
                    {
                        pattern: RegExp(REGULAR_EXPRESSIONS.PHONE_NO),
                        message: "Please ensure the crematorium's fax has the correct format.",
                    },
                ]}
                initialValue={crematorium?.fax_number ?? null}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label='Private Burials?'
                name={['crematorium', 'has_private_burial']}
                initialValue={crematorium?.has_private_burial ?? false}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Urns
            </Divider>

            <Table className='hospital_crematorium_urns_table' columns={columns} dataSource={urns} pagination={false} />

            <Button disabled={urns.some((i) => !i.id || !i.name)} style={{ marginTop: 'var(--spacing-sm)' }} onClick={addItems}>
                <PlusOutlined /> Add Urn
            </Button>
        </>
    );
};

export default CrematoriumTabPane;
