import { Modal } from 'antd';
import { AutoComplete, Space } from 'components/lib';
import { useMemo } from 'react';
import { useGetUsersQuery } from 'services/users.service';
import { BaseModalProps, VegUser } from 'utils/types';

interface AssignEmployeeModalProps extends BaseModalProps {
    onAssign: (user: VegUser) => void;
}

const AssignEmployeeModal: React.FC<AssignEmployeeModalProps> = ({ isOpen, setIsOpen, onAssign }) => {
    const { data: users } = useGetUsersQuery(null);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleEdit = () => {
        handleClose();
        onAssign(null as any);
    };

    const usersList = useMemo<{ key: number; value: string }[]>(() => {
        if (typeof users === 'undefined') {
            return [];
        } else {
            return users
                ?.map((user) => {
                    return {
                        value: `${user.first_name} ${user.last_name}`,
                        key: user.id,
                    };
                })
                .sort((a, b) => {
                    return a.value.localeCompare(b.value);
                });
        }
    }, [users]);

    return (
        <Modal title='Assign Employee' open={isOpen} onCancel={handleClose} onOk={handleEdit}>
            <Space direction='vertical' style={{ alignItems: 'center', width: '100%' }}>
                Select an existing emoloyee from the below list:-
                <AutoComplete
                    allowClear
                    options={usersList}
                    placeholder='Select employee..'
                    style={{ width: 200 }}
                    // onChange={(val, opt) => {}}
                />
            </Space>
        </Modal>
    );
};

export default AssignEmployeeModal;
