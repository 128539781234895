import { PriceOverride } from 'utils/types';
import emptySplitApi, { DIAGNOSTIC_TAG_TYPE, PRICE_OVERRIDE_TAG_TYPE } from './emptySplitApi';
import { LocationSpecificItem } from './locationSpecificItems.service';

interface ResultWidget {
    id: number;
    name: string;
}

interface ResultValidation {
    id: number;
    name: string;
}

export interface Diagnostic {
    base_price_cents: number;
    category: string | null;
    id: number;
    name: string;
    validator_id: number;
    validator_name: string;
    result_widget_id: number;
    result_widget_name: string;
    default_frequency: string | null;
    product_category_id?: number | null;
    product_category_name?: number;
    sort_rank?: number;
    has_commission: boolean;
    one_at_a_time: boolean;
    result_label?: string;
    result_unit?: string;
    is_pricing_relative: boolean;
    avalara_tax_code: string | null;
    serial?: boolean;
    serial_hours?: number;
    base_serial_price_cents: number;
    list_price?: string;
    idexx_code?: string;
}

export interface CreateDiagnostic extends Omit<Diagnostic, 'id' | 'product_category_name' | 'result_widget_name' | 'validator_name'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}
export interface UpdateDiagnostic extends Omit<Diagnostic, 'product_category_name' | 'result_widget_name' | 'validator_name'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}

export const diagnosticsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        listResultWidget: builder.query<ResultWidget[], void>({
            query: () => 'result_widget',
            transformResponse: (response: { data: ResultWidget[] }) => {
                return response.data;
            },
        }),
        listResultValidation: builder.query<ResultValidation[], void>({
            query: () => 'result_validation',
            transformResponse: (response: { data: ResultValidation[] }) => {
                return response.data;
            },
        }),
        getDiagnostics: builder.query<Diagnostic[], void>({
            query: () => `diagnostic`,
            transformResponse: (response: { data: Diagnostic[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: DIAGNOSTIC_TAG_TYPE, id: 'LIST' }],
        }),
        getIdexxRefLabs: builder.query<Diagnostic[], void>({
            query: () => `diagnostic/idexx_ref_lab`,
            transformResponse: (response: { data: Diagnostic[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: DIAGNOSTIC_TAG_TYPE, id: 'LIST' }],
        }),
        getDiagnosticPriceOverrides: builder.query<PriceOverride[], number>({
            query: (id) => `diagnostic/${id}/price`,
            transformResponse: (response: { data: PriceOverride[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: PRICE_OVERRIDE_TAG_TYPE, id }],
        }),
        createDiagnostic: builder.mutation<void, CreateDiagnostic>({
            query({ base_price_cents, ...body }) {
                return {
                    url: `diagnostic`,
                    method: 'POST',
                    body: {
                        ...body,
                        base_price_cents: base_price_cents,
                    },
                };
            },
            invalidatesTags: () => [{ type: DIAGNOSTIC_TAG_TYPE, id: 'LIST' }],
        }),
        updateDiagnostic: builder.mutation<void, UpdateDiagnostic>({
            query({ id, ...body }) {
                return {
                    url: `diagnostic/${id}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: () => [{ type: DIAGNOSTIC_TAG_TYPE, id: 'LIST' }],
        }),
        deleteDiagnostic: builder.mutation<void, number>({
            query(id) {
                return {
                    url: `diagnostic/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: () => [{ type: DIAGNOSTIC_TAG_TYPE, id: 'LIST' }],
        }),
    }),
});

export const {
    useGetDiagnosticsQuery,
    useGetIdexxRefLabsQuery,
    useGetDiagnosticPriceOverridesQuery,
    useCreateDiagnosticMutation,
    useUpdateDiagnosticMutation,
    useListResultWidgetQuery,
    useListResultValidationQuery,
    useDeleteDiagnosticMutation,
} = diagnosticsApi;
