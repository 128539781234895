import { useState } from 'react';

const useModalWithState = <T>() => {
    const [state, setState] = useState<T | null>(null);

    const closeModal = () => {
        setState(null);
    };

    const openModal = (newState: T) => {
        setState(newState);
    };

    return [state, state !== null, openModal, closeModal] as [T, boolean, (state: T) => {}, () => {}];
};

export default useModalWithState;
