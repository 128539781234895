import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, message, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { Breadcrumb, Content, Space, SpacedContent, Table, Title } from 'components/lib';
import { ElementRef, useRef, useState } from 'react';
import { NonMedical, useDeleteNonMedicalMutation, useListNonMedicalsQuery } from 'services/nonMedical.service';
import { formatCurrency, roundTo } from 'utils/helpers/format';
import { sortRows } from 'utils/helpers/general';
import CreateOrUpdateNonMedicalModal from './CreateOrUpdateNonMedicalModal';

const NonMedicalListing = () => {
    const [search, setSearch] = useState('');
    const nonMedicalModalRef = useRef<ElementRef<typeof CreateOrUpdateNonMedicalModal>>(null);

    const { data: nonMedicalsList } = useListNonMedicalsQuery();
    const [deleteNonMedical] = useDeleteNonMedicalMutation();

    const filteredData = nonMedicalsList?.filter((nonMedical) => {
        if (search === '') return nonMedical;

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return nonMedical.name.match(regex);
    });

    const confirmDeleteNonMedical = (nonMedical: NonMedical) => {
        confirm({
            title: 'Delete Non-Medical',
            icon: <ExclamationCircleOutlined style={{ color: '#e7202e' }} />,
            content: <p>Are you sure you want to delete {nonMedical.name}?</p>,
            okText: 'Confirm',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                deleteNonMedical(nonMedical.id).then((res) => {
                    if ('error' in res) {
                        message.error(`There was an error deleting ${nonMedical.name}`);
                        return;
                    }

                    message.success(`${nonMedical.name} successfully deleted`);
                });
            },
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: sortRows('name'),
            defaultSortOrder: 'ascend',
            width: 350,
        },
        {
            title: 'Base Unit Price',
            dataIndex: 'base_price_cents',
            key: 'base_price_cents',
            sorter: sortRows('base_price_cents'),
            render: (basePrice: number) => formatCurrency(roundTo(basePrice / 100, 2)),
        },
        {
            title: 'Order Form Estimate',
            dataIndex: 'order_from_estimate',
            key: 'order_from_estimate',
            render: (orderFromEstimate: boolean) => (orderFromEstimate ? 'Yes' : 'No'),
        },
        {
            title: 'Product Category',
            dataIndex: 'product_category_name',
            key: 'product_category_name',
            sorter: sortRows('product_category_name'),
        },
        {
            title: '',
            key: 'action',
            dataIndex: 'id',
            width: 100,
            render: (id: number, nonMedical: NonMedical) => (
                <Dropdown
                    key={id}
                    overlay={
                        <Menu>
                            <Menu.Item
                                onClick={() => {
                                    nonMedicalModalRef.current?.setNonMedicalToUpdate(nonMedical);
                                }}
                            >
                                <SpacedContent>
                                    Edit <EditOutlined />
                                </SpacedContent>
                            </Menu.Item>
                            <Menu.Item onClick={() => confirmDeleteNonMedical(nonMedical)}>
                                <SpacedContent>
                                    Remove <DeleteOutlined />
                                </SpacedContent>
                            </Menu.Item>
                        </Menu>
                    }
                    placement='bottomRight'
                    arrow
                >
                    <Button>
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <Breadcrumb path={['Non-Medicals', 'Dashboard']} />
            <Content>
                <Title level={2}>Non-Medicals</Title>
                <Space direction='vertical' full>
                    <Typography.Text>{nonMedicalsList?.length} items</Typography.Text>
                    <SpacedContent>
                        <Space>
                            <Input
                                placeholder='Search Non-medical'
                                suffix={<SearchOutlined />}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Space>
                        <Button
                            onClick={() => {
                                nonMedicalModalRef.current?.openModal();
                            }}
                        >
                            New Non-Medical <PlusOutlined />
                        </Button>
                    </SpacedContent>

                    <Table columns={columns} data={filteredData ?? []} />
                </Space>

                <CreateOrUpdateNonMedicalModal ref={nonMedicalModalRef} />
            </Content>
        </>
    );
};

export default NonMedicalListing;
