import emptySplitApi, { LIST_PRODUCT_CATEGORY } from './emptySplitApi';

interface ProductCategory {
    id: number;
    name: string;
}

export const productCategoryService = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        listProductCategories: builder.query<ProductCategory[], void>({
            query: () => `/product_category`,
            transformResponse: (response: { data: ProductCategory[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: LIST_PRODUCT_CATEGORY, id: 'LIST' }],
        }),
    }),
});

export const { useListProductCategoriesQuery } = productCategoryService;
