import { Form, FormInstance, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';
import { FLUID_ROUTES } from 'utils/consts';
import { NEW_PACKAGE_INSTRUCTION_FIELDS } from '../utils';
interface PackageFluidFormProps {
    form: FormInstance;
    instructionName: string;
}

const PackageFluidForm: React.FC<PackageFluidFormProps> = ({ form, instructionName }) => {
    const [showFluidRate, setShowFluidRate] = useState<boolean>(
        form.getFieldValue(NEW_PACKAGE_INSTRUCTION_FIELDS.ADMINISTRATION_ROUTE) !== FLUID_ROUTES[3],
    );

    useEffect(() => {
        if (!showFluidRate) {
            form.setFieldsValue({
                [NEW_PACKAGE_INSTRUCTION_FIELDS.FLUID_RATE]: null,
            });
        }
    }, [showFluidRate]);

    return (
        <>
            <Form.Item label='Order'>{instructionName}</Form.Item>
            {instructionName.includes('Oxygen') ? (
                <>
                    <Form.Item<number> label='Quantity ' name='quantity' initialValue={null}>
                        <InputNumber<number> style={{ width: '100%' }} type='number' min={0} />
                    </Form.Item>
                </>
            ) : (
                <>
                    <Form.Item<number> label='Volume ' name={NEW_PACKAGE_INSTRUCTION_FIELDS.FLUID_VOLUME}>
                        <InputNumber<number> style={{ width: '100%' }} type='number' min={0} />
                    </Form.Item>
                    <Form.Item label='Fluid rate' name={NEW_PACKAGE_INSTRUCTION_FIELDS.FLUID_RATE}>
                        <InputNumber<number> style={{ width: '100%' }} disabled={!showFluidRate} min={0} />
                    </Form.Item>
                    <Form.Item
                        name={NEW_PACKAGE_INSTRUCTION_FIELDS.ADMINISTRATION_ROUTE}
                        label='Route'
                        rules={[
                            {
                                required: true,
                                message: 'Please ensure the route has been selected.',
                            },
                        ]}
                    >
                        <Select<(typeof FLUID_ROUTES)[number]>
                            onChange={(event) => {
                                setShowFluidRate(event !== FLUID_ROUTES[3]);
                            }}
                        >
                            {FLUID_ROUTES.map((route: string) => {
                                return (
                                    <Select.Option key={route} value={route}>
                                        {route}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </>
            )}
        </>
    );
};

export default PackageFluidForm;
