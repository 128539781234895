import { AutoComplete } from 'components/lib';
import { useMemo } from 'react';
import { useSearchInstructionsQuery } from 'services/instructions.service';
import { Instruction, PackageInstructionType } from 'utils/types';

const getOrderKey = (order: Instruction) => {
    return `${order.id}-${order.type_id}`;
};
interface InstructionTypeProps {
    handleChange: (order: Instruction | null) => void;
    selectedInstruction: string;
}

const InstructionType: React.FC<InstructionTypeProps> = ({ handleChange, selectedInstruction }) => {
    const { data: orders } = useSearchInstructionsQuery();
    const formattedOrders = useMemo(() => {
        if (typeof orders !== 'undefined') {
            return orders?.map((order) => {
                return { key: getOrderKey(order), value: order.name, id: order.id };
            });
        } else return [];
    }, [orders]);

    const handleSelect = (_: string, opt: { key: string; value: PackageInstructionType; id: number } | undefined) => {
        let selectedOrder = null;
        if (typeof opt !== 'undefined') {
            let { key } = opt;
            selectedOrder = orders?.find((order) => {
                return getOrderKey(order) === key;
            });
        }

        if (typeof selectedOrder !== 'undefined') {
            handleChange(selectedOrder);
        }
    };

    return (
        <div>
            <p>Please choose your order below:</p>
            <AutoComplete
                placeholder='Choose order'
                options={formattedOrders}
                allowClear
                style={{ width: '100%' }}
                onChange={handleSelect}
                defaultValue={selectedInstruction}
            />
        </div>
    );
};

export default InstructionType;
