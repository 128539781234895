import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { Breadcrumb, Button, Col, Content, Divider, PageTitle, Row, Space, SpacedContent } from 'components/lib';
import { useDependantBreadcrumbs } from 'hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEditPackageMutation, useGetPackageQuery } from 'services/packages.service';
import { format } from 'utils/helpers';
import { Package, QueryResponse, ROUTES } from 'utils/types';
import InstructionTable from './features/InstructionTable';
import NewInstructionWizard from './features/NewInstructionWizard';

const PackageDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [newInstructionModalOpen, setNewInstructionModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newPackageName, setNewPackageName] = useState('');

    const { data: _package } = useGetPackageQuery<QueryResponse<Package>>({ id });
    const [editPackage] = useEditPackageMutation();

    const [breadcrumbs] = useDependantBreadcrumbs(['Management', 'Packages'], (_package) => _package.name, _package);

    if (typeof _package === 'undefined') {
        return null;
    }

    const confirmEditPackage = () => {
        const newNameTrimmed = newPackageName.trim();

        confirm({
            title: 'Edit package',
            icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
            content: (
                <p>
                    Are you sure you want to update the package name from "{_package.name}" to "{newNameTrimmed}"?
                </p>
            ),
            okText: 'Confirm',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                editPackage({ name: newNameTrimmed, packageId: +_package.id });
                setIsEditing(false);
            },
        });
    };

    return (
        <>
            <Breadcrumb path={breadcrumbs} />
            <Content>
                <SpacedContent>
                    {isEditing ? (
                        <Input value={newPackageName} onChange={(e) => setNewPackageName(e.target.value)} />
                    ) : (
                        <PageTitle title={_package.name} backRoute={ROUTES.PACKAGES_LISTING} />
                    )}

                    {isEditing && (
                        <Button onClick={() => confirmEditPackage()} type='primary'>
                            Save
                        </Button>
                    )}

                    <Button
                        onClick={() => {
                            setIsEditing((currState) => !currState);
                            setNewPackageName(_package.name);
                        }}
                    >
                        {isEditing ? 'Cancel' : 'Edit'}
                    </Button>
                </SpacedContent>

                <Divider />

                <Row gutter={18}>
                    <Col xs={24} xl={7}>
                        <div>
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <div>
                                    <b>Created:</b> {format.formatDate(new Date())}
                                </div>
                                <div>
                                    <b>Orders:</b> {_package.instructions.length}
                                </div>
                                <Button style={{ marginTop: 10 }} block onClick={() => setNewInstructionModalOpen(true)}>
                                    Add Order
                                </Button>
                            </Space>
                        </div>
                    </Col>
                    <Col xs={24} xl={17}>
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <InstructionTable instructions={_package.instructions} />
                            <Button
                                type='dashed'
                                block
                                style={{ height: 'auto', padding: 12 }}
                                onClick={() => setNewInstructionModalOpen(true)}
                            >
                                <PlusOutlined style={{ fontSize: 22 }} />
                                <div>Add Order</div>
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Content>
            <NewInstructionWizard isOpen={newInstructionModalOpen} setIsOpen={setNewInstructionModalOpen} package_id={id} />
        </>
    );
};

export default PackageDetail;
