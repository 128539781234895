import { FilterOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Input, Select, Tooltip, Typography } from 'antd';
import { Breadcrumb, Button, CheckCross, Col, Content, PageHeader, Row, Space, Table, Title } from 'components/lib';
import { useTableFilters } from 'hooks';
import { round } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetUserDataQuery } from 'services/auth.service';
import { useGetHospitalsQuery, useLazyGetHospitalClinicsQuery } from 'services/hospitals.service';
import { useGetRdvmsQuery } from 'services/rdvm.service';
import { useGetUserQuery } from 'services/users.service';
import { BASE_QUERY_OPTIONS, RDVM_RELATIONSHIP_STATUS, US_STATES } from 'utils/consts';
import { format } from 'utils/helpers';
import { sortRows } from 'utils/helpers/general';
import { Clinic, FixAny, Hospital, MeetingNotes, QueryResponse, RdvmClinic } from 'utils/types';
import { checkIfUserHasNecessaryPermission, USER_PERMISSIONS } from 'utils/userPermissions';
import clinicsListingStyles from './index.module.css';
import NewClinicModal from './NewClinicModal';
import UploadDraftsRdvmModal from './UploadDraftsRdvmModal';

const ClinicsListing: React.FC = () => {
    const history = useHistory();
    const [newClinicModalOpen, setNewClinicModalOpen] = useState(false);
    const [uploadRdvmDraftsModalOpen, setUploadRdvmDraftsModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [search, setSearch] = useState('');
    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const { data: userData } = useGetUserQuery(null);
    const [getClinicQuery] = useLazyGetHospitalClinicsQuery<QueryResponse<Clinic[]>>();
    const { data: hospitals } = useGetHospitalsQuery<QueryResponse<Hospital[]>>({});
    const { data: rdvmClinics } = useGetRdvmsQuery();

    const userHasUploadPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_clinics_upload,
    );

    interface RdvmClinicRow {
        address: string;
        clinic_id: string;
        display_name: string;
        note: string;
        email: string;
        phone_number: string;
        fax_number: string | null;
        street: string;
        city: string;
        state: string;
        zipcode: string;
        meetings: MeetingNotes[];
        stage: string;
        last_interaction: number;
        associated_hospital_id?: number;
        associated_hospital_location?: string;
        past_six_months_revenue?: number;
        is_active: boolean;
        is_validated: boolean;
    }
    const formattedAddress = (rdvmClinic: RdvmClinic) => {
        return [rdvmClinic?.street, rdvmClinic?.city, rdvmClinic?.state, rdvmClinic?.zipcode].filter((detail) => !!detail).join(', ');
    };

    const formattedStage = (rdvmClinic: RdvmClinic) => {
        return RDVM_RELATIONSHIP_STATUS.find((status) => status.value === rdvmClinic.relationship_status)?.name ?? '';
    };

    const formattedRdvmClinics: RdvmClinicRow[] | undefined = useMemo(
        () =>
            rdvmClinics
                ?.slice()
                ?.sort((a, b) => b.last_interaction - a.last_interaction)
                .filter((rdvmClinic) => {
                    if (selectedItems.length > 0) {
                        return selectedItems.includes(rdvmClinic.associated_hospital_location ?? '');
                    }
                    return true;
                })
                .filter((rdvmClinic) => {
                    if (search === '') return true;

                    const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

                    return rdvmClinic.display_name.match(regex);
                })
                .map((rdvmClinic) => {
                    return {
                        ...rdvmClinic,
                        address: formattedAddress(rdvmClinic),
                        stage: formattedStage(rdvmClinic),
                    };
                }),
        [rdvmClinics, selectedItems, search],
    );

    const handleQueryClinics = (query: FixAny) => {
        getClinicQuery({
            ...query,
        });
    };

    const { handleChange } = useTableFilters(
        {
            onFilter: handleQueryClinics,
        },
        [userData],
    );

    const NameColumn = ({ clinic, displayName }: { displayName: string; clinic: RdvmClinic }) => (
        <>
            <div>{displayName}</div>
            {clinic.associated_hospital_location && (
                <div style={{ color: '#8C8C8C', fontSize: '12px' }}>{clinic.associated_hospital_location}</div>
            )}
        </>
    );

    const columns = useMemo(() => {
        return [
            {
                title: '',
                key: 'action',
                dataIndex: 'clinic_id',
                render: (id: string) => {
                    return <Button onClick={() => viewClinic(id)}>View</Button>;
                },
                width: 100,
            },
            {
                title: 'Name',
                dataIndex: 'display_name',
                key: 'display_name',
                defaultSortOrder: 'ascend',
                sorter: sortRows('display_name'),
                width: 200,
                render: (displayName: string, clinic: RdvmClinic) => <NameColumn {...{ clinic, displayName }} />,
            },
            {
                title: 'Last Interaction',
                dataIndex: 'last_interaction',
                key: 'last_interaction',
                sorter: sortRows('last_interaction'),
                render: (date: number) => {
                    return date ? moment.unix(date).format('LL') : '';
                },
                width: 160,
            },
            {
                title: 'Stage',
                dataIndex: 'stage',
                key: 'stage',
                sorter: sortRows('stage'),
                width: 95,
            },
            {
                title: 'Revenue',
                dataIndex: 'past_six_months_revenue',
                key: 'past_six_months_revenue',
                sorter: sortRows('past_six_months_revenue'),
                render: (record: number) => {
                    return <Tooltip title='Past 6 months revenue'>{`$${round(record / 100, 2)}`}</Tooltip>;
                },
                width: 100,
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                sorter: sortRows('address'),
                ellipsis: true,
            },
            {
                title: 'Phone No.',
                dataIndex: 'phone_number',
                key: 'phone_number',
                render: (phone: string) => {
                    return format.formatPhoneNumber(phone);
                },
                ellipsis: true,
                width: 150,
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                sorter: false,
                width: 200,
            },
            {
                title: 'Active',
                dataIndex: 'is_active',
                key: 'is_active',
                ellipsis: true,
                width: 90,
                render: (is_active: boolean) => <CheckCross checked={is_active} />,
                sorter: (a: RdvmClinicRow, b: RdvmClinicRow) => (a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1),
                align: 'center',
            },
            {
                title: 'Valid',
                dataIndex: 'is_validated',
                key: 'is_validated',
                ellipsis: true,
                width: 90,
                render: (is_validated: boolean) => (
                    <Tooltip placement='topRight' title='Clinics marked valid are locked. Validate permission required to edit.'>
                        <div>
                            <CheckCross checked={is_validated} />
                        </div>
                    </Tooltip>
                ),
                sorter: (a: RdvmClinicRow, b: RdvmClinicRow) => (a.is_validated === b.is_validated ? 0 : a.is_validated ? -1 : 1),
                align: 'center',
            },
        ];
    }, []);

    const viewClinic = (id: string) => {
        history.push(`/marketing/clinics/${id}`);
    };

    const filteredOptions = hospitals?.filter((hosp) => !selectedItems.includes(hosp.display_name));

    const getFilterOptions = () => {
        const optionsObj = filteredOptions?.reduce((hosp: any, current) => {
            (hosp[current.state] = hosp[current.state] || []).push(current);

            return hosp;
        }, {});

        if (optionsObj) {
            return Object.entries(optionsObj)
                .map(([sta, hospitals]: [string, any]) => ({
                    label: US_STATES.find((state) => state.abbreviation === sta)?.name ?? '',
                    options: hospitals.map((h: any) => ({
                        value: h.display_name,
                        label: h.display_name,
                    })),
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
        }

        return [];
    };

    getFilterOptions();

    return (
        <>
            <Breadcrumb path={['Marketing', 'Clinics']} />
            <Content>
                <PageHeader
                    title={<Title level={2}>Clinics</Title>}
                    extra={[
                        <Button onClick={() => setNewClinicModalOpen(true)} className={clinicsListingStyles.addClinicModal}>
                            Add Clinic
                            <PlusOutlined />
                        </Button>,
                        userHasUploadPermission ? (
                            <Button icon={<UploadOutlined />} onClick={() => setUploadRdvmDraftsModalOpen(true)} />
                        ) : null,
                    ]}
                />

                <Space direction='vertical'>
                    <Typography.Text>{rdvmClinics && rdvmClinics[0].total_items} items</Typography.Text>

                    <Row style={{ gap: '0 var(--spacing-sm)' }}>
                        <Col>
                            <Input
                                placeholder='Search Clinic'
                                suffix={<SearchOutlined />}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Select
                                className='filter-hospital-select'
                                style={{
                                    width: 'fit-content',
                                    minWidth: '168px',
                                    maxWidth: '500px',
                                }}
                                mode='multiple'
                                allowClear={true}
                                value={selectedItems}
                                placeholder={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <FilterOutlined style={{ marginRight: '10px' }} />
                                        <span>Filter by Hospital</span>
                                    </div>
                                }
                                options={getFilterOptions()}
                                onChange={setSelectedItems}
                            />
                        </Col>
                    </Row>

                    <Table columns={columns} data={formattedRdvmClinics || []} onChange={handleChange} />
                </Space>
            </Content>

            <NewClinicModal isOpen={newClinicModalOpen} setIsOpen={setNewClinicModalOpen} />
            <UploadDraftsRdvmModal isVisible={uploadRdvmDraftsModalOpen} setIsVisible={setUploadRdvmDraftsModalOpen} />
        </>
    );
};

export default ClinicsListing;
