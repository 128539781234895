import { Form, message } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { CreateOrUpdateCatalogModal } from 'components/lib/CreateOrUpdateCatalogModal';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Diagnostic,
    useCreateDiagnosticMutation,
    useGetDiagnosticPriceOverridesQuery,
    useUpdateDiagnosticMutation,
} from 'services/diagnostics.service';
import { DiagnosticTab } from './DiagnosticTab';

interface CreateOrUpdateDiagnosticProps {
    isRefLab: boolean;
}

interface CreateOrUpdateDiagnosticModalRef {
    openModal: () => void;
    setDiagnosticToUpdate: (diagnostic: Diagnostic) => void;
}

const CreateOrUpdateDiagnosticModal: React.ForwardRefRenderFunction<CreateOrUpdateDiagnosticModalRef, CreateOrUpdateDiagnosticProps> = (
    { isRefLab },
    ref,
) => {
    const [isOpen, setIsOpen] = useState(false);
    const [diagnosticToUpdate, setDiagnosticToUpdate] = useState<Diagnostic | null>(null);
    const [form] = Form.useForm();

    const {
        data: initialPriceOverrides,
        isFetching: initialPriceOverridesFetching,
        isError: initialPriceOverridesError,
    } = useGetDiagnosticPriceOverridesQuery(diagnosticToUpdate?.id as number, {
        skip: !diagnosticToUpdate,
        refetchOnMountOrArgChange: true,
    });

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setIsOpen(true);
        },
        setDiagnosticToUpdate: (diagnostic: Diagnostic) => {
            setIsOpen(true);
            setDiagnosticToUpdate(diagnostic);
        },
    }));

    const [createDiagnostic, { isLoading: createDiagnosticLoading }] = useCreateDiagnosticMutation();
    const [editDiagnostic, { isLoading: editDiagnosticLoading }] = useUpdateDiagnosticMutation();

    const handleCloseModal = () => {
        setIsOpen(false);
        setDiagnosticToUpdate(null);
        form.resetFields();
    };

    const handleSubmit = (values: any) => {
        const formattedValues = {
            ...values,
            product_category_id: values.product_category_id ?? null,
            specific_locations: values.specific_locations ?? [],
        };

        if ('id' in formattedValues) {
            editDiagnostic(formattedValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error updating ${values.name}`);
                    return;
                }
                message.success(`${values.name} successfully updated`);
                handleCloseModal();
            });
        } else {
            createDiagnostic(formattedValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error in adding ${values.name}`);
                    return;
                }
                message.success(`${values.name} successfully created`);
                handleCloseModal();
            });
        }
    };

    return (
        <CreateOrUpdateCatalogModal
            modalTitle={`${diagnosticToUpdate ? 'Edit' : 'New'} ${isRefLab ? 'Idexx External Labs' : 'Diagnostic'}`}
            isOpen={isOpen}
            catalogItemToUpdate={diagnosticToUpdate}
            initialPriceOverrides={initialPriceOverrides || []}
            catalogItemTab={
                <TabPane tab={`${isRefLab ? 'Idexx External Labs' : 'Diagnostic'}`} key='catalog_item' forceRender>
                    <DiagnosticTab diagnosticToUpdate={diagnosticToUpdate} form={form} />
                </TabPane>
            }
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            form={form}
            createCatalogItemLoading={createDiagnosticLoading}
            updateCatalogItemLoading={editDiagnosticLoading}
            initialPriceOverridesFetching={initialPriceOverridesFetching}
            initialPriceOverridesError={initialPriceOverridesError}
            typeId='D'
            isRefLab={isRefLab}
        />
    );
};

export default forwardRef(CreateOrUpdateDiagnosticModal);
