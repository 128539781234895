import { Checkbox, Form, FormInstance, Input, Select } from 'antd';
import InputWithLock from 'components/lib/formFields/InputWithLock';
import SelectWithLock from 'components/lib/formFields/SelectWithLock';
import { RDVM_STATUS, SUPPORTED_PHONE_NUMBER_REGEX, SUPPORTED_ZIPCODE_REGEX, US_STATES } from 'utils/consts';

interface ClinicTabPaneProps {
    userIsSuperadmin?: boolean;
    isActiveDefault?: boolean;
    isEditing?: boolean;
    form?: FormInstance;
    locked?: boolean;
}

const ClinicTabPane = ({ userIsSuperadmin, isActiveDefault, isEditing, form, locked }: ClinicTabPaneProps) => {
    return (
        <>
            <Form.Item name='display_name' label='Clinic Name:' rules={[{ required: true, message: 'Please enter the clinic name' }]}>
                <InputWithLock locked={locked} />
            </Form.Item>
            <Form.Item
                name='email'
                label='Email:'
                rules={[
                    { required: true, message: 'Please enter an email' },
                    { type: 'email', message: 'Please enter a valid email' },
                ]}
            >
                <InputWithLock locked={locked} />
            </Form.Item>
            <Form.Item
                name='phone_number'
                label='Phone:'
                rules={[{ pattern: SUPPORTED_PHONE_NUMBER_REGEX, message: 'Invalid phone number.' }]}
            >
                <InputWithLock locked={locked} placeholder='XXX-XXX-XXXX' />
            </Form.Item>
            <Form.Item name='fax_number' label='Fax:' rules={[{ pattern: SUPPORTED_PHONE_NUMBER_REGEX, message: 'Invalid fax number.' }]}>
                <InputWithLock locked={locked} placeholder='XXX-XXX-XXXX' />
            </Form.Item>
            <Form.Item name='street' label='Address:'>
                <InputWithLock locked={locked} />
            </Form.Item>
            <Form.Item name='city' label='City:'>
                <InputWithLock locked={locked} />
            </Form.Item>
            <Form.Item name='state' label='State:' rules={[{ required: true, message: 'Please select a state' }]}>
                <SelectWithLock locked={locked} placeholder='Select a state'>
                    {US_STATES.map((state) => (
                        <Select.Option key={state.abbreviation} value={state.abbreviation}>
                            {state.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
            <Form.Item name='zipcode' label='Zip:' rules={[{ pattern: SUPPORTED_ZIPCODE_REGEX, message: 'Invalid zip code.' }]}>
                <InputWithLock locked={locked} />
            </Form.Item>
            <Form.Item name='website' label='Website:' rules={[{ type: 'url', message: 'Please enter a valid URL' }]}>
                <InputWithLock locked={locked} />
            </Form.Item>
            <Form.Item name='status' label='Status:' rules={[{ required: true, message: 'Please select a status' }]}>
                <SelectWithLock locked={locked} placeholder='Select status'>
                    {RDVM_STATUS.map((rdvm) => (
                        <Select.Option key={rdvm.value} value={rdvm.value}>
                            {rdvm.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
            <Form.Item name='description' label='Description:'>
                <Input.TextArea disabled={locked} />
            </Form.Item>
            {isEditing && (
                <Form.Item name='is_active' label='Active:'>
                    <Checkbox
                        defaultChecked={isActiveDefault}
                        disabled={!userIsSuperadmin || locked}
                        onChange={(e) => form?.setFieldsValue({ is_active: e.target.checked })}
                    />
                </Form.Item>
            )}
        </>
    );
};

export default ClinicTabPane;
