import { PackageInstructionBase, PackageInstructionType } from 'utils/types';

export interface PackageInstructionForm extends PackageInstructionBase {
    type_id: PackageInstructionType;
    name: string;
    route_id?: string;
    dose?: number;
    dose_unit?: string;
}

export enum NEW_PACKAGE_INSTRUCTION_FIELDS {
    FREQUENCY = 'frequency',
    START_DELAY_DAYS = 'start_delay_days',
    START_DELAY_HOURS = 'start_delay_hours',
    START_DELAY_MINS = 'start_delay_mins',
    DURATION_DAYS = 'duration_days',
    DURATION_HOURS = 'duration_hours',
    DURATION_MINS = 'duration_mins',
    DOSE = 'dose',
    DOSE_UNIT = 'dose_unit',
    ADMINISTRATION_ROUTE = 'route_id',
    DEFAULT_ROUTE = 'default_route',
    DEFAULT_FREQUENCY = 'default_frequency',
    FLUID_VOLUME = 'fluids_volume_ml',
    FLUID_RATE = 'rate_ml_per_hr',
    FLUIDS_ID = 'fluids_id',
}
