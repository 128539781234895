import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { endpoint } from 'utils/consts';

const API_HOST = `${endpoint}/tasks`;

export const taskApi = createApi({
    reducerPath: 'taskApi',
    tagTypes: [],
    baseQuery: fetchBaseQuery({
        baseUrl: API_HOST,
        credentials: 'include',
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        },
    }),
    endpoints: () => ({}),
});
