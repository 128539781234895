import { EditOutlined, EllipsisOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu, Typography } from 'antd';
import { Breadcrumb, Button, CheckCross, Content, Space, SpacedContent, Table, Title } from 'components/lib';
import { useMediaQuery, useModalWithState } from 'hooks';
import { useMemo, useState } from 'react';
import { useGetRolesQuery, useGetUsersQuery } from 'services/users.service';
import { sortRows } from 'utils/helpers/general';
import { VegUser } from 'utils/types';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';

const UsersListing: React.FC = () => {
    const { sm } = useMediaQuery();
    const [addUserModalOpen, setAddUserModalOpen] = useState(false);
    const [currentEditingUser, editModalOpen, openEditUserModal, closeEditUserModal] = useModalWithState<VegUser>();

    const { data: users, refetch } = useGetUsersQuery(null);
    const { data: userRoles } = useGetRolesQuery(null);
    const [searchQuery, setSearchQuery] = useState('');
    const filteredUsers = users?.filter((user) => {
        if (searchQuery === '') {
            return true;
        }
        const regex = new RegExp(searchQuery.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');
        return (
            user.first_name?.match(regex) ||
            user.last_name?.match(regex) ||
            (user.first_name && user.last_name && `${user.first_name} ${user.last_name}`.match(regex)) ||
            user.email?.match(regex) ||
            userRoles?.find((role) => role.role_id === user.role_id)?.name.match(regex) ||
            user.payroll_id?.match(regex)
        );
    });
    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a: VegUser, b: VegUser) => {
                    const aFullName = (a.first_name ?? '') + (a.last_name ?? '');
                    const bFullName = (b.first_name ?? '') + (b.last_name ?? '');
                    return aFullName.localeCompare(bFullName);
                },
                width: 200,
                render: (name: string, user: VegUser) => `${user?.first_name} ${user?.last_name}`,
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                sorter: sortRows('email'),
                ellipsis: true,
                width: 200,
            },
            {
                title: 'Role',
                dataIndex: 'role_id',
                key: 'role_id',
                sorter: (a: VegUser, b: VegUser) => {
                    const aRoleName = userRoles?.find((role) => role.role_id === a.role_id)?.name ?? '';
                    const bRoleName = userRoles?.find((role) => role.role_id === b.role_id)?.name ?? '';
                    return aRoleName.localeCompare(bRoleName);
                },
                ellipsis: true,
                width: 200,
                render: (role_id: number | null) => userRoles?.find((role) => role.role_id === role_id)?.name,
            },
            {
                title: 'Payroll ID',
                dataIndex: 'payroll_id',
                key: 'payroll_id',
                sorter: (a: VegUser, b: VegUser) => (a.payroll_id ?? '').localeCompare(b.payroll_id ?? ''),
                ellipsis: true,
                width: 200,
            },
            {
                title: 'Active',
                dataIndex: 'is_active',
                key: 'is_active',
                sorter: (a: VegUser, b: VegUser) => Number(a.is_active) - Number(b.is_active),
                ellipsis: true,
                width: 100,
                render: (is_active: boolean) => <CheckCross checked={is_active} />,
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                ellipsis: true,
                width: 100,
                render: (id: string, user: VegUser) => {
                    return (
                        <Dropdown
                            key={id}
                            overlay={
                                <Menu>
                                    <Menu.Item onClick={() => openEditUserModal(user)}>
                                        <SpacedContent>
                                            Edit <EditOutlined />
                                        </SpacedContent>
                                    </Menu.Item>
                                </Menu>
                            }
                            placement='bottomRight'
                            arrow
                        >
                            <Button>
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    );
                },
            },
        ];
    }, [userRoles]);

    if (typeof users === 'undefined') {
        return null;
    }

    return (
        <>
            <Breadcrumb path={['User Management', 'Users']} />
            <Content>
                <Title level={2}>Users</Title>
                <Space direction='vertical'>
                    <Typography.Text>{users.length} items</Typography.Text>
                    <SpacedContent>
                        <Space>
                            <Input
                                placeholder='Search Users'
                                suffix={<SearchOutlined />}
                                value={searchQuery}
                                style={{ width: 280 }}
                                onChange={({ target }) => setSearchQuery(target.value)}
                            />
                        </Space>
                        <Button onClick={() => setAddUserModalOpen(true)}>
                            Add User <PlusOutlined />
                        </Button>
                    </SpacedContent>
                    {sm ? null : (
                        // <ClinicCards clinics={data} viewClinic={viewClinic} />
                        <Table columns={columns} data={filteredUsers ?? []} />
                    )}
                </Space>
            </Content>
            <AddUserModal isOpen={addUserModalOpen} setIsOpen={setAddUserModalOpen} />
            <EditUserModal isOpen={editModalOpen} setIsOpen={closeEditUserModal} user={currentEditingUser} />
        </>
    );
};

export default UsersListing;
