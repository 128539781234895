import { Form, InputNumber } from 'antd';

interface TimeFieldProps {
    minutesName: string;
    hoursName: string;
    daysName: string;
    label?: string;
    fieldClassName?: string;
}

const TimeField: React.FC<TimeFieldProps> = ({ label, minutesName, hoursName, daysName, fieldClassName }) => {
    return (
        <Form.Item label={label || 'Time'}>
            <Form.Item name={daysName || 'days'} noStyle>
                <InputNumber className={fieldClassName} placeholder='day/s' type='number' />
            </Form.Item>
            <Form.Item name={hoursName || 'hours'} noStyle>
                <InputNumber className={fieldClassName} placeholder='hour/s' type='number' max='23' />
            </Form.Item>
            <Form.Item name={minutesName || 'minutes'} noStyle>
                <InputNumber className={fieldClassName} placeholder='minute/s' type='number' max='59' />
            </Form.Item>
        </Form.Item>
    );
};

export default TimeField;
