import emptySplitApi from './emptySplitApi';

export interface LocationSpecificItem {
    hospital_id: number;
    hide_item: boolean;
}

const locationSpecificService = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getLocationSpecificItems: builder.query<LocationSpecificItem[], { item_id: number; type_id: string }>({
            query: ({ item_id, type_id }) => `location_specific_items/${type_id}/${item_id}`,
            transformResponse: (response: { data: LocationSpecificItem[] }) => {
                return response.data;
            },
        }),
    }),
});

export const { useGetLocationSpecificItemsQuery } = locationSpecificService;
