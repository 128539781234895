import { Form, Select } from 'antd';
import FrequencyField from 'components/lib/formFields/FrequencyField';
import { useGetMedicationRoutesQuery } from 'services/instructions.service';
import { NEW_PACKAGE_INSTRUCTION_FIELDS } from '../utils';

interface PackageMedicineFormProps {
    instructionName: string;
}

const PackageMedicineForm: React.FC<PackageMedicineFormProps> = ({ instructionName }) => {
    const { data: med_routes, isLoading } = useGetMedicationRoutesQuery();

    return (
        <>
            <Form.Item label='Order'>{instructionName}</Form.Item>
            <Form.Item
                name={NEW_PACKAGE_INSTRUCTION_FIELDS.ADMINISTRATION_ROUTE}
                label='Route'
                rules={[
                    {
                        required: true,
                        message: 'Please ensure the route has been entered.',
                    },
                ]}
            >
                <Select loading={isLoading}>
                    {med_routes?.map((medRoute) => {
                        return (
                            <Select.Option key={medRoute.id} value={medRoute.id}>
                                {medRoute.id}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <FrequencyField label='Frequency' name={NEW_PACKAGE_INSTRUCTION_FIELDS.FREQUENCY} />
        </>
    );
};

export default PackageMedicineForm;
