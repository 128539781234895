import { Form, FormInstance, InputNumber, Select } from 'antd';
import TimeField from 'components/lib/formFields/TimeField';
import { FLUIDS_WITH_EMPTY } from 'utils/consts';
import { NEW_PACKAGE_INSTRUCTION_FIELDS } from '../utils';
import instructionDetailStyles from './package-instruction-form.module.css';

interface PackageCriFormProps {
    form: FormInstance;
    instructionName: string;
}

const PackageCriForm: React.FC<PackageCriFormProps> = ({ form, instructionName }) => {
    return (
        <div className={instructionDetailStyles.instructionDetailWrapper}>
            <Form.Item label='Order'>{instructionName}</Form.Item>
            <Form.Item label='Dose(mg/kg/hr)' name={NEW_PACKAGE_INSTRUCTION_FIELDS.DOSE}>
                <InputNumber style={{ width: '100%' }} type='number' min={0} />
            </Form.Item>

            <Form.Item name={NEW_PACKAGE_INSTRUCTION_FIELDS.FLUIDS_ID} label='Fluids'>
                <Select placeholder='Select fluids type'>
                    {FLUIDS_WITH_EMPTY.map((fluid) => (
                        <Select.Option key={fluid.id} value={fluid.id}>
                            {fluid.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item<number> label='Volume (mL)' name={NEW_PACKAGE_INSTRUCTION_FIELDS.FLUID_VOLUME}>
                <InputNumber<number> style={{ width: '100%' }} type='number' min={0} />
            </Form.Item>
            <Form.Item label='Fluid rate (mL/hr)' name={NEW_PACKAGE_INSTRUCTION_FIELDS.FLUID_RATE}>
                <InputNumber<number> style={{ width: '100%' }} min={0} />
            </Form.Item>
            <TimeField
                label='Starting after'
                minutesName={NEW_PACKAGE_INSTRUCTION_FIELDS.START_DELAY_MINS}
                hoursName={NEW_PACKAGE_INSTRUCTION_FIELDS.START_DELAY_HOURS}
                daysName={NEW_PACKAGE_INSTRUCTION_FIELDS.START_DELAY_DAYS}
                fieldClassName={instructionDetailStyles.triInput}
            />
            <TimeField
                label='Order Duration'
                minutesName={NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_MINS}
                hoursName={NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_HOURS}
                daysName={NEW_PACKAGE_INSTRUCTION_FIELDS.DURATION_DAYS}
                fieldClassName={instructionDetailStyles.triInput}
            />
        </div>
    );
};

export default PackageCriForm;
