import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, FormInstance, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetHospitalsQuery } from 'services/hospitals.service';
import { LocationSpecificItem, useGetLocationSpecificItemsQuery } from 'services/locationSpecificItems.service';
import { getHospitalFromHospitalId } from 'utils/helpers/general';
import { HiddenInput } from '../../formFields/HiddenInput';
import HospitalColumn from '../HospitalColumn';
import './IncludeExcludeTab.css';

interface IncludeExcludeTabProps {
    typeId: string;
    form: FormInstance;
    itemId?: number;
}

const IncludeExcludeTab: React.FC<IncludeExcludeTabProps> = ({ form, typeId, itemId }) => {
    const [locations, setLocations] = useState<Partial<LocationSpecificItem>[]>([]);
    const [hideItem, setHideItem] = useState<boolean>();

    const catalogItemName = Form.useWatch('name', form);

    const { data: hospitals } = useGetHospitalsQuery({});
    const { data: initialLocations } = useGetLocationSpecificItemsQuery(
        { type_id: typeId, item_id: itemId ?? 0 },
        { refetchOnMountOrArgChange: true, skip: !itemId },
    );

    const notSetHospitals = hospitals?.filter(
        (hospital) => !locations.some((location) => location.hospital_id?.toString() === hospital.id.toString()),
    );

    const getEmptyState = () => {
        return (
            <div className='empty-state-text'>
                <p>If this order is not offered in all locations specify one or more locations to either exclude or include. </p>
                <p>
                    By excluding locations, the order will not appear as an option in the visit order dropdown. By including, only those
                    listed will have the option in the dropdown.
                </p>
            </div>
        );
    };

    const getItemName = () => {
        if (typeId === 'D') {
            return 'diagnostic';
        }

        if (typeId === 'F') {
            return 'fluid';
        }

        if (typeId === 'M') {
            return 'medication';
        }

        if (typeId === 'N') {
            return 'non-medical';
        }

        if (typeId === 'T') {
            return 'task';
        }
    };

    const columns: ColumnsType<Partial<LocationSpecificItem>> = useMemo(
        () => [
            {
                title: 'Location Name',
                dataIndex: 'hospital_id',
                key: 'hospital_id',
                width: '40%',
                sorter: (a, b) => {
                    if (!a.hospital_id || !b.hospital_id) return 0;

                    const aName = getHospitalFromHospitalId(hospitals, a.hospital_id)?.display_name ?? '';
                    const bName = getHospitalFromHospitalId(hospitals, b.hospital_id)?.display_name ?? '';

                    return aName.localeCompare(bName);
                },
                render: (_, record, index) => {
                    return record.hospital_id ? (
                        <>
                            <HiddenInput fieldName={['specific_locations', index, 'hide_item']} initialValue={hideItem} />
                            <Form.Item name={['specific_locations', index, 'hospital_id']} initialValue={record.hospital_id}>
                                {getHospitalFromHospitalId(hospitals, record.hospital_id)?.display_name}
                            </Form.Item>
                        </>
                    ) : (
                        <HospitalColumn
                            formName={['specific_locations', index, 'hospital_id']}
                            hospitals={hospitals}
                            notSetHospitals={notSetHospitals}
                            onChange={(hospital_id) => {
                                setLocations((oldState) =>
                                    oldState.map((location, i) => {
                                        return i === index ? { ...location, hospital_id } : location;
                                    }),
                                );
                            }}
                        />
                    );
                },
            },
            {
                title: '',
                width: '5%',
                render: (_, record, index) => (
                    <Button
                        className='location-specific-items__delete-button'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            setLocations((oldState) =>
                                oldState.filter((item, i) => (record.hospital_id ? item.hospital_id !== record.hospital_id : i !== index)),
                            );
                        }}
                    />
                ),
            },
        ],
        [hospitals, notSetHospitals, hideItem],
    );

    const addItems = () => {
        setLocations((oldState) => {
            if (oldState.some((i) => !i.hospital_id)) {
                return oldState;
            }

            return [...oldState, { hospital_id: undefined }];
        });
    };

    useEffect(() => {
        if (locations.length === 0) {
            setHideItem(undefined);
        }

        locations.forEach((location, index) => {
            form.setFieldValue(['specific_locations', index, 'hide_item'], hideItem ?? location.hide_item);
            form.setFieldValue(['specific_locations', index, 'hospital_id'], location.hospital_id);
        });
    }, [locations, hideItem]);

    useEffect(() => {
        if (initialLocations?.length) {
            setLocations(initialLocations);
            setHideItem(initialLocations[0].hide_item);
        } else {
            setLocations([]);
            setHideItem(undefined);
        }
    }, [initialLocations]);

    return (
        <section className='location-specific-items-container'>
            <HiddenInput fieldName='hide_item' />

            <Form.Item label='Name' className='no-margin'>
                {catalogItemName}
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Locations
            </Divider>

            {hideItem !== undefined && (
                <Alert
                    message={
                        <>
                            This {getItemName()} order {hideItem ? <b>WILL NOT</b> : <b>WILL ONLY</b>} be shown for the locations in this
                            list.
                        </>
                    }
                    showIcon
                />
            )}

            <div className='location-counter'>
                {locations.length ?? 0} / {hospitals?.length ?? 0} Locations
            </div>

            <Table columns={columns} dataSource={locations} pagination={false} locale={{ emptyText: getEmptyState() }} />

            <div className='location-specific-buttons'>
                {(() => {
                    if (hideItem === true) {
                        return (
                            <>
                                <Button
                                    onClick={addItems}
                                    disabled={notSetHospitals?.length === 0 || locations.some((i) => !i.hospital_id)}
                                    type='primary'
                                >
                                    <PlusOutlined /> Exclude Location
                                </Button>
                                <Button onClick={() => setHideItem(false)} disabled={notSetHospitals?.length === 0}>
                                    Convert to Include List
                                </Button>
                            </>
                        );
                    }

                    if (hideItem === false) {
                        return (
                            <>
                                <Button
                                    onClick={addItems}
                                    disabled={notSetHospitals?.length === 0 || locations.some((i) => !i.hospital_id)}
                                    type='primary'
                                >
                                    <PlusOutlined /> Include Location
                                </Button>
                                <Button onClick={() => setHideItem(true)} disabled={notSetHospitals?.length === 0}>
                                    Convert to Exclude List
                                </Button>
                            </>
                        );
                    }

                    return (
                        <>
                            <Button
                                onClick={() => {
                                    setHideItem(true);
                                    addItems();
                                }}
                                disabled={notSetHospitals?.length === 0 || locations.some((i) => !i.hospital_id)}
                            >
                                <PlusOutlined /> Exclude Location
                            </Button>
                            <Button
                                onClick={() => {
                                    setHideItem(false);
                                    addItems();
                                }}
                                disabled={notSetHospitals?.length === 0 || locations.some((i) => !i.hospital_id)}
                            >
                                <PlusOutlined /> Include Location
                            </Button>
                        </>
                    );
                })()}
            </div>
        </section>
    );
};

export default IncludeExcludeTab;
