import { Form, message } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { CreateOrUpdateCatalogModal } from 'components/lib/CreateOrUpdateCatalogModal';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Fluid, useCreateFluidMutation, useGetFluidsPriceOverridesQuery, useUpdateFluidMutation } from 'services/fluids.service';
import { FluidsTab } from './FluidsTab';

interface CreateOrUpdateFluidProps {}

interface CreateOrUpdateFluidModalRef {
    openModal: () => void;
    setFluidToUpdate: (fluid: Fluid) => void;
}

const CreateOrUpdateFluidModal: React.ForwardRefRenderFunction<CreateOrUpdateFluidModalRef, CreateOrUpdateFluidProps> = (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [fluidToUpdate, setFluidToUpdate] = useState<Fluid | null>(null);
    const [form] = Form.useForm();

    const {
        data: initialPriceOverrides,
        isFetching: initialPriceOverridesFetching,
        isError: initialPriceOverridesError,
    } = useGetFluidsPriceOverridesQuery(fluidToUpdate?.id as number, {
        skip: !fluidToUpdate,
        refetchOnMountOrArgChange: true,
    });

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setIsOpen(true);
        },
        setFluidToUpdate: (fluid: Fluid) => {
            setIsOpen(true);
            setFluidToUpdate(fluid);
        },
    }));

    const [createFluid, { isLoading: createFluidLoading }] = useCreateFluidMutation();
    const [updateFluid, { isLoading: updateFluidLoading }] = useUpdateFluidMutation();

    const handleCloseModal = () => {
        setIsOpen(false);
        setFluidToUpdate(null);
        form.resetFields();
    };

    const handleSubmit = (values: any) => {
        const { base_serial_price_cents, serial_hours, ...otherValues } = values;
        const formattedValues = {
            ...otherValues,
            default_rate_mcl_per_hr: values.default_rate_mcl_per_hr ? values.default_rate_mcl_per_hr * 1000 : null,
            product_category_id: values.product_category_id ?? null,
            specific_locations: values.specific_locations ?? [],
        };

        if ('id' in formattedValues) {
            updateFluid(formattedValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error updating ${values.name}`);
                    return;
                }

                message.success(`${values.name} successfully updated`);
                handleCloseModal();
            });
        } else {
            createFluid(formattedValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error in adding ${values.name}`);
                    return;
                }

                message.success(`${values.name} successfully created`);
                handleCloseModal();
            });
        }
    };

    return (
        <CreateOrUpdateCatalogModal
            modalTitle={`${fluidToUpdate ? 'Edit' : 'New'} Fluid`}
            isOpen={isOpen}
            catalogItemToUpdate={fluidToUpdate}
            initialPriceOverrides={initialPriceOverrides || []}
            catalogItemTab={
                <TabPane tab='Fluid' key='catalog_item' forceRender>
                    <FluidsTab fluidToUpdate={fluidToUpdate} form={form} />
                </TabPane>
            }
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            form={form}
            createCatalogItemLoading={createFluidLoading}
            updateCatalogItemLoading={updateFluidLoading}
            initialPriceOverridesFetching={initialPriceOverridesFetching}
            initialPriceOverridesError={initialPriceOverridesError}
            typeId='F'
        />
    );
};

export default forwardRef(CreateOrUpdateFluidModal);
