import { Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import textEditorStyles from './text-editor.module.css';

interface TextEditorProps {
    content: EditorState;
    onChange: (a: any) => void;
    initialState?: EditorState;
}
const TextEditor: React.FC<TextEditorProps> = ({ content, onChange, initialState }) => {
    return (
        <Editor
            editorState={initialState ?? content}
            toolbarClassName='toolbarClassName'
            wrapperClassName='wrapperClassName'
            editorClassName={textEditorStyles.editor}
            onEditorStateChange={onChange}
            toolbar={{
                options: ['inline', 'blockType', 'list', 'history', 'link'],
                inline: {
                    options: ['bold', 'italic'],
                },
                blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                },
                link: {
                    options: ['link'],
                },
                list: {
                    options: ['unordered', 'ordered'],
                },
            }}
        />
    );
};

export default TextEditor;
