import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import Button from '../Button';
import SpacedContent from '../SpacedContent';
import Title from '../Title';

interface PageTitleProps {
    title: string | React.ReactNode;
    backRoute?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, backRoute }) => {
    const history = useHistory();
    return (
        <Title level={2}>
            <div style={{ flex: 'none' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    {backRoute && (
                        <Button shape='circle' size='small' onClick={() => history.push(backRoute)}>
                            <ArrowLeftOutlined />
                        </Button>
                    )}
                    <SpacedContent>{title}</SpacedContent>
                </div>
            </div>
        </Title>
    );
};

export default PageTitle;
