import { LockOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
import InputWithLock from 'components/lib/formFields/InputWithLock';
import SelectWithLock from 'components/lib/formFields/SelectWithLock';
import { FC } from 'react';
import { RDVM_EMERGENCY_CARE_OFFERINGS, RDVM_FACILITY, RDVM_HOURS, RDVM_WEEKEND_OPERATIONS } from 'utils/consts';

interface AdvancedTabPaneProps {
    locked?: boolean;
}

const AdvancedTabPane: FC<AdvancedTabPaneProps> = ({ locked }) => {
    return (
        <>
            <Form.Item name='parent_organization' label='Parent Organization:'>
                <InputWithLock locked={locked} />
            </Form.Item>
            <Form.Item name='facility_type' label='Facility Type:'>
                <SelectWithLock locked={locked} placeholder='Select facility type'>
                    {RDVM_FACILITY.map((facility) => (
                        <Select.Option key={facility.value} value={facility.value}>
                            {facility.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
            <Form.Item name='emergency_care_offerings' label='Emergency Care Offerings:'>
                <SelectWithLock locked={locked} placeholder='Select emergency care offering'>
                    {RDVM_EMERGENCY_CARE_OFFERINGS.map((emc) => (
                        <Select.Option key={emc.value} value={emc.value}>
                            {emc.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
            <Form.Item name='hours_category' label='Hours:'>
                <SelectWithLock locked={locked} placeholder='Select hours'>
                    {RDVM_HOURS.map((hours) => (
                        <Select.Option key={hours.value} value={hours.value}>
                            {hours.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
            <Form.Item name='weekend_operations' label='Weekend Operations:'>
                <SelectWithLock locked={locked} placeholder='Select weekend operations' suffixIcon={<LockOutlined />}>
                    {RDVM_WEEKEND_OPERATIONS.map((weekend_operation) => (
                        <Select.Option key={weekend_operation.value} value={weekend_operation.value}>
                            {weekend_operation.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
        </>
    );
};

export default AdvancedTabPane;
