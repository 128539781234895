import { Select } from 'antd';
import React from 'react';
import { useListProductCategoriesQuery } from 'services/productCategory.service';

interface ProductCategorySelectProps {
    onChange: (value: any) => void;
    defaultValue?: number | null;
}

const ProductCategorySelect: React.FC<ProductCategorySelectProps> = ({ defaultValue, onChange }) => {
    const { data, isLoading } = useListProductCategoriesQuery();

    return (
        <Select defaultValue={defaultValue} loading={isLoading} onChange={onChange} allowClear>
            {data?.map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                    {name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default ProductCategorySelect;
