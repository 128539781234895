import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, message, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { Breadcrumb, Content, Space, SpacedContent, Table, Title } from 'components/lib';
import { ElementRef, useRef, useState } from 'react';
import { Fluid, useDeleteFluidMutation, useListFluidsQuery } from 'services/fluids.service';
import { formatCurrency, roundTo } from 'utils/helpers/format';
import { sortRows } from 'utils/helpers/general';
import CreateOrUpdateFluidModal from './CreateOrUpdateFluidModal';

const FluidsListing = () => {
    const [search, setSearch] = useState('');
    const fluidModalRef = useRef<ElementRef<typeof CreateOrUpdateFluidModal>>(null);

    const { data: fluidsList } = useListFluidsQuery();
    const [deleteFluid] = useDeleteFluidMutation();

    const filteredData = fluidsList?.filter((fluid) => {
        if (search === '') return fluid;

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return fluid.name.match(regex);
    });

    const confirmDeleteFluid = (fluid: Fluid) => {
        confirm({
            title: 'Delete Fluid',
            icon: <ExclamationCircleOutlined style={{ color: '#e7202e' }} />,
            content: <p>Are you sure you want to delete {fluid.name}?</p>,
            okText: 'Confirm',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                deleteFluid(fluid.id).then((res) => {
                    if ('error' in res) {
                        message.error(`There was an error deleting ${fluid.name}`);
                        return;
                    }

                    message.success(`${fluid.name} successfully deleted`);
                });
            },
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 350,
            sorter: sortRows('name'),
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Abbreviation',
            dataIndex: 'abbreviation',
            key: 'abbreviation',
            sorter: sortRows('abbreviation'),
        },
        {
            title: 'Supplemental',
            dataIndex: 'supplemental_name',
            key: 'supplemental_name',
            sorter: sortRows('supplemental_name'),
        },
        {
            title: 'Base Unit Price',
            dataIndex: 'base_price_cents',
            key: 'base_price_cents',
            sorter: sortRows('base_price_cents'),
            render: (basePrice: number) => formatCurrency(roundTo(basePrice / 100, 2)),
        },
        {
            title: 'Sort Rank',
            dataIndex: 'sort_rank',
            key: 'sort_rank',
            width: 120,
            sorter: sortRows('sort_rank'),
        },
        {
            title: 'Product Category',
            dataIndex: 'product_category_name',
            key: 'product_category_name',
            sorter: sortRows('product_category_name'),
        },
        {
            title: '',
            key: 'action',
            dataIndex: 'id',
            width: 100,
            render: (id: number, fluid: Fluid) => (
                <Dropdown
                    key={id}
                    overlay={
                        <Menu>
                            <Menu.Item
                                onClick={() => {
                                    fluidModalRef.current?.setFluidToUpdate(fluid);
                                }}
                            >
                                <SpacedContent>
                                    Edit <EditOutlined />
                                </SpacedContent>
                            </Menu.Item>
                            <Menu.Item onClick={() => confirmDeleteFluid(fluid)}>
                                <SpacedContent>
                                    Remove <DeleteOutlined />
                                </SpacedContent>
                            </Menu.Item>
                        </Menu>
                    }
                    placement='bottomRight'
                    arrow
                >
                    <Button>
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    return (
        <>
            <Breadcrumb path={['Fluids', 'Dashboard']} />
            <Content>
                <Title level={2}>Fluids</Title>
                <Space direction='vertical' full>
                    <Typography.Text>{fluidsList?.length} items</Typography.Text>
                    <SpacedContent>
                        <Space>
                            <Input
                                placeholder='Search Fluid'
                                suffix={<SearchOutlined />}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Space>
                        <Button
                            onClick={() => {
                                fluidModalRef.current?.openModal();
                            }}
                        >
                            New Fluid <PlusOutlined />
                        </Button>
                    </SpacedContent>

                    <Table columns={columns} data={filteredData ?? []} />
                </Space>
                <CreateOrUpdateFluidModal ref={fluidModalRef} />
            </Content>
        </>
    );
};

export default FluidsListing;
