import { CURRENT_USER } from 'mock.data';
import { VegRole, VegUser } from 'utils/types';
import emptySplitApi, { USER_TAG_TYPE } from './emptySplitApi';

export interface CreateUserParams extends Omit<VegUser, 'id' | 'is_active'> {
    okta_subject: string | null;
    hospital_bypass: boolean;
    assigned_hospitals: number[] | null;
}
export type EditUserParams = Omit<VegUser, 'email'>;

export const usersApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (searchQuery) => `user`,
            transformResponse: (response: { data: VegUser[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: USER_TAG_TYPE, id: 'LIST' }],
        }),
        getRoles: builder.query({
            query: (searchQuery) => `role`,
            transformResponse: (response: { data: VegRole[] }) => {
                return response.data;
            },
        }),
        getUser: builder.query({
            providesTags: (result, error, id) => [{ type: USER_TAG_TYPE, id: id }],
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                return {
                    data: CURRENT_USER,
                };
            },
        }),
        createUser: builder.mutation<VegUser, CreateUserParams>({
            query(body) {
                return {
                    url: `user`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: { data: VegUser }) => {
                return response.data;
            },
            invalidatesTags: () => [{ type: USER_TAG_TYPE, id: 'LIST' }],
        }),
        deteleteUser: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                return { data: true };
            },
        }),
        editUser: builder.mutation<VegUser, EditUserParams>({
            query({ id, ...rest }) {
                return {
                    url: `user/${id}/infos`,
                    method: 'PUT',
                    body: rest,
                };
            },
            transformResponse: (response: { data: VegUser }) => {
                return response.data;
            },
            invalidatesTags: () => [{ type: USER_TAG_TYPE, id: 'LIST' }],
        }),
    }),
});

export const { useGetUserQuery, useGetUsersQuery, useGetRolesQuery, useCreateUserMutation, useEditUserMutation } = usersApi;
