import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import sortableListStyles from './sortable-list.module.css';

export type RenderItem = (abc: any, index: number) => JSX.Element;

interface SortableComponentProps {
    items: any[];
    setItems: (items: any[]) => void;
    renderItem: RenderItem;
}

interface SortableItemProps {
    value: string;
    renderItem: RenderItem;
    _index: number;
    index: number;
}

interface SortableContainerWrapperProps {
    items: string[];
    renderItem: RenderItem;
    onSortEnd: any;
    helperClass: any;
}

const arrayMove: (array: string[], from: number, to: number) => string[] = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);

    return array;
};

const SortableItem = ({ value, renderItem, _index }: SortableItemProps) => (
    <>
        {SortableElement(() => {
            return (
                <li style={{ zIndex: 99999999 }} className={sortableListStyles.sortableItem}>
                    {renderItem(value, _index)}
                </li>
            );
        })}
    </>
);

const SortableContainerWrapper = ({ items, renderItem }: SortableContainerWrapperProps) => (
    <>
        {SortableContainer(() => {
            return (
                <ul className={sortableListStyles.sortableList}>
                    {items.map((value, index) => (
                        <SortableItem key={`item-${value}`} index={index} value={value} _index={index} renderItem={renderItem} />
                    ))}
                </ul>
            );
        })}
    </>
);

const SortableList: React.FC<SortableComponentProps> = ({ items, setItems, renderItem }) => {
    const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
        setItems(arrayMove(items, oldIndex, newIndex));
    };

    return (
        <SortableContainerWrapper
            items={items}
            onSortEnd={onSortEnd}
            helperClass={sortableListStyles.sortableHelper}
            renderItem={renderItem}
        />
    );
};

export default SortableList;
