import { AutoComplete, Button, Space } from 'components/lib';
import { DIFFERENTIALS } from 'mock.data';
import { useMemo, useState } from 'react';
import { Differential } from 'utils/types';

interface AssignNewDifferentialPopoverProps {
    onAdd: (diff: Differential) => void;
}

const AssignNewDifferentialPopover: React.FC<AssignNewDifferentialPopoverProps> = ({ onAdd }) => {
    const [currentDiff, setCurrentDiff] = useState<Differential | null>(null);
    let diffs = useMemo(() => {
        return DIFFERENTIALS.map((d) => ({ key: d.id, value: d.name }));
    }, []);

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <AutoComplete
                placeholder='differentials'
                allowClear
                options={diffs}
                style={{ width: '100%' }}
                onChange={(val, opt) => {
                    setCurrentDiff(typeof opt !== 'undefined' ? { id: opt.key, name: opt.value } : null);
                }}
            />
            <Button
                block
                onClick={() => {
                    if (currentDiff) {
                        onAdd(currentDiff);
                    }
                }}
            >
                Assign
            </Button>
        </Space>
    );
};

export default AssignNewDifferentialPopover;
