import { Drawer } from 'antd';

export interface SideDrawerProps {
    isOpen: boolean;
    setIsOpen: (is: boolean) => void;
    closable?: boolean;
    title?: string;
}

const SideDrawer: React.FC<SideDrawerProps> = ({ isOpen, setIsOpen, title = 'title', closable = true, children }) => {
    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <Drawer
            style={{ position: 'absolute', height: 'calc(100vh - 64px)' }}
            width={340}
            getContainer={false}
            title={title}
            placement='right'
            closable={closable}
            onClose={onClose}
            open={isOpen}
        >
            {children}
        </Drawer>
    );
};

export default SideDrawer;
