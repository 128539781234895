import { Form, message } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { CreateOrUpdateCatalogModal } from 'components/lib/CreateOrUpdateCatalogModal';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Task, useCreateTaskMutation, useEditTaskMutation, useGetTaskPriceOverridesQuery } from 'services/tasks.service';
import { TaskTab } from './TaskTab';

interface CreateOrUpdateTaskProps {}

interface CreateOrUpdateTaskModalRef {
    openModal: () => void;
    setTaskToUpdate: (task: Task) => void;
}

const CreateOrUpdateTaskModal: React.ForwardRefRenderFunction<CreateOrUpdateTaskModalRef, CreateOrUpdateTaskProps> = (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [taskToUpdate, setTaskToUpdate] = useState<Task | null>(null);
    const [form] = Form.useForm();

    const {
        data: initialPriceOverrides,
        isFetching: initialPriceOverridesFetching,
        isError: initialPriceOverridesError,
    } = useGetTaskPriceOverridesQuery(taskToUpdate?.id as number, {
        skip: !taskToUpdate,
        refetchOnMountOrArgChange: true,
    });

    useImperativeHandle(ref, () => ({
        openModal: () => setIsOpen(true),
        setTaskToUpdate: (task: Task) => {
            setIsOpen(true);
            setTaskToUpdate(task);
        },
    }));

    const [createTask, { isLoading: createTaskLoading }] = useCreateTaskMutation();
    const [editTask, { isLoading: updateTaskLoading }] = useEditTaskMutation();

    const handleCloseModal = () => {
        setIsOpen(false);
        setTaskToUpdate(null);
        form.resetFields();
    };

    const handleSubmit = (values: any) => {
        const formattedValues = {
            ...values,
            product_category_id: values.product_category_id ?? null,
            specific_locations: values.specific_locations ?? [],
        };

        if ('id' in formattedValues) {
            editTask(formattedValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error updating ${values.name}`);
                    return;
                }
                message.success(`${values.name} successfully updated`);
                handleCloseModal();
            });
        } else {
            createTask(formattedValues).then((res) => {
                if ('error' in res) {
                    message.error(`There was an error in adding ${values.name}`);
                    return;
                }

                message.success(`${values.name} successfully created`);
                handleCloseModal();
            });
        }
    };

    return (
        <CreateOrUpdateCatalogModal
            modalTitle={`${taskToUpdate ? 'Edit' : 'New'} Task`}
            isOpen={isOpen}
            catalogItemToUpdate={taskToUpdate}
            initialPriceOverrides={initialPriceOverrides || []}
            catalogItemTab={
                <TabPane tab='Task' key='catalog_item' forceRender>
                    <TaskTab taskToUpdate={taskToUpdate} form={form} />
                </TabPane>
            }
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            form={form}
            createCatalogItemLoading={createTaskLoading}
            updateCatalogItemLoading={updateTaskLoading}
            initialPriceOverridesFetching={initialPriceOverridesFetching}
            initialPriceOverridesError={initialPriceOverridesError}
            typeId='T'
        />
    );
};

export default forwardRef(CreateOrUpdateTaskModal);
