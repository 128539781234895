import { Breadcrumb as AntBreadcrumb } from 'antd';

interface BreadcrumbProps {
    path: string[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ path }) => {
    return (
        <AntBreadcrumb style={{ margin: '16px 0' }}>
            {path.map((p) => {
                return <AntBreadcrumb.Item key={p}>{p}</AntBreadcrumb.Item>;
            })}
        </AntBreadcrumb>
    );
};

export default Breadcrumb;
