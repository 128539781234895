import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { endpoint } from 'utils/consts';

const API_HOST = `${endpoint}/admin_panel`;

export const CLINIC_TAG_TYPE = 'Clinic';
export const USER_TAG_TYPE = 'User';
export const MEDICATION_TAG_TYPE = 'Medication';
export const CRI_TAG_TYPE = 'Cri';
export const PACKAGE_TAG_TYPE = 'Package';
export const HOSPITAL_TAG_TYPE = 'Hospital';
export const DIAGNOSTIC_TAG_TYPE = 'Diagnostic';
export const TASK_TAG_TYPE = 'Task';
export const COMPLAINT_TAG_TYPE = 'Complaint';
export const DIFFERENTIAL_TAG_TYPE = 'Differential';
export const INSTRUCTION_TAG_TYPE = 'Instruction';
export const MACRO_TAG_TYPE = 'Macro';
export const RDVM_CLINIC = 'rDVM Clinic';
export const FLUIDS_TAG_TYPE = 'Fluids';
export const LIST_PRODUCT_CATEGORY = 'Product categories';
export const SUPPLEMENTAL_TAG_TYPE = 'Supplemental';
export const NON_MEDICAL_TAG_TYPE = 'Non-medical';
export const PRICE_OVERRIDE_TAG_TYPE = 'Price Override';

const emptySplitApi = createApi({
    reducerPath: 'api',
    tagTypes: [
        CLINIC_TAG_TYPE,
        USER_TAG_TYPE,
        MEDICATION_TAG_TYPE,
        CRI_TAG_TYPE,
        PACKAGE_TAG_TYPE,
        HOSPITAL_TAG_TYPE,
        DIAGNOSTIC_TAG_TYPE,
        TASK_TAG_TYPE,
        COMPLAINT_TAG_TYPE,
        DIFFERENTIAL_TAG_TYPE,
        INSTRUCTION_TAG_TYPE,
        MACRO_TAG_TYPE,
        RDVM_CLINIC,
        FLUIDS_TAG_TYPE,
        LIST_PRODUCT_CATEGORY,
        SUPPLEMENTAL_TAG_TYPE,
        NON_MEDICAL_TAG_TYPE,
        PRICE_OVERRIDE_TAG_TYPE,
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: API_HOST,
        credentials: 'include',
        prepareHeaders: (headers, { getState, endpoint }) => {
            if (!(endpoint === 'uploadRdvmFile')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});

export default emptySplitApi;
