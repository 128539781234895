import Markdown from 'react-markdown';
import markdownStyles from './index.module.css';

interface VegMarkdownProps {
    content: string;
}
const VegMarkdown: React.FC<VegMarkdownProps> = ({ content }) => {
    return (
        <div className={markdownStyles.markdown}>
            <Markdown>{content}</Markdown>
        </div>
    );
};

export default VegMarkdown;
