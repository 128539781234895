import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = () => {
    let history = useHistory();
    let { search } = useLocation();

    let queries = useMemo(() => {
        if (search.length) {
            return JSON.parse('{"' + decodeURI(search.replace(/\?/g, '').replace(/&/g, '","').replace(/=/g, '":"')) + '"}');
        }
        return {};
    }, [search]);

    const setQuery = (obj: {}) => {
        history.push({
            search: new URLSearchParams({ ...queries, ...obj }).toString(),
        });
    };

    const removeQuery = () => {};

    return {
        queries,
        setQuery,
        removeQuery,
    };
};

export default useQueryParams;
