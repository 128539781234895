import { DownloadOutlined } from '@ant-design/icons';
import { DatePicker, DatePickerProps, Form, Modal, Space, Table } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Breadcrumb, Button, Content, Title } from 'components/lib';
import moment from 'moment';
import { MouseEventHandler, useState } from 'react';
import {
    useLazyAccountsReceivableInvoicesQuery,
    useLazyAllLocationsPaymentsQuery,
    useLazyPaymentsOnUncollectibleQuery,
    useLazyRevenueDetailQuery,
    useLazyUncollectibleInvoicesQuery,
} from 'services/reports.service';

interface ReportsProps {}

const { RangePicker } = DatePicker;

enum REPORTS {
    accounts_receivable_invoices = 'Accounts Receivable Invoices',
    uncollectible_invoices = 'Uncollectible Invoices',
    payments_on_uncollectible = 'Payments on Uncollectible',
    revenue_detail = 'Revenue Detail',
    payments_report = 'Payments Report',
}

export const Reports: React.FC<ReportsProps> = () => {
    const [downloadAccountsReceivableInvoices] = useLazyAccountsReceivableInvoicesQuery();
    const [downloadUncollectibleInvoices] = useLazyUncollectibleInvoicesQuery();
    const [downloadPaymentsOnUncollectible] = useLazyPaymentsOnUncollectibleQuery();
    const [downloadRevenueDetail] = useLazyRevenueDetailQuery();
    const [downloadAllLocationPayments] = useLazyAllLocationsPaymentsQuery();
    const [selectedReport, setSelectedReport] = useState<REPORTS | undefined>();
    const [isOpen, setIsOpen] = useState(false);
    const [form] = Form.useForm();
    const [date, setDate] = useState<moment.Moment | undefined>(undefined);
    const [month, setMonth] = useState<number>(0);
    const [year, setYear] = useState<number>(0);
    const [dateRange, setDateRange] = useState<moment.Moment[]>([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 200,
            render: (onClick: MouseEventHandler) => {
                return (
                    <Button icon={<DownloadOutlined />} onClick={onClick}>
                        Download CSV
                    </Button>
                );
            },
        },
    ];

    const data = [
        {
            name: REPORTS.accounts_receivable_invoices,
            action: () => handleDownloadButton(REPORTS.accounts_receivable_invoices),
        },
        {
            name: REPORTS.uncollectible_invoices,
            action: () => handleDownloadButton(REPORTS.uncollectible_invoices),
        },
        {
            name: REPORTS.payments_on_uncollectible,
            action: () => handleDownloadButton(REPORTS.payments_on_uncollectible),
        },
        {
            name: REPORTS.revenue_detail,
            action: () => handleDownloadButton(REPORTS.revenue_detail),
        },
        {
            name: REPORTS.payments_report,
            action: () => handleDownloadButton(REPORTS.payments_report),
        },
    ];

    const handleClose = () => {
        setIsOpen(false);
        setSelectedReport(undefined);
        form.resetFields();
    };

    const handleDownloadButton = (report: REPORTS) => {
        setSelectedReport(report);
        setIsOpen(true);
    };

    const disabledDate: DatePickerProps['disabledDate'] = (current) => {
        return current && current > moment().endOf('day');
    };

    const disabledMonth: RangePickerProps['disabledDate'] = (current: any) => {
        return current > moment().endOf('month');
    };

    return (
        <>
            <Breadcrumb path={['Reports', 'Dashboard']} />
            <Content>
                <Title level={2}>Reports</Title>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                    <Table style={{ maxWidth: 600 }} columns={columns} dataSource={data} pagination={false} />
                </Space>
            </Content>
            <Modal title={selectedReport} open={isOpen} centered={true} onOk={form.submit} onCancel={handleClose}>
                <Form
                    form={form}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={() => {
                        if (selectedReport === REPORTS.accounts_receivable_invoices) {
                            if (date) {
                                downloadAccountsReceivableInvoices({
                                    report_date: date.format('MM_DD_YYYY'),
                                });
                            }
                        } else if (selectedReport === REPORTS.uncollectible_invoices) {
                            if (dateRange) {
                                downloadUncollectibleInvoices({
                                    report_start_date: dateRange[0].format('MM_DD_YYYY'),
                                    report_end_date: dateRange[1].format('MM_DD_YYYY'),
                                });
                            }
                        } else if (selectedReport === REPORTS.payments_on_uncollectible) {
                            if (dateRange) {
                                downloadPaymentsOnUncollectible({
                                    report_start_date: dateRange[0].format('MM_DD_YYYY'),
                                    report_end_date: dateRange[1].format('MM_DD_YYYY'),
                                });
                            }
                        } else if (selectedReport === REPORTS.revenue_detail) {
                            downloadRevenueDetail({
                                month: month,
                                year: year,
                            });
                        } else if (selectedReport === REPORTS.payments_report) {
                            downloadAllLocationPayments({ year: year });
                        }
                        setIsOpen(false);
                        form.resetFields();
                    }}
                    onFinishFailed={() => {}}
                >
                    {selectedReport === REPORTS.accounts_receivable_invoices && (
                        <Form.Item name={'date'} style={{ margin: 0 }} rules={[{ required: true }]}>
                            <DatePicker
                                disabledDate={disabledDate}
                                onChange={(date) => {
                                    setDate(date || undefined);
                                }}
                            />
                        </Form.Item>
                    )}
                    {selectedReport === REPORTS.revenue_detail && (
                        <Form.Item name={'date'} style={{ margin: 0 }} rules={[{ required: true }]}>
                            <DatePicker
                                autoFocus
                                onChange={(date, dateString) => {
                                    if (date) {
                                        setMonth(date.get('month') + 1);
                                        setYear(date.get('year'));
                                    } else {
                                        setMonth(0);
                                        setYear(0);
                                    }
                                }}
                                picker='month'
                                disabledDate={disabledMonth}
                            />
                        </Form.Item>
                    )}
                    {selectedReport === REPORTS.payments_report && (
                        <Form.Item name={'date'} style={{ margin: 0 }} rules={[{ required: true }]}>
                            <DatePicker
                                autoFocus
                                onChange={(date, dateString) => {
                                    if (date) {
                                        setYear(date.get('year'));
                                    } else {
                                        setYear(0);
                                    }
                                }}
                                picker='year'
                                disabledDate={disabledMonth}
                            />
                        </Form.Item>
                    )}
                    {selectedReport !== REPORTS.revenue_detail &&
                        selectedReport !== REPORTS.accounts_receivable_invoices &&
                        selectedReport !== REPORTS.payments_report && (
                            <Form.Item name={'date_range'} style={{ margin: 0 }} rules={[{ required: true }]}>
                                <RangePicker
                                    disabledDate={disabledDate}
                                    onChange={(dates) => {
                                        const keys = dates?.map((range) => {
                                            return range?.utc() ?? moment();
                                        });
                                        if (keys?.every(Boolean)) {
                                            setDateRange(keys);
                                        } else {
                                            setDateRange([]);
                                        }
                                    }}
                                />
                            </Form.Item>
                        )}
                </Form>
            </Modal>
        </>
    );
};
