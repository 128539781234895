import { Popover } from 'antd';
import { Button, SideDrawer, SortableList, Space, SpacedContent } from 'components/lib';
import { SideDrawerProps } from 'components/lib/SideDrawer';
import { useEffect, useMemo, useState } from 'react';
import { format } from 'utils/helpers';
import { Differential } from 'utils/types';
import AssignNewDifferentialPopover from './AssignNewDifferentialPopover';

interface ComplaintsDetailDrawerProps extends SideDrawerProps {
    complaintId: string;
    differentials: Differential[];
    onSave: () => void;
}

const ComplaintsDetailDrawer: React.FC<ComplaintsDetailDrawerProps> = ({
    title,
    isOpen,
    setIsOpen,
    complaintId,
    differentials,
    onSave,
}) => {
    const [orderedItems, setOrderedItems] = useState<Differential[]>([]);
    const [showAssignDiffPopover, setShowAssignDiffPopover] = useState(false);

    let formattedTitle = useMemo(() => {
        return typeof title !== 'undefined' ? format.capitalizeStr(title) : '';
    }, [title]);

    useEffect(() => {
        setOrderedItems(differentials);
    }, [complaintId]);

    const renderListItem = (item: Differential, index: number) => {
        return <div> {`${index + 1}. ${item.name}`}</div>;
    };

    const addDifferential = (diff: Differential) => {
        setOrderedItems([...orderedItems, diff]);
        setShowAssignDiffPopover(false);
    };

    return (
        <SideDrawer title={formattedTitle} isOpen={isOpen} setIsOpen={setIsOpen}>
            <Space direction='vertical' full>
                <Popover
                    overlayInnerStyle={{ width: 360 }}
                    content={<AssignNewDifferentialPopover onAdd={addDifferential} />}
                    title={
                        <SpacedContent>
                            Assign New Differential <a onClick={() => setShowAssignDiffPopover(false)}>Close</a>
                        </SpacedContent>
                    }
                    trigger='click'
                    open={showAssignDiffPopover}
                    onOpenChange={setShowAssignDiffPopover}
                    placement='left'
                >
                    <Button block>Assign Differential</Button>
                </Popover>
                <SortableList items={orderedItems} setItems={setOrderedItems} renderItem={renderListItem} />
                <Button type='primary' block onClick={onSave}>
                    Save
                </Button>
            </Space>
        </SideDrawer>
    );
};

export default ComplaintsDetailDrawer;
