interface RequiredLabelStarProps {
    label: string;
}

const RequiredLabelStar: React.FC<RequiredLabelStarProps> = (props) => (
    <span>
        <span
            style={{
                display: 'inline-block',
                marginRight: '4px',
                color: '#ff4d4f',
                fontSize: '14px',
                fontFamily: 'SimSun, sans-serif',
                lineHeight: 1,
                content: '*',
            }}
        >
            *
        </span>
        {props.label}
    </span>
);
export default RequiredLabelStar;
