import { LockOutlined } from '@ant-design/icons';
import { Input, InputProps, Tooltip } from 'antd';
import React from 'react';

interface InputWithLockProps extends InputProps {
    locked?: boolean;
}

const InputWithLock: React.FC<InputWithLockProps> = ({ locked, ...props }) => {
    const inputElement = <Input disabled={locked} {...props} suffix={locked ? <LockOutlined /> : props.suffix} />;

    return locked ? (
        <Tooltip title={'Locked'}>
            <div>{inputElement}</div>
        </Tooltip>
    ) : (
        <>{inputElement}</>
    );
};

export default InputWithLock;
