import { PriceOverride } from 'utils/types';
import emptySplitApi, { PRICE_OVERRIDE_TAG_TYPE, SUPPLEMENTAL_TAG_TYPE } from './emptySplitApi';

export interface Supplemental {
    id: number;
    name: string;
    recurring: boolean;
    base_price_cents: number;
    is_pricing_relative: boolean;
    is_additive: boolean;
    avalara_tax_code: string | null;
    serial?: boolean;
    serial_hours?: number;
    base_serial_price_cents: number;
}

export interface CreateSupplemental extends Omit<Supplemental, 'id'> {
    avalara_tax_code: string | null;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}
export interface UpdateSupplemental extends Supplemental {
    avalara_tax_code: string | null;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}

const supplementalService = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        listSupplementals: builder.query<Supplemental[], void>({
            query: () => '/supplemental',
            transformResponse: (response: { data: Supplemental[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: SUPPLEMENTAL_TAG_TYPE, id: 'LIST' }],
        }),
        getSupplementalPriceOverrides: builder.query<PriceOverride[], number>({
            query: (id) => `supplemental/${id}/price`,
            transformResponse: (response: { data: PriceOverride[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: PRICE_OVERRIDE_TAG_TYPE, id }],
        }),
        createSupplemental: builder.mutation<void, CreateSupplemental>({
            query: ({ base_price_cents, ...body }) => ({
                url: 'supplemental',
                method: 'POST',
                body: {
                    ...body,
                    base_price_cents: base_price_cents,
                },
            }),
            invalidatesTags: () => [{ type: SUPPLEMENTAL_TAG_TYPE, id: 'LIST' }],
        }),
        updateSupplemental: builder.mutation<void, UpdateSupplemental>({
            query: ({ id, ...restBody }) => ({
                url: `supplemental/${id}`,
                method: 'PUT',
                body: restBody,
            }),
            invalidatesTags: () => [{ type: SUPPLEMENTAL_TAG_TYPE, id: 'LIST' }],
        }),
        deleteSupplemental: builder.mutation<void, number>({
            query: (supplemental_id) => ({
                url: `supplemental/${supplemental_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => [{ type: SUPPLEMENTAL_TAG_TYPE, id: 'LIST' }],
        }),
    }),
});

export const {
    useListSupplementalsQuery,
    useGetSupplementalPriceOverridesQuery,
    useCreateSupplementalMutation,
    useUpdateSupplementalMutation,
    useDeleteSupplementalMutation,
} = supplementalService;
