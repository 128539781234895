import { Select, SelectProps } from 'antd';
import React from 'react';
import { useListMedicationRoutesQuery } from 'services/medicines.service';

const MedicationRouteSelect: React.FC<SelectProps> = (props) => {
    const { data, isLoading } = useListMedicationRoutesQuery();

    return (
        <Select {...props} loading={isLoading} allowClear showSearch>
            {data?.map(({ id }) => (
                <Select.Option key={id} value={id}>
                    {id}
                </Select.Option>
            ))}
        </Select>
    );
};

export default MedicationRouteSelect;
