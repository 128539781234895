import { Form, Modal } from 'antd';
import { HiddenInput } from 'components/lib/formFields/HiddenInput';
import { useSuccessMessage } from 'hooks';
import { useEffect } from 'react';
import { useEditPackageInstructionMutation } from 'services/packages.service';
import { minsToTimeUnits, roundTo, timeUnitsToMins } from 'utils/helpers/format';
import { BaseModalProps, PackageInstruction, PackageInstructionType } from 'utils/types';
import PackageInstructionForm from '../includes/PackageInstructionForm';

interface EditPackageInstructionModalProps extends BaseModalProps {
    instruction: PackageInstruction | null;
}

const EditPackageInstructionModal: React.FC<EditPackageInstructionModalProps> = ({ isOpen, setIsOpen, instruction }) => {
    const [form] = Form.useForm<any>();
    const [editPackageInstruction, { isSuccess, isError, isLoading }] = useEditPackageInstructionMutation();

    useEffect(() => {
        setInitialFormState();
    }, [instruction]);

    const setInitialFormState = () => {
        form.resetFields();

        if (!instruction) {
            return;
        }

        const { days: startDelayDays, hours: startDelayHours, mins: startDelayMins } = minsToTimeUnits(instruction.start_delay_mins);
        const { days: durationDays, hours: durationHours, mins: durationMins } = minsToTimeUnits(instruction.duration_mins);

        form.setFieldsValue({ ...instruction });
        form.setFieldsValue({
            toggled: instruction.toggled,
            start_delay_days: startDelayDays,
            start_delay_hours: startDelayHours,
            start_delay_mins: startDelayMins,
            duration_days: durationDays,
            duration_hours: durationHours,
            duration_mins: durationMins,
            id: instruction.id,
            type_id: instruction.type_id,
        });

        if (instruction.type_id === 'F') {
            form.setFieldValue('rate_ml_per_hr', instruction.rate_mcl_per_hr ? roundTo(instruction.rate_mcl_per_hr / 1000, 2) : null);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useSuccessMessage({
        success: {
            status: isSuccess,
            message: `Order details successfully updated.`,
            callback: () => {
                handleClose();
                form.resetFields();
            },
        },
        error: {
            status: isError,
            message: `It seems there has been an issue updating the order's details, please try again.`,
        },
    });

    const handleSave = (values: any) => {
        const { start_delay_days, start_delay_hours, start_delay_mins, duration_days, duration_hours, duration_mins, ...body } = values;

        const start_delay_mins_final = timeUnitsToMins(start_delay_days, start_delay_hours, start_delay_mins);
        const orderDuration = timeUnitsToMins(duration_days, duration_hours, duration_mins);

        if (values.type_id === 'F') {
            const { rate_ml_per_hr, ...fluidsBody } = body;
            const rate_mcl_per_hr = rate_ml_per_hr ? roundTo(rate_ml_per_hr, 2) * 1000 : null;

            editPackageInstruction({
                ...fluidsBody,
                start_delay_mins: start_delay_mins_final,
                duration_mins: orderDuration,
                rate_mcl_per_hr,
            });
            return;
        }

        editPackageInstruction({
            ...body,
            start_delay_mins: start_delay_mins_final,
            duration_mins: orderDuration,
        });
    };

    return (
        <Modal title='Edit Order' open={isOpen} onOk={form.submit} onCancel={handleClose} okButtonProps={{ loading: isLoading }}>
            <Form form={form} name='basic' initialValues={form.getFieldsValue()} onFinish={handleSave}>
                <HiddenInput fieldName='type_id' initialValue={form.getFieldValue('type_id')} />
                <HiddenInput fieldName='id' initialValue={form.getFieldValue('id')} />

                <PackageInstructionForm
                    form={form}
                    instructionType={instruction?.type_id ?? PackageInstructionType.PACKAGE}
                    instructionName={instruction?.name ?? ''}
                />
            </Form>
        </Modal>
    );
};

export default EditPackageInstructionModal;
