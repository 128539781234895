import { Form, message } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Medication,
    useCreateMedicationMutation,
    useGetMedicinePriceOverridesQuery,
    useUpdateMedicationMutation,
} from 'services/medicines.service';

import { CreateOrUpdateCatalogModal } from 'components/lib/CreateOrUpdateCatalogModal';
import { convertFromMicro, convertToMicro } from '../MedicationUnitSelect';
import { MedicineTab } from './MedicineTab';

interface CreateOrUpdateMedicationProps {}

interface CreateOrUpdateMedicationModalRef {
    openModal: () => void;
    setMedicationToUpdate: (medication: Medication) => void;
}

const CreateOrUpdateMedicationModal: React.ForwardRefRenderFunction<CreateOrUpdateMedicationModalRef, CreateOrUpdateMedicationProps> = (
    props,
    ref,
) => {
    const [isOpen, setIsOpen] = useState(false);
    const [medicationToUpdate, setMedicationToUpdate] = useState<Medication | null>(null);
    const [form] = Form.useForm();

    const {
        data: initialPriceOverrides,
        isFetching: initialPriceOverridesFetching,
        isError: initialPriceOverridesError,
    } = useGetMedicinePriceOverridesQuery(medicationToUpdate?.id as number, {
        skip: !medicationToUpdate,
        refetchOnMountOrArgChange: true,
    });

    useImperativeHandle(ref, () => ({
        openModal: () => {
            setIsOpen(true);
        },
        setMedicationToUpdate: (medication: Medication) => {
            setIsOpen(true);
            setMedicationToUpdate(medication);
        },
    }));

    const [createMedication, { isLoading: createMedicationLoading }] = useCreateMedicationMutation();
    const [updateMedication, { isLoading: updateMedicationLoading }] = useUpdateMedicationMutation();

    const handleCloseModal = () => {
        setIsOpen(false);
        setMedicationToUpdate(null);
        form.resetFields();
    };

    const isSimpleOrTGH = (calculator_type: string) => calculator_type === 'simple' || calculator_type === 'prepackaged';

    const getUnitConvertedFromMicro = (unit: string | null, dosing_unit: string) => {
        return unit === dosing_unit ? unit : convertFromMicro(unit) ?? unit;
    };

    const getDefaultDoseUnit = (
        dosing_unit: string,
        default_dose: number | null,
        calculator_type: string,
        numerator_unit: string | null,
    ) => {
        if (default_dose) {
            return isSimpleOrTGH(calculator_type) ? dosing_unit : `${convertToMicro(numerator_unit) ?? numerator_unit}/kg`;
        }
        return null;
    };

    const handleSubmit = (values: any) => {
        const checkSimpleOrTGH = isSimpleOrTGH(values.calculator_type);

        const formattedValues = {
            ...values,
            product_category_id: values.product_category_id ?? null,
            default_dose: values.default_dose ? values.default_dose * 1000 : null,
            default_dose_unit: getDefaultDoseUnit(values.dosing_unit, values.default_dose, values.calculator_type, values.numerator_unit),
            denominator_unit: checkSimpleOrTGH
                ? values.dosing_unit
                : getUnitConvertedFromMicro(values.denominator_unit, values.dosing_unit) ?? values.denominator_unit,
            numerator_value: checkSimpleOrTGH ? null : values.numerator_value,
            numerator_unit: checkSimpleOrTGH ? null : getUnitConvertedFromMicro(values.numerator_unit, values.dosing_unit),
            low_dose_alert: checkSimpleOrTGH ? null : values.low_dose_alert,
            high_dose_alert: checkSimpleOrTGH ? null : values.high_dose_alert,
            default_cri_unit: values.default_cri_unit ?? null,
            specific_locations: values.specific_locations ?? [],
            pricing_unit_size: values.pricing_unit_size ? Math.round(parseInt(values.pricing_unit_size) * 1000) : null,
        };

        if ('id' in formattedValues) {
            updateMedication(formattedValues)
                .unwrap()
                .then(() => {
                    message.success(`${values.name} successfully updated`);
                    handleCloseModal();
                })
                .catch((e) => {
                    message.error(`There was an error updating ${values.name}`);
                    console.error(e);
                });
        } else {
            createMedication(formattedValues)
                .unwrap()
                .then(() => {
                    message.success(`${values.name} successfully created`);
                    handleCloseModal();
                })
                .catch((e) => {
                    message.error(`There was an error in adding ${values.name}`);
                    console.error(e);
                });
        }
    };

    return (
        <CreateOrUpdateCatalogModal
            modalTitle={`${medicationToUpdate ? 'Edit' : 'New'} Medication`}
            isOpen={isOpen}
            catalogItemToUpdate={medicationToUpdate}
            initialPriceOverrides={initialPriceOverrides || []}
            catalogItemTab={
                <TabPane tab='Medicine' key='catalog_item' forceRender>
                    <MedicineTab medicationToUpdate={medicationToUpdate} form={form} />
                </TabPane>
            }
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            form={form}
            createCatalogItemLoading={createMedicationLoading}
            updateCatalogItemLoading={updateMedicationLoading}
            initialPriceOverridesFetching={initialPriceOverridesFetching}
            initialPriceOverridesError={initialPriceOverridesError}
            typeId='M'
        />
    );
};

export default forwardRef(CreateOrUpdateMedicationModal);
