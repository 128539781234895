import { message } from 'antd';
import { useEffect } from 'react';

interface SuccessHandler {
    status: boolean;
    message: string;
    callback?: () => void;
}

interface useSuccessMessageArgs {
    success: SuccessHandler;
    error: SuccessHandler;
}

const useSuccessMessage = ({
    success: { status: success = false, message: successMessage = '', callback: onSuccess = () => {} },
    error: { status: error = false, message: errorMessage = '', callback: onError = () => {} },
}: useSuccessMessageArgs) => {
    useEffect(() => {
        if (success) {
            message.success(successMessage);
            onSuccess();
        }

        if (error) {
            message.error(errorMessage);
            onError();
        }
    }, [success, error]);
};

export default useSuccessMessage;
