import { Space as AntSpace, SpaceProps as AntSpaceProps } from 'antd';
import spaceStyles from './space.module.css';
interface SpaceProps extends AntSpaceProps {
    full?: boolean;
}

const Space: React.FC<SpaceProps> = ({ full, ...props }) => {
    return <AntSpace {...props} className={`${props?.className} ${full ? spaceStyles.fullWidth : ''}`} />;
};

export default Space;
