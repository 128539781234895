import { Typography } from 'antd';

interface TitleProps {
    level: 1 | 2 | 3 | 4 | 5 | undefined;
}

const Title: React.FC<TitleProps> = ({ children, level }) => {
    return <Typography.Title level={level}>{children}</Typography.Title>;
};

export default Title;
