import { PriceOverride } from 'utils/types';
import emptySplitApi, { MEDICATION_TAG_TYPE, PRICE_OVERRIDE_TAG_TYPE } from './emptySplitApi';
import { LocationSpecificItem } from './locationSpecificItems.service';

interface MedicationForm {
    name: string;
}

interface MedicationRoute {
    id: string;
    name: string;
}

export interface Medication {
    id: number;
    name: string;
    generic_name?: string;
    brand_name?: string;
    form: string;
    dosing_unit: string;
    numerator_value: number | null;
    numerator_unit: string | null;
    denominator_value: number;
    denominator_unit: string;
    calculator_type: string;
    default_dose: number | null;
    default_dose_unit: string | null;
    default_duration_mins?: number;
    default_frequency?: string;
    default_route?: string;
    sort_rank: number;
    product_category_id?: number | null;
    product_category_name?: string;
    base_price_cents: number;
    has_commission: boolean;
    controlled_drug: boolean;
    vetspire_id?: number;
    low_dose_alert?: number | null;
    high_dose_alert?: number | null;
    default_cri_unit?: string | null;
    is_pricing_relative: boolean;
    avalara_tax_code: string | null;
    pricing_unit: string | null;
    pricing_unit_size: number | null;
    serial?: boolean;
    serial_hours?: number;
    base_serial_price_cents: number;
}

export const isInstanceOfMedication = (item: any): item is Medication => (item as Medication)?.calculator_type !== undefined;

export interface CreateMedication extends Omit<Medication, 'id'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}
export interface UpdateMedication extends Omit<Medication, 'product_category_name' | 'supplemental_name'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}

export const medicineApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        listMedicationForms: builder.query<MedicationForm[], void>({
            query: () => `medication_form`,
            transformResponse: (response: { data: MedicationForm[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: MEDICATION_TAG_TYPE, id: 'LIST_FORMS' }],
        }),
        listMedicationRoutes: builder.query<MedicationRoute[], void>({
            query: () => `medication_route`,
            transformResponse: (response: { data: MedicationRoute[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: MEDICATION_TAG_TYPE, id: 'LIST_ROUTES' }],
        }),
        getMedicines: builder.query<Medication[], void>({
            query: () => `medication`,
            transformResponse: (response: { data: Medication[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: MEDICATION_TAG_TYPE, id: 'LIST' }],
        }),
        getMedicinePriceOverrides: builder.query<PriceOverride[], number>({
            query: (id) => `medication/${id}/price`,
            transformResponse: (response: { data: PriceOverride[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: PRICE_OVERRIDE_TAG_TYPE, id }],
        }),
        createMedication: builder.mutation<void, CreateMedication>({
            query({ base_price_cents, ...body }) {
                return {
                    url: `medication`,
                    method: 'POST',
                    body: {
                        ...body,
                        base_price_cents: base_price_cents,
                    },
                };
            },
            invalidatesTags: (result, error) => [{ type: MEDICATION_TAG_TYPE, id: 'LIST' }],
        }),
        updateMedication: builder.mutation<void, UpdateMedication>({
            query({ id, ...body }) {
                return {
                    url: `medication/${id}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (result, error) => {
                return [{ type: MEDICATION_TAG_TYPE, id: 'LIST' }];
            },
        }),
        deleteMedication: builder.mutation<void, number>({
            query: (medication_id) => ({
                url: `medication/${medication_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error) => [{ type: MEDICATION_TAG_TYPE, id: 'LIST' }],
        }),
    }),
});

export const {
    useListMedicationFormsQuery,
    useListMedicationRoutesQuery,
    useGetMedicinesQuery,
    useGetMedicinePriceOverridesQuery,
    useDeleteMedicationMutation,
    useCreateMedicationMutation,
    useUpdateMedicationMutation,
} = medicineApi;
