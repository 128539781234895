import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import React from 'react';

interface HiddenInputProps {
    fieldName: NamePath;
    initialValue?: any;
    rules?: Rule[];
}

export const HiddenInput = React.memo(({ fieldName, initialValue, rules }: HiddenInputProps) => {
    return (
        <>
            <Form.Item preserve={false} hidden noStyle name={fieldName} rules={rules ?? []} initialValue={initialValue}>
                <Input type='hidden' />
            </Form.Item>
        </>
    );
});
