import { DownOutlined, LockOutlined } from '@ant-design/icons';
import { Select, SelectProps, Tooltip } from 'antd';
import React from 'react';

interface SelectWithLockProps extends SelectProps<any> {
    locked?: boolean;
}

const SelectWithLock: React.FC<SelectWithLockProps> = ({ locked, ...props }) => {
    const selectIcon = locked ? <LockOutlined /> : <DownOutlined />;
    const selectWithLockIcon = <Select {...props} suffixIcon={selectIcon} disabled={locked} />;

    return locked ? (
        <Tooltip title='Locked' placement='topRight'>
            <div>{selectWithLockIcon}</div>
        </Tooltip>
    ) : (
        selectWithLockIcon
    );
};

export default SelectWithLock;
