import { PriceOverride } from 'utils/types';
import emptySplitApi, { NON_MEDICAL_TAG_TYPE, PRICE_OVERRIDE_TAG_TYPE } from './emptySplitApi';
import { LocationSpecificItem } from './locationSpecificItems.service';

export interface NonMedical {
    id: number;
    name: string;
    base_price_cents: number;
    order_from_estimate: boolean;
    product_category_id?: number | null;
    product_category_name?: number;
    is_pricing_relative: boolean;
    unit: string;
    has_reason: boolean;
    has_commission: boolean;
    avalara_tax_code: string | null;
    serial?: boolean;
    serial_hours?: number;
    base_serial_price_cents: number;
}

export interface CreateNonMedical extends Omit<NonMedical, 'product_category_name' | 'id'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}
export interface UpdateNonMedical extends Omit<NonMedical, 'product_category_name'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}

const nonMedicalService = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        listNonMedicals: builder.query<NonMedical[], void>({
            query: () => 'non_medical',
            transformResponse: (response: { data: NonMedical[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: NON_MEDICAL_TAG_TYPE, id: 'LIST' }],
        }),
        getNonMedicalPriceOverrides: builder.query<PriceOverride[], number>({
            query: (id) => `non_medical/${id}/price`,
            transformResponse: (response: { data: PriceOverride[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: PRICE_OVERRIDE_TAG_TYPE, id }],
        }),
        createNonMedical: builder.mutation<void, CreateNonMedical>({
            query: ({ base_price_cents, ...body }) => ({
                url: 'non_medical',
                method: 'POST',
                body: {
                    ...body,
                    base_price_cents: base_price_cents,
                },
            }),
            invalidatesTags: () => [{ type: NON_MEDICAL_TAG_TYPE, id: 'LIST' }],
        }),
        updateNonMedical: builder.mutation<void, UpdateNonMedical>({
            query: ({ id, ...restBody }) => ({
                url: `non_medical/${id}`,
                method: 'PUT',
                body: restBody,
            }),
            invalidatesTags: () => [{ type: NON_MEDICAL_TAG_TYPE, id: 'LIST' }],
        }),
        deleteNonMedical: builder.mutation<void, number>({
            query: (nonMedicalId) => ({
                url: `non_medical/${nonMedicalId}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => [{ type: NON_MEDICAL_TAG_TYPE, id: 'LIST' }],
        }),
    }),
});

export const {
    useListNonMedicalsQuery,
    useGetNonMedicalPriceOverridesQuery,
    useCreateNonMedicalMutation,
    useUpdateNonMedicalMutation,
    useDeleteNonMedicalMutation,
} = nonMedicalService;
