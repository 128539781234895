import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Typography } from 'antd';
import { Button, Space, SpacedContent, Table } from 'components/lib';
import { useMemo, useState } from 'react';
import { useGetDifferentialsQuery } from 'services/differentials.service';
import { Differential, QueryResponse } from 'utils/types';
import NewDifferentialModal from './NewDifferentialModal';

const DifferentialsListing: React.FC = () => {
    const [newDifferentialModalOpen, setNewDifferentialModalOpen] = useState(false);
    const [search, setSearch] = useState('');

    const { data: differentials } = useGetDifferentialsQuery<QueryResponse<Differential[]>>();

    const filteredData = differentials?.filter((differential) => {
        if (search === '') return differential;

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return differential.name.match(regex);
    });

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                defaultSortOrder: 'ascend',
                width: 200,
            },
        ];
    }, []);

    if (typeof differentials === 'undefined') {
        return null;
    }

    return (
        <>
            <Space direction='vertical' full>
                <Typography.Text>{differentials.length} items</Typography.Text>

                <SpacedContent>
                    <Space>
                        <Input
                            placeholder='Search Differentials'
                            suffix={<SearchOutlined />}
                            value={search}
                            style={{ width: 280 }}
                            onChange={({ target }) => {
                                setSearch(target.value);
                            }}
                        />
                    </Space>

                    <Button onClick={() => setNewDifferentialModalOpen(true)}>
                        Add Differential <PlusOutlined />
                    </Button>
                </SpacedContent>

                <Table columns={columns} data={filteredData ?? []} onChange={() => {}}></Table>
            </Space>
            <NewDifferentialModal isOpen={newDifferentialModalOpen} setIsOpen={setNewDifferentialModalOpen} />
        </>
    );
};

export default DifferentialsListing;
