import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Modal, Row, Typography } from 'antd';
import { FC } from 'react';
import { UploadRdvmDraftResponseData } from 'utils/types';

const { Text, Paragraph, Title } = Typography;

interface UploadSummaryModalProps {
    isVisible: boolean;
    onClose: () => void;
    data?: UploadRdvmDraftResponseData;
}

const UploadSummaryModal: FC<UploadSummaryModalProps> = ({ isVisible, onClose, data }) => {
    const successfullyUploadedItems = data?.success ?? [];
    const failedUploadedItems = data?.failure ?? [];

    return (
        <Modal
            destroyOnClose
            title='Upload Summary'
            open={isVisible}
            onCancel={onClose}
            style={{ maxHeight: '80vh', overflowY: 'auto' }}
            footer={[
                <Button key='ok' type='primary' onClick={onClose}>
                    OK
                </Button>,
            ]}
        >
            {successfullyUploadedItems.length > 0 && (
                <Text style={{ fontSize: '18px', fontWeight: 500 }}>
                    <CheckCircleOutlined style={{ color: '#73C64C', marginRight: 10 }} />
                    {successfullyUploadedItems.length} Successfully Uploaded
                </Text>
            )}

            {successfullyUploadedItems.length > 0 && failedUploadedItems.length > 0 && <Divider />}

            {failedUploadedItems.length > 0 && (
                <>
                    <Row align='middle'>
                        <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}>
                            <ExclamationCircleOutlined style={{ color: '#E7202E', fontSize: 16 }} />
                        </Col>
                        <Col span={20} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text style={{ fontSize: '18px', fontWeight: 500 }}>{failedUploadedItems.length} Errors</Text>
                        </Col>
                    </Row>

                    <Row align='top'>
                        <Col span={1} style={{ marginRight: 8 }} />
                        <Col span={20}>
                            <Paragraph type='secondary'>
                                Please check that all fields are properly formatted, all required information is provided, and a record does
                                not already exist.{' '}
                            </Paragraph>
                        </Col>
                    </Row>

                    <Col style={{ backgroundColor: '#FAFAFA', padding: 24 }}>
                        <Title level={5}>The following clinics were unable to be uploaded:</Title>
                        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                            {failedUploadedItems.map((item, index) => (
                                <Paragraph key={index} style={{ marginBottom: 4 }}>
                                    <CloseCircleOutlined style={{ marginRight: 8 }} />
                                    {item.clinic}
                                </Paragraph>
                            ))}
                        </div>
                    </Col>
                </>
            )}
        </Modal>
    );
};

export default UploadSummaryModal;
