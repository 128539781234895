import { Form, Input, Modal, Select } from 'antd';
import { BaseModalProps, EmployeeRoles, VegUser } from 'utils/types';

interface AddNewEmployeeModalProps extends BaseModalProps {
    edit: (user: VegUser) => void;
}

const initialValues: Omit<VegUser, 'id' | 'role_id' | 'okta_subject' | 'payroll_id' | 'hospital_bypass' | 'is_active'> = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
};

const AddNewEmployeeModal: React.FC<AddNewEmployeeModalProps> = ({ isOpen, setIsOpen, edit }) => {
    const [form] = Form.useForm();

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSave = () => {
        form.submit();
        handleClose();
    };
    const handleEdit = (args: VegUser) => {
        edit(args);
        form.resetFields();
    };

    const handleFail = () => {};

    return (
        <Modal title='New Employee' open={isOpen} onOk={handleSave} onCancel={handleClose}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={initialValues}
                onFinish={handleEdit}
                onFinishFailed={handleFail}
            >
                <Form.Item label='Name'>
                    <Input.Group compact>
                        <Form.Item
                            name='first_name'
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: "Please ensure the new employee's first_name has been completed.",
                                },
                            ]}
                        >
                            <Input style={{ width: '50%' }} placeholder='firstname' />
                        </Form.Item>
                        <Form.Item
                            name='last_name'
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: "Please ensure the new employee's surname has been completed.",
                                },
                            ]}
                        >
                            <Input style={{ width: '50%' }} placeholder='surname' />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>

                <Form.Item label='Email' name='email'>
                    <Input />
                </Form.Item>
                <Form.Item label='Phone' name='phone'>
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Role'
                    name='role'
                    rules={[
                        {
                            required: true,
                            message: "Please ensure the employee's role has been added.",
                        },
                    ]}
                >
                    <Select>
                        {Object.values(EmployeeRoles).map((role: string) => {
                            return (
                                <Select.Option key={role} value={role}>
                                    {role}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddNewEmployeeModal;
