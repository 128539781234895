import { DatePicker, Form, Modal, Select } from 'antd';
import { TextEditor } from 'components/lib';
import { convertToRaw, EditorState } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import moment from 'moment';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateMeetingNoteMutation } from 'services/rdvm.service';
import { MEETING_TYPES } from 'utils/consts';
import { BaseModalProps, MeetingNotesCreation } from 'utils/types';

interface NewClinicNoteModalProps extends BaseModalProps {}

const NewClinicNoteModal: React.FC<NewClinicNoteModalProps> = ({ isOpen, setIsOpen }) => {
    const [note, setNote] = useState(EditorState.createEmpty());
    const [createMeetingNote] = useCreateMeetingNoteMutation();
    const [form] = Form.useForm();
    const { id: clinic_id } = useParams<{ id: string }>();

    const onFinish = (values: any) => {
        let markDown = draftToMarkdown(convertToRaw(note.getCurrentContent()));

        const newNote: MeetingNotesCreation = {
            ...values,
            clinic_id,
            note: markDown,
            mtg_date: +moment(values.mtg_date).format('X'),
        };
        createMeetingNote(newNote);
        handleClose();
    };

    const handleClose = () => {
        setIsOpen(false);
        setNote(EditorState.createEmpty());
        form.resetFields();
    };

    const handleChange = (editorState: EditorState) => {
        setNote(editorState);
    };

    return (
        <Modal title='Add Note' open={isOpen} onOk={form.submit} onCancel={handleClose}>
            <Form form={form} labelCol={{ span: 4 }} onFinish={onFinish}>
                <Form.Item
                    label='Date'
                    name='mtg_date'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a date.',
                        },
                    ]}
                >
                    <DatePicker placeholder='Select Date' format={'YYYY-MM-DD'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label='Type'
                    name='mtg_type'
                    rules={[
                        {
                            required: true,
                            message: 'Please ensure a meeting type has been selected.',
                        },
                    ]}
                >
                    <Select placeholder='Select Type' allowClear>
                        {MEETING_TYPES.map((type) => (
                            <Select.Option key={type} value={type}>
                                {type}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label='Note'
                    name='note'
                    rules={[
                        {
                            required: true,
                            message: 'Please ensure a meeting note has been entered.',
                        },
                    ]}
                >
                    <TextEditor content={note} onChange={handleChange}></TextEditor>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default NewClinicNoteModal;
