import { Form, Input, message, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { useCreatePackageMutation } from 'services/packages.service';
import { BaseModalProps, ROUTES } from 'utils/types';
interface AddPackageModalProps extends BaseModalProps {}

const AddPackageModal: React.FC<AddPackageModalProps> = ({ isOpen, setIsOpen }) => {
    const history = useHistory();
    const [form] = Form.useForm();

    let [createPackage] = useCreatePackageMutation();

    const handleClose = () => {
        setIsOpen(false);
        form.resetFields();
    };

    const handleSave = ({ name }: { name: string }) => {
        createPackage({ name }).then((res) => {
            if ('data' in res) {
                history.push(ROUTES.addID(ROUTES.PACKAGE_DETAIL, res.data?.id));
            } else if ('error' in res) {
                message.error(`It seems there has been an issue adding the new '${form.getFieldValue('name')}' package, please try again.`);
            }
        });

        handleClose();
    };

    const handleFail = () => {};

    return (
        <Modal title='Add Package' open={isOpen} onOk={form.submit} onCancel={handleClose}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ name: '' }}
                onFinish={handleSave}
                onFinishFailed={handleFail}
            >
                <Form.Item
                    label='Name'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: "Please ensure the package's name has been entered.",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddPackageModal;
