import { Form } from 'antd';
import FrequencyField from 'components/lib/formFields/FrequencyField';
import { NEW_PACKAGE_INSTRUCTION_FIELDS } from '../utils';

interface PackageDiagnosticFormProps {
    instructionName: string;
}

const PackageDiagnosticForm: React.FC<PackageDiagnosticFormProps> = ({ instructionName }) => {
    return (
        <>
            <Form.Item label='Order'>{instructionName}</Form.Item>
            <FrequencyField label='Frequency' name={NEW_PACKAGE_INSTRUCTION_FIELDS.FREQUENCY} />
        </>
    );
};

export default PackageDiagnosticForm;
