import { Form, InputNumber } from 'antd';

interface PackageNonMedicalFormProps {
    instructionName: string;
}

const PackageNonMedicalForm: React.FC<PackageNonMedicalFormProps> = ({ instructionName }) => {
    return (
        <>
            <Form.Item label='Order'>{instructionName}</Form.Item>
            <Form.Item<number> name='quantity' label='Quantity' initialValue={null}>
                <InputNumber<number> style={{ width: '100%' }} type='number' min={0} />
            </Form.Item>
        </>
    );
};

export default PackageNonMedicalForm;
