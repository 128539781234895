import { Table as AntTable, TablePaginationConfig } from 'antd';
import { FixAny, TableColumns } from 'utils/types';
// TODO sort out extension of props
interface TableProps<T> {
    columns: TableColumns[];
    data: T[];
    onChange?: (a: TablePaginationConfig, b: FixAny, c: FixAny) => void;
    paginationPosition?: 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft' | false;
    expandable?: any;
    rowKey?: string;
}

const Table = <T extends object>({
    columns,
    data,
    onChange,
    paginationPosition = 'topRight',
    children,
    expandable,
    rowKey,
}: TableProps<T> & { children?: React.ReactNode }) => {
    return (
        <AntTable
            dataSource={data}
            columns={columns}
            pagination={paginationPosition ? { position: [paginationPosition] } : paginationPosition}
            onChange={onChange}
            expandable={expandable}
            rowKey={rowKey}
        >
            {children}
        </AntTable>
    );
};

export default Table;
