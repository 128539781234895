import { Form, Input, Modal } from 'antd';
import { TextEditor } from 'components/lib';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useSuccessMessage } from 'hooks';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { useEffect, useState } from 'react';
import { CreateMacroParams, useEditMacroMutation } from 'services/macros.service';
import { BaseModalProps, NoteMacro } from 'utils/types';

enum EDIT_MACRO_FIELDS {
    LABEL = 'label',
    CONTENT = 'content',
    KEY_WORD = 'key_word',
    DESCRIPTION = 'description',
}

const initalMacroState: CreateMacroParams = {
    [EDIT_MACRO_FIELDS.LABEL]: '',
    [EDIT_MACRO_FIELDS.CONTENT]: '',
    [EDIT_MACRO_FIELDS.KEY_WORD]: '',
    [EDIT_MACRO_FIELDS.DESCRIPTION]: '',
};

interface EditMacroModalProps extends BaseModalProps {
    macro: NoteMacro | null;
}

const EditMacroModal: React.FC<EditMacroModalProps> = ({ macro, isOpen, setIsOpen }) => {
    const [form] = Form.useForm<CreateMacroParams>();

    const [note, setNote] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (macro !== null) {
            form.setFieldsValue(macro);
            let content = EditorState.createWithContent(convertFromRaw(markdownToDraft(macro.content)));
            setNote(content);
        }
    }, [macro]);

    const [editMacro, { isSuccess, isError, isLoading }] = useEditMacroMutation();

    const handleClose = () => {
        setIsOpen(false);
    };

    useSuccessMessage({
        success: {
            status: isSuccess,
            message: `New '${form.getFieldValue(EDIT_MACRO_FIELDS.LABEL)}' macro has been successfully updated.`,
            callback: () => {
                handleClose();
                form.resetFields();
            },
        },
        error: {
            status: isError,
            message: `It seems there has been an issue updating the macro '${form.getFieldValue(
                EDIT_MACRO_FIELDS.LABEL,
            )}', please try again.`,
        },
    });

    const handleSave = (values: CreateMacroParams) => {
        let content = draftToMarkdown(convertToRaw(note.getCurrentContent()));
        editMacro({ ...values, content, id: (macro as NoteMacro)?.id });
    };

    const handleFail = () => {};
    return (
        <Modal title='Edit Macro' open={isOpen} onOk={form.submit} onCancel={handleClose}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={initalMacroState}
                onFinish={handleSave}
                onFinishFailed={handleFail}
            >
                <Form.Item
                    label='Label'
                    name={EDIT_MACRO_FIELDS.LABEL}
                    rules={[
                        {
                            required: true,
                            message: 'Please ensure the macro label has been entered.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Keyword'
                    name={EDIT_MACRO_FIELDS.KEY_WORD}
                    rules={[
                        {
                            required: true,
                            message: 'Please ensure the macro keyword has been entered.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Description'
                    name={EDIT_MACRO_FIELDS.DESCRIPTION}
                    rules={[
                        {
                            required: true,
                            message: 'Please ensure the macro description has been entered.',
                        },
                    ]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item label='Content'>
                    <TextEditor onChange={setNote} content={note} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditMacroModal;
