import { Differential } from 'utils/types';
import emptySplitApi, { DIFFERENTIAL_TAG_TYPE } from './emptySplitApi';

export type NewDifferentialParams = Omit<Differential, 'id'>;

export const differentialsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDifferentials: builder.query<Differential[], void>({
            query: (searchQuery) => `differential`,
            transformResponse: (response: { data: Differential[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: DIFFERENTIAL_TAG_TYPE, id: 'LIST' }],
        }),
        createDifferential: builder.mutation<Differential, NewDifferentialParams>({
            query(body) {
                return {
                    url: `differential`,
                    method: 'POST',
                    body,
                };
            },
            transformResponse: (response: { data: Differential }) => {
                return response.data;
            },
            invalidatesTags: (result, error, id) => [{ type: DIFFERENTIAL_TAG_TYPE, id: 'LIST' }],
        }),
        getDifferential: builder.query<Differential, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                return { data: {} as Differential };
            },
        }),
        deleteDifferential: builder.query<{ id: number }, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                return { data: { id: -1 } };
            },
        }),
        editDifferential: builder.query<Differential, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                return { data: {} as Differential };
            },
        }),
    }),
});

export const { useGetDifferentialsQuery, useCreateDifferentialMutation } = differentialsApi;
