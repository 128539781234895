import { DeleteOutlined, DownOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Menu, message, Modal, Row, Tabs, Tooltip, Typography } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { rdvmEmailAndPhoneWarningConfig } from 'components/lib/ConfirmModalConfig';
import { useEffect, useState } from 'react';
import { useGetUserDataQuery } from 'services/auth.service';
import { useGetHospitalsQuery } from 'services/hospitals.service';
import {
    useCheckRdvmEmailAndPhoneWarningMutation,
    useDeactivateRdvmClinicMutation,
    useUpdateRdvmMutation,
    useValidateRdvmMutation,
} from 'services/rdvm.service';
import { useVisitCountForRdvmQuery } from 'services/reports.service';
import { BASE_QUERY_OPTIONS } from 'utils/consts';
import { BaseModalProps, Hospital, QueryResponse, RdvmClinic, RdvmClinicCreation } from 'utils/types';
import { checkIfUserHasNecessaryPermission, USER_PERMISSIONS } from 'utils/userPermissions';
import AdvancedTabPane from '../ClinicsListing/NewClinicModal/AdvancedTabPane';
import ClinicTabPane from '../ClinicsListing/NewClinicModal/ClinicTabPane';
import '../ClinicsListing/NewClinicModal/index.css';
import RelationshipTabPane from '../ClinicsListing/NewClinicModal/RelationshipTabPane';
import ConfirmRemoveModal from './ConfirmRemoveModal';

interface EditClinicModalProps extends BaseModalProps {
    initialContactState?: RdvmClinic;
    clinicId: string;
}

interface RdvmClinicFormWithActive extends RdvmClinicCreation {
    is_active: boolean;
    is_validated: boolean;
}

const EditClinicModal: React.FC<EditClinicModalProps> = ({ isOpen, setIsOpen, initialContactState, clinicId }) => {
    const [is_activeChanged, setIsActiveChanged] = useState(false);
    const [activeTab, setActiveTab] = useState('clinic');
    const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] = useState(false);
    const [isValidated, setIsValidated] = useState(initialContactState?.is_validated);
    const [shouldValidate, setShouldValidate] = useState(false);
    const [form] = Form.useForm();
    const [checkRdvmEmailAndPhoneWarning] = useCheckRdvmEmailAndPhoneWarningMutation();
    const [updateRdvm] = useUpdateRdvmMutation();
    const [validateRdvm] = useValidateRdvmMutation();
    const [deactivateRdvmClinic] = useDeactivateRdvmClinicMutation();
    const { data: visitCount } = useVisitCountForRdvmQuery({
        rdvm_id: initialContactState?.clinic_id,
    });

    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);
    const { data: hospitals } = useGetHospitalsQuery<QueryResponse<Hospital[]>>({});

    const userHasPrimaryLocationEditPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.primary_location_edit,
    );

    const userHasDeactivatePermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_clinics_deactivate,
    );

    const userHasMergePermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_clinics_remove,
    );

    const userHasValidatePermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_clinics_validate,
    );

    const userHasEditPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_clinics_edit,
    );

    const tooltipMessage = !userHasEditPermission
        ? 'You do not have permission to edit clinic details.'
        : 'You do not have permission to edit a locked clinic.';

    const handleFormSubmit = () => {
        const errors = form.getFieldsError(['display_name', 'email', 'state', 'status']);
        const hasErrors = errors.some((field) => field.errors.length > 0);

        if (hasErrors) {
            setActiveTab('clinic');
            message.error('Please complete all required fields.');
        } else {
            form.submit();
        }
    };

    const handleClose = () => {
        form.resetFields();
        setIsOpen(false);
        form.resetFields();
    };

    let initialDiscountAmount = null;
    if (initialContactState?.discount_fixed) {
        initialDiscountAmount = initialContactState.discount_fixed;
    } else if (initialContactState?.discount_percentage) {
        initialDiscountAmount = initialContactState.discount_percentage;
    } else if (initialContactState?.discount_other) {
        initialDiscountAmount = initialContactState.discount_other;
    }

    const initialState = {
        ...initialContactState,
        discount_amount: initialDiscountAmount,
        has_call_forwarding: initialContactState?.has_call_forwarding ? 'true' : 'false',
    };

    const onFinish = (values: RdvmClinicFormWithActive) => {
        const { is_active, discount_amount, ...updateValues } = values;
        const onOk = () => {
            values.fax_number = values.fax_number ? values.fax_number : null;
            let has_call_forwarding = values.has_call_forwarding === 'true' ? true : values.has_call_forwarding === 'false' ? false : false;

            let discount_fixed = null;
            let discount_percentage = null;
            let discount_other = null;

            if (discount_amount) {
                if (values.discount_type === 'Percentage') {
                    discount_percentage = +discount_amount;
                    discount_other = null;
                    discount_fixed = null;
                } else if (values.discount_type === 'Other') {
                    discount_other = discount_amount;
                    discount_fixed = null;
                    discount_percentage = null;
                } else if (values.discount_type === 'Fixed amount') {
                    discount_fixed = +discount_amount;
                    discount_other = null;
                    discount_percentage = null;
                }
            }

            updateRdvm({
                id: clinicId,
                body: {
                    ...updateValues,
                    has_call_forwarding,
                    discount_fixed,
                    discount_percentage,
                    discount_other,
                    phone_number: values.phone_number?.length === 0 ? null : values.phone_number,
                    fax_number: values.fax_number?.length === 0 ? null : values.fax_number,
                    zipcode: values.zipcode?.length === 0 ? null : values.zipcode,
                },
            })
                .unwrap()
                .then(() => {
                    message.success('Clinic updated successfully');
                    setIsOpen(false);
                })
                .catch((error) => {
                    message.error(`Error updating clinic: ${error.message || 'Unknown error'}`);
                });
        };

        const deactivateModalContent = (
            <div>
                <Typography.Paragraph>
                    {initialState.display_name}
                    <Typography.Text strong> will no longer be available </Typography.Text>
                    to select as a primary rDVM for patients.
                </Typography.Paragraph>
                <Typography.Paragraph>All existing visits associated with this clinic will remain unchanged.</Typography.Paragraph>
            </div>
        );

        // validate only if clicked on Save & Lock
        const processValidation = async () => {
            if (shouldValidate) {
                handleValidateRdvm(!isValidated);
            }
        };

        // deactivation plus normal update
        const processDeactivation = () => {
            deactivateRdvmClinic({ clinic_id: clinicId, is_active: values.is_active })
                .unwrap()
                .then(() => {
                    message.success(`Clinic ${values.is_active ? 'activated' : 'deactivated'} successfully`);
                    processUpdate();
                })
                .catch((error) => {
                    message.error(`Error deactivating clinic: ${error.message || 'Unknown error'}`);
                });
        };

        // normal update with no deactivation
        const processUpdate = () => {
            checkRdvmEmailAndPhoneWarning({
                clinic_id: clinicId,
                body: {
                    ...values,
                    phone_number: values.phone_number?.length === 0 ? null : values.phone_number,
                    fax_number: values.fax_number?.length === 0 ? null : values.fax_number,
                },
            })
                .unwrap()
                .then((resp) => {
                    if (resp.email.emit_warning || resp.phone.emit_warning) {
                        Modal.confirm(rdvmEmailAndPhoneWarningConfig(resp.email.emit_warning, resp.phone.emit_warning, onOk));
                    } else {
                        onOk();
                        processValidation();
                    }
                })
                .catch((e) => {
                    message.error('Something went wrong');
                });
        };

        if (is_activeChanged) {
            if (!values.is_active) {
                Modal.confirm({
                    title: 'Deactivate Clinic',
                    content: deactivateModalContent,
                    onOk: processDeactivation,
                    okText: 'Save',
                });
            } else {
                processDeactivation();
            }
        } else {
            processUpdate();
        }
    };

    const onIsActiveChange = (changedValues: any) => {
        if ('is_active' in changedValues) {
            setIsActiveChanged(true);
        }
    };

    const handleValidateRdvm = async (isValidated: boolean) => {
        try {
            await validateRdvm({ clinic_id: clinicId, is_validated: isValidated }).unwrap();
            if (isValidated) {
                message.success(`${form.getFieldValue('display_name')} has been locked`);
            } else {
                message.warn(`${form.getFieldValue('display_name')} has been unlocked`);
            }
        } catch (error) {
            message.error(`Error updating clinic validation.`);
        }
    };

    const openConfirmRemoveModal = () => {
        setIsConfirmRemoveModalOpen(true);
        setIsOpen(false);
    };

    const closeConfirmRemoveModal = () => {
        setIsConfirmRemoveModalOpen(false);
        setIsOpen(true);
    };

    useEffect(() => {
        // keep track of is_active changes to avoid calling the deactivate function if already unchecked/no permissions
        setIsActiveChanged(initialContactState?.is_active !== initialState.is_active);
    }, [initialContactState, initialState.is_active]);

    useEffect(() => {
        if (initialContactState !== undefined) {
            setIsValidated(initialContactState.is_validated);
        }
    }, [initialContactState]);

    const saveMenu = (
        <Menu>
            <Menu.Item
                key='1'
                onClick={() => {
                    handleFormSubmit();
                    setShouldValidate(true);
                    form.submit();
                }}
            >
                Save and {!isValidated ? 'Lock' : 'Unlock'}
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        if (isOpen) {
            form.setFieldsValue(initialState);
        }
    }, [isOpen]);

    return (
        <>
            <Modal
                destroyOnClose
                title='Edit Clinic'
                open={isOpen}
                onOk={handleFormSubmit}
                onCancel={handleClose}
                okText='Save'
                footer={
                    <Row justify='space-between' style={{ width: '100%' }}>
                        <Col>
                            {!isValidated && (
                                <Button
                                    key='remove'
                                    disabled={!userHasMergePermission}
                                    onClick={openConfirmRemoveModal}
                                    danger
                                    icon={<DeleteOutlined />}
                                >
                                    Remove
                                </Button>
                            )}
                        </Col>
                        <Col>
                            <div style={{ display: 'inline-flex', gap: 8 }}>
                                <Button key='back' onClick={handleClose}>
                                    Cancel
                                </Button>
                                {userHasValidatePermission && isValidated && (
                                    <Button
                                        style={{ backgroundColor: '#FBAD18', border: 0, marginLeft: 0 }}
                                        key='validate'
                                        onClick={() => {
                                            setIsValidated(!isValidated);
                                            handleValidateRdvm(!isValidated);
                                        }}
                                    >
                                        Unlock
                                    </Button>
                                )}
                                {userHasEditPermission &&
                                    (userHasValidatePermission && !isValidated ? (
                                        <Dropdown.Button
                                            type='primary'
                                            icon={<DownOutlined />}
                                            overlay={saveMenu}
                                            onClick={() => {
                                                handleFormSubmit();
                                                setShouldValidate(false);
                                                form.submit();
                                            }}
                                        >
                                            Save
                                        </Dropdown.Button>
                                    ) : (
                                        <Button
                                            type='primary'
                                            onClick={() => {
                                                setShouldValidate(false);
                                                form.submit();
                                            }}
                                            style={{ marginLeft: 0 }}
                                        >
                                            Save
                                        </Button>
                                    ))}
                            </div>
                        </Col>
                    </Row>
                }
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {(!isValidated && !userHasEditPermission) || (isValidated && !userHasValidatePermission) ? (
                        <Tooltip title={tooltipMessage}>
                            <Typography.Text>
                                {isValidated ? (
                                    <LockOutlined style={{ color: '#73C64C' }} />
                                ) : (
                                    <UnlockOutlined style={{ color: '#FAAD14' }} />
                                )}
                                <span style={{ marginLeft: 8 }}>{isValidated ? 'Locked' : 'Unlocked'}</span>
                            </Typography.Text>
                        </Tooltip>
                    ) : (
                        <Typography.Text>
                            {isValidated ? <LockOutlined style={{ color: '#73C64C' }} /> : <UnlockOutlined style={{ color: '#FAAD14' }} />}
                            <span style={{ marginLeft: 8 }}>{isValidated ? 'Locked' : 'Unlocked'}</span>
                        </Typography.Text>
                    )}
                </div>

                <Form
                    form={form}
                    onFinish={onFinish}
                    layout='horizontal'
                    labelAlign='left'
                    labelCol={{ span: 7 }}
                    labelWrap
                    wrapperCol={{ span: 25 }}
                    colon={false}
                    className='clinic-modal'
                    initialValues={initialState}
                    onValuesChange={onIsActiveChange}
                >
                    <Tabs activeKey={activeTab} onChange={setActiveTab}>
                        <TabPane tab='Clinic' key='clinic' forceRender>
                            <ClinicTabPane
                                userIsSuperadmin={userHasDeactivatePermission}
                                isActiveDefault={initialState.is_active}
                                isEditing
                                form={form}
                                locked={isValidated || !userHasEditPermission}
                            />
                        </TabPane>
                        <TabPane tab='Advanced' key='advanced' forceRender>
                            <AdvancedTabPane locked={isValidated || !userHasEditPermission} />
                        </TabPane>
                        <TabPane tab='Relationship' key='relationship' forceRender>
                            <RelationshipTabPane
                                hospitals={hospitals}
                                userHasPrimaryLocationEditPermission={userHasPrimaryLocationEditPermission}
                                initialDiscountType={initialContactState?.discount_type}
                                form={form}
                                userHasEditPermission={userHasEditPermission}
                            />
                        </TabPane>
                    </Tabs>
                </Form>
            </Modal>
            <ConfirmRemoveModal
                isOpen={isConfirmRemoveModalOpen}
                onClose={closeConfirmRemoveModal}
                clinicName={initialState.display_name}
                visitCount={visitCount}
                rdvmId={initialContactState?.clinic_id}
            />
        </>
    );
};

export default EditClinicModal;
