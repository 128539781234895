import { Avatar as AntAvatar, AvatarProps as AntAvatarProps } from 'antd';
import { format } from 'utils/helpers';

interface AvatarProps extends AntAvatarProps {
    content: string;
    saturation?: number;
    lightness?: number;
}

const Avatar: React.FC<AvatarProps> = ({ content, size = 'large', saturation = 60, lightness = 70, ...rest }) => {
    return (
        <AntAvatar
            style={{
                backgroundColor: format.stringToHslColor(content, saturation, lightness),
                verticalAlign: 'middle',
                marginRight: '1rem',
            }}
            size={size}
            gap={1}
            {...rest}
        >
            {format.getInitials(content)}
        </AntAvatar>
    );
};

export default Avatar;
