import { useEffect } from 'react';

/**
 * @author Tood Skelton
 * @source https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
 **/

const useDebounce = (effect: () => void, deps: any[], delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);
    }, [...(deps || []), delay]);
};

export default useDebounce;
