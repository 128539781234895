import { Form, Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { getHospitalFromHospitalId } from 'utils/helpers/general';
import { Hospital } from 'utils/types';

interface HospitalColumnProps {
    formName: NamePath;
    hospitals?: Hospital[];
    notSetHospitals?: Hospital[];
    onChange: (hospital_id: number) => void;
    initialValue?: number;
}

const HospitalColumn: React.FC<HospitalColumnProps> = ({ formName, initialValue, hospitals, notSetHospitals, onChange }) => {
    if (!hospitals) return null;

    if (initialValue) {
        return <span>{getHospitalFromHospitalId(hospitals, initialValue)?.display_name}</span>;
    }

    return (
        <Form.Item
            rules={[{ required: true, message: 'Location is required.' }]}
            name={formName}
            style={{ margin: 0, padding: 0, minHeight: 0 }}
        >
            <Select<number>
                allowClear
                showSearch
                options={notSetHospitals?.map((hospital: Hospital) => {
                    return {
                        label: hospital.display_name,
                        value: hospital.id,
                    };
                })}
                filterOption={(inputValue, option) => (option?.label as string)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                style={{ width: '100%' }}
                onClick={(e) => e.stopPropagation()}
                onChange={onChange}
            />
        </Form.Item>
    );
};

export default HospitalColumn;
