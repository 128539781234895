import { AutoComplete as AntAutoComplete } from 'antd';

interface AutoCompleteProps {
    options: {
        key: string | number;
        value: string;
    }[];
    placeholder: string;
    style?: React.CSSProperties;
    defaultValue?: string;
    onChange?: (val: string, option: any) => void;
    allowClear: boolean;
}

export interface AutoCompleteOption {
    key?: React.Key | undefined;
    value?: number | string;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({ options, style, placeholder, defaultValue, onChange, allowClear = true }) => (
    <AntAutoComplete
        style={style}
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValue}
        allowClear={allowClear}
        filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        onChange={onChange}
    />
);

export default AutoComplete;
