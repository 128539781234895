import { Form, Input } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { REGULAR_EXPRESSIONS } from 'utils/types';

interface FrequencyFieldProps {
    name: string;
    label?: string;
    rules?: Rule[];
}

const FrequencyField: React.FC<FrequencyFieldProps> = ({ label, name, rules }) => {
    const localRules: Rule[] = rules || [
        {
            required: true,
            message: 'Please ensure the frequency has been entered.',
        },
        {
            pattern: RegExp(REGULAR_EXPRESSIONS.FREQUENCY),
            message: 'Please ensure the frequency number has the correct format.',
        },
    ];
    return (
        <Form.Item label={label || 'Frequency'} name={name} rules={localRules}>
            <Input placeholder='e.g. q1h, q1d, once' />
        </Form.Item>
    );
};

export default FrequencyField;
