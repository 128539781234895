import { EllipsisOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu } from 'antd';
import { Button, Space, SpacedContent, Table } from 'components/lib';
import { useDebounce } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyGetHospitalUsersQuery } from 'services/hospitals.service';
import { Hospital, QueryResponse, VegUser } from 'utils/types';
import AddNewEmployeeModal from './AddNewEmployeeModal';
import AssignEmployeeModal from './AssignEmployeeModal';
import UnassignEmployeeModal from './UnassignEmployeeModal';

interface HospitalEmployeeTableProps {
    hospital: Hospital | null;
}

const HospitalEmployeeTable: React.FC<HospitalEmployeeTableProps> = ({ hospital }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [assignEmployeeModalOpen, setAssignEmployeeModalOpen] = useState(false);
    const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
    const [unassignEmployeeModalOpen, setUnassignEmployeeModalOpen] = useState(false);
    const [userToUnassign, setUserToUnassign] = useState<VegUser | null>(null);

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                width: 200,
                defaultSortOrder: 'ascend',
                render: (name: undefined, user: VegUser) => {
                    return `${user.first_name} ${user.last_name}`;
                },
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                sorter: true,
                ellipsis: true,
                width: 100,
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                sorter: true,
                ellipsis: true,
                width: 190,
            },
            {
                title: 'Phone',
                key: 'phone_number',
                dataIndex: 'phone_number',
                width: 150,
            },
            {
                title: '',
                key: 'action',
                dataIndex: 'id',
                width: 80,
                render: (id: string, user: VegUser) => {
                    return (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        onClick={() => {
                                            setUserToUnassign(user);
                                            setUnassignEmployeeModalOpen(true);
                                        }}
                                    >
                                        <SpacedContent>Unassign from hospital</SpacedContent>
                                    </Menu.Item>
                                </Menu>
                            }
                            placement='bottomRight'
                            arrow
                        >
                            <Button>
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    );
                },
            },
        ];
    }, []);

    let [getHospitalUsers, { data: users }] = useLazyGetHospitalUsersQuery<QueryResponse<VegUser[]>>();

    useEffect(() => {
        if (hospital !== null) {
            getHospitalUsers({
                hospitalId: hospital.id,
                query: searchQuery,
            });
        }
    }, [hospital]);

    useDebounce(
        () => {
            if (hospital !== null) {
                getHospitalUsers({
                    hospitalId: hospital.id,
                    query: searchQuery,
                });
            }
        },
        [searchQuery],
        400,
    );

    const handleAssignUser = useCallback((user) => {}, []);

    return (
        <>
            <SpacedContent>
                <Input
                    style={{ width: 280 }}
                    placeholder='Search employees'
                    suffix={<SearchOutlined />}
                    value={searchQuery}
                    onChange={({ target }) => setSearchQuery(target.value)}
                />
                <Space>
                    <Button onClick={() => setAssignEmployeeModalOpen(true)}>Assign Employee</Button>
                    <Button onClick={() => setAddEmployeeModalOpen(true)}>
                        Add New Employee <UserAddOutlined />
                    </Button>
                </Space>
            </SpacedContent>
            <Table columns={columns} data={users} onChange={() => {}}></Table>
            <AssignEmployeeModal onAssign={handleAssignUser} isOpen={assignEmployeeModalOpen} setIsOpen={setAssignEmployeeModalOpen} />
            <UnassignEmployeeModal
                isOpen={unassignEmployeeModalOpen}
                setIsOpen={setUnassignEmployeeModalOpen}
                hospitalName=''
                employee={userToUnassign}
            />
            <AddNewEmployeeModal isOpen={addEmployeeModalOpen} setIsOpen={setAddEmployeeModalOpen} edit={() => {}} />
        </>
    );
};

export default HospitalEmployeeTable;
