import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, message, Modal, Select, Typography } from 'antd';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMergeRdvmClinicsMutation, useRemoveRdvmClinicMutation } from 'services/rdvm.service';
import { useSearchRdvmQuery } from 'services/reports.service';
import { US_STATES_MAP } from 'utils/consts';
import { pluralize } from 'utils/helpers/format';
import { ROUTES } from 'utils/types';

interface ConfirmRemoveModalProps {
    isOpen: boolean;
    onClose: () => void;
    clinicName?: string;
    visitCount?: number;
    rdvmId?: string;
}

const ConfirmRemoveModal: React.FC<ConfirmRemoveModalProps> = ({ isOpen, onClose, clinicName, visitCount, rdvmId }) => {
    const [validationError, setValidationError] = useState(false);
    const [rdvmSearch, setRdvmSearch] = useState('');
    const [selectedRdvm, setSelectedRdvm] = useState<any | undefined>();
    const [mergeRdvmClinics] = useMergeRdvmClinicsMutation();
    const [removeRdvmClinic] = useRemoveRdvmClinicMutation();
    const history = useHistory();
    const { data: rdvms } = useSearchRdvmQuery(null);

    const rdvmOptions = useMemo(() => {
        const regex = new RegExp(rdvmSearch.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return _.groupBy(
            rdvms?.filter((rdvm) => (rdvmSearch ? rdvm.display_name.match(regex) || rdvm.section_group === 0 : true)),
            'section_group',
        );
    }, [rdvms, rdvmSearch]);

    const optionsMapping = (rdvm: any) => ({ value: rdvm.clinic_id, label: rdvm.display_name });

    const getNearbyLocations = useCallback(() => {
        return rdvmOptions?.[1]
            ? [
                  {
                      label: (
                          <>
                              <Divider className='rdvm-options-divider' />
                              <span className='rdvm-options-title'>Nearby locations</span>
                          </>
                      ),
                      options: rdvmOptions?.[1]?.map(optionsMapping) ?? [],
                  },
              ]
            : [];
    }, [rdvmOptions]);

    const getRdmvsGroupedByState = useCallback(() => {
        const formattedRdvmsWithStates = rdvmOptions?.[3]?.map((option) => ({ ...option, state: US_STATES_MAP[option.state] }));
        const rdvmsGroupByState = _.groupBy(_.orderBy(formattedRdvmsWithStates, 'state'), 'state');
        const arr = [];
        let index = 0;

        if (rdvmsGroupByState) {
            for (const [state, rdvms] of Object.entries(rdvmsGroupByState)) {
                arr.push({
                    label: (
                        <>
                            {index === 0 && <Divider className='rdvm-options-divider' />}
                            <span className='rdvm-options-state'>{state}</span>
                        </>
                    ),
                    options: rdvms.map(optionsMapping) ?? [],
                });

                index++;
            }
        }

        return arr;
    }, [rdvmOptions]);

    const handleSubmit = () => {
        if (visitCount && visitCount > 0) {
            if (!selectedRdvm) {
                setValidationError(true);
                return;
            }

            mergeRdvmClinics({ duplicate_rdvm_id: rdvmId, primary_rdvm_id: selectedRdvm.clinic_id })
                .unwrap()
                .then((response: any) => {
                    message.success(`${clinicName} has been successfully removed.`);
                    handleClose();
                    history.push(ROUTES.CLINICS_LISTING);
                })
                .catch((error) => {
                    message.error(error.message);
                });
        } else {
            removeRdvmClinic({ clinic_id: rdvmId, is_deleted: true })
                .unwrap()
                .then((response: any) => {
                    message.success(`${response?.data?.display_name} has been successfully removed.`);
                    handleClose();
                    history.push(ROUTES.CLINICS_LISTING);
                })
                .catch((error) => {
                    message.error(error.message);
                });
        }
    };

    const rdvmById = (rdvm_id: string) => rdvms?.find((item) => item.clinic_id === rdvm_id);

    const handleClose = () => {
        setSelectedRdvm(undefined);
        setValidationError(false);
        onClose();
    };

    return (
        <Modal
            title={
                <span>
                    <ExclamationCircleOutlined style={{ color: '#e7202e', marginRight: 8 }} />
                    This change cannot be undone!
                </span>
            }
            open={isOpen}
            onCancel={handleClose}
            footer={[
                <Button key='back' onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key='submit' type='primary' danger onClick={handleSubmit}>
                    {!!(visitCount && visitCount > 0) ? 'Remove and Update Visits' : 'Remove'}
                </Button>,
            ]}
            destroyOnClose
        >
            <div>
                <Typography.Paragraph>
                    {clinicName} will be
                    <Typography.Text strong> completely removed </Typography.Text>
                    from the clinic list, {!!(visitCount && visitCount > 0) && 'removed from visits, '}and no longer available as a primary
                    rDVM option in visits.
                </Typography.Paragraph>
                {!!(visitCount && visitCount > 0) && (
                    <Col span={24} style={{ width: '100%' }}>
                        <Typography.Paragraph>
                            There {pluralize(visitCount, 'is', 'are')} {visitCount} {pluralize(visitCount, 'visit', 'visits')} currently
                            associated with this clinic. Please select a different primary rDVM for these visits.
                        </Typography.Paragraph>
                        <Typography.Paragraph style={{ marginBottom: 10 }}>
                            <span>
                                <Typography.Text type='danger'>* </Typography.Text>
                                Replacement Primary rDVM
                            </span>
                        </Typography.Paragraph>
                        <Select
                            placeholder='Select'
                            onChange={(value: string) => {
                                setSelectedRdvm(rdvmById(value));
                                setValidationError(false);
                            }}
                            onSearch={setRdvmSearch}
                            showSearch
                            value={selectedRdvm?.display_name}
                            style={{ width: '100%' }}
                            status={validationError ? 'error' : ''}
                            options={
                                rdvmOptions && [
                                    ...(rdvmOptions?.[0]?.map(optionsMapping) ?? []),
                                    ...getNearbyLocations(),
                                    ...getRdmvsGroupedByState(),
                                ]
                            }
                            filterOption={false}
                        />
                        {validationError && (
                            <Typography.Text type='danger' style={{ display: 'block', marginTop: 2 }}>
                                Please select a replacement primary rDVM!
                            </Typography.Text>
                        )}
                    </Col>
                )}
            </div>
        </Modal>
    );
};

export default ConfirmRemoveModal;
