export enum USER_PERMISSIONS {
    primary_location_edit = 'ap:section:clinics:primary-location:edit',
    section_user_management = 'ap:section:user-mgmt',
    section_marketing = 'ap:section:marketing',
    section_clinics = 'ap:section:clinics',
    section_clinics_deactivate = 'ap:section:clinics:deactivate',
    section_clinics_remove = 'ap:section:clinics:remove',
    section_clinics_validate = 'ap:section:clinics:validate',
    section_clinics_upload = 'ap:section:clinics:upload',
    section_clinics_edit = 'ap:section:clinics:edit',
    section_hospital_management = 'ap:section:hospital-mgmt',
}

export function checkIfUserHasNecessaryPermission(userPermissions: USER_PERMISSIONS[] = [], necessaryPermission: USER_PERMISSIONS) {
    return userPermissions?.some((permission) => permission === necessaryPermission) ?? false;
}
