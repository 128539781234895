import emptySplitApi from './emptySplitApi';

const avalaraTaxCodeApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getTaxCodes: builder.query<Array<{ tax_code: string }>, void>({
            query: () => `/avalara_tax_code`,
            transformResponse: (response: { data: Array<{ tax_code: string }> }) => {
                return response.data;
            },
        }),
    }),
});

export const { useGetTaxCodesQuery } = avalaraTaxCodeApi;
