import {
    AreaChartOutlined,
    CaretDownOutlined,
    MenuOutlined,
    ProjectOutlined,
    ReconciliationOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Layout, Menu } from 'antd';
import { Avatar, Button } from 'components/lib';
import { useMediaQuery } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGetUserDataQuery, useLogoutMutation } from 'services/auth.service';
import { appUrl, BASE_QUERY_OPTIONS } from 'utils/consts';
import { ROUTES } from 'utils/types';
import { checkIfUserHasNecessaryPermission, USER_PERMISSIONS } from 'utils/userPermissions';
import sidebarStyles from './sidebar.module.css';

const { SubMenu } = Menu;
const { Header, Sider } = Layout;

interface SidebarMenuItemProps {
    route: string;
    handlelinkClick: () => void;
}

const Sidebar: React.FC = ({ children }) => {
    const { lg } = useMediaQuery();
    const [collapsed, setCollapsed] = useState(true);
    const location = useLocation();
    const [logout] = useLogoutMutation();

    // Only on initialisation then collapse
    useEffect(() => {
        // if (!sm) {
        //   setCollapsed(true);
        // }
    }, []);

    const { data: loggedInUserData } = useGetUserDataQuery(null, BASE_QUERY_OPTIONS);

    const userHasSectionHospitalManagementPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_hospital_management,
    );
    const userHasSectionUserManagementPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_user_management,
    );
    const userHasSectionMarketingPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_marketing,
    );
    const userHasSectionClinicsPermission = checkIfUserHasNecessaryPermission(
        loggedInUserData?.user_permissions,
        USER_PERMISSIONS.section_clinics,
    );

    let userName = useMemo(() => {
        if (loggedInUserData) {
            return `${loggedInUserData.first_name} ${loggedInUserData.last_name}`;
        }
        return '';
    }, [loggedInUserData]);

    const handlelinkClick = () => {
        if (lg) {
            setCollapsed(true);
        }
    };
    const menu = (
        <Menu>
            <Menu.Item>
                <a href={appUrl}>Patient Management System</a>
            </Menu.Item>
            <Menu.Item
                onClick={() => {
                    logout(null);
                }}
            >
                Sign Out
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout>
            <Header className={sidebarStyles.sidebarHeader}>
                <div className='logo' />
                <Menu mode='horizontal' defaultSelectedKeys={['2']}>
                    {/* <img src={vegLogo} alt="Veg Logo" /> */}
                    <div className={sidebarStyles.topNavWrapper}>
                        <div></div>
                        <Button className={sidebarStyles.hamburgerMenu} onClick={() => setCollapsed(!collapsed)}>
                            <MenuOutlined />
                        </Button>
                        <Dropdown className={sidebarStyles.userDropdown} overlay={menu} placement='bottomRight' arrow trigger={['click']}>
                            <div className={sidebarStyles.userDropdownButton}>
                                <Avatar content={userName} size={32} saturation={60} lightness={60} />
                                {userName} <CaretDownOutlined />
                            </div>
                        </Dropdown>
                    </div>
                </Menu>
            </Header>
            <Layout className={sidebarStyles.layoutWrapper}>
                <Sider
                    width={234}
                    className={`site-layout-background ${sidebarStyles.antLayoutSider}`}
                    collapsedWidth={0}
                    collapsed={collapsed}
                    breakpoint='lg'
                    onBreakpoint={() => setCollapsed(!collapsed)}
                    trigger={null}
                >
                    <Dropdown className={sidebarStyles.userDropdownSide} overlay={menu} placement='bottomRight' arrow trigger={['click']}>
                        <div className={sidebarStyles.userDropdownSideButton}>
                            <Avatar content={userName} size={34} saturation={60} lightness={60} />
                            {userName} <CaretDownOutlined />
                        </div>
                    </Dropdown>

                    <Menu
                        mode='inline'
                        defaultSelectedKeys={[location.pathname]}
                        defaultOpenKeys={['sub1']}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                        {userHasSectionHospitalManagementPermission && (
                            <SubMenu key='sub1' icon={<ReconciliationOutlined />} title='Hospital Management'>
                                <Menu.Item key={ROUTES.HOSPITALS_LISTING} className='adapted-menu-item'>
                                    <Link onClick={handlelinkClick} to={ROUTES.HOSPITALS_LISTING}>
                                        Hospitals
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.MEDICATIONS_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.MEDICATIONS_LISTING}>
                                        Medications
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.DIAGNOSTICS_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.DIAGNOSTICS_LISTING}>
                                        Diagnostics
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.IDEXX_REF_LABS_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.IDEXX_REF_LABS_LISTING}>
                                        Idexx External Labs
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.FLUIDS_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.FLUIDS_LISTING}>
                                        Fluids
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.SUPPLEMENTALS_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.SUPPLEMENTALS_LISTING}>
                                        Supplementals
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.NON_MEDICAL_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.NON_MEDICAL_LISTING}>
                                        Non-Medicals
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.TASKS_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.TASKS_LISTING}>
                                        Tasks
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.PACKAGES_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.PACKAGES_LISTING}>
                                        Packages
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.COMPLAINTS_AND_DIFFERENTIALS}>
                                    <Link onClick={handlelinkClick} to={ROUTES.COMPLAINTS_AND_DIFFERENTIALS}>
                                        C&Ds
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={ROUTES.NOTATION}>
                                    <Link onClick={handlelinkClick} to={ROUTES.NOTATION}>
                                        Notation
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                        )}
                        {userHasSectionUserManagementPermission && (
                            <SubMenu key='sub6' icon={<UserOutlined />} title='User Management'>
                                <Menu.Item key={ROUTES.USER_LISTING}>
                                    <Link onClick={handlelinkClick} to={ROUTES.USER_LISTING}>
                                        Users
                                    </Link>
                                </Menu.Item>
                                {/* <Menu.Item key="6">Permissions</Menu.Item> */}
                            </SubMenu>
                        )}
                        {userHasSectionMarketingPermission && (
                            <SubMenu key='sub3' icon={<AreaChartOutlined />} title='Marketing'>
                                {userHasSectionClinicsPermission && (
                                    <Menu.Item key={ROUTES.CLINICS_LISTING}>
                                        <Link onClick={handlelinkClick} to={ROUTES.CLINICS_LISTING}>
                                            Clinics
                                        </Link>
                                    </Menu.Item>
                                )}
                            </SubMenu>
                        )}

                        {/* <SubMenu key="sub4" icon={<FileTextOutlined />} title="Billing">
              <Menu.Item key="9">Billing</Menu.Item>
            </SubMenu> */}

                        <Menu.Item key={ROUTES.REPORTS} icon={<ProjectOutlined />}>
                            <Link onClick={handlelinkClick} to={ROUTES.REPORTS}>
                                Reports
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout style={{ padding: '0 24px 24px' }}>{children}</Layout>
            </Layout>
        </Layout>
    );
};

export default Sidebar;
