import {
    backendCriToFrontendCri,
    backendFluidToFrontendFluid,
    Instruction,
    isInstanceOfAPICriSearchInstruction,
    isInstanceOfAPIFluidSearchInstruction,
    isInstanceOfPackageSearchInstruction,
} from 'utils/types';
import instructionsApi, { INSTRUCTION_TAG_TYPE } from './instructionsApi';

interface MedicationRoutes {
    id: string;
    name: string;
}

export const instructionsService = instructionsApi.injectEndpoints({
    endpoints: (builder) => ({
        searchInstructions: builder.query<Instruction[], void>({
            query: (searchQuery) => `/search`,
            // transformResponse: (response: { data: any }) => {
            //   return response.data;
            // },
            transformResponse: (response: { data: Instruction[] }): Instruction[] => {
                const values: Instruction[] = response.data.map((item) => {
                    if (isInstanceOfAPIFluidSearchInstruction(item)) {
                        return backendFluidToFrontendFluid(item);
                    } else if (isInstanceOfAPICriSearchInstruction(item)) {
                        return backendCriToFrontendCri(item);
                    } else {
                        return item;
                    }
                });
                return values.filter((instruction) => !isInstanceOfPackageSearchInstruction(instruction));
            },
            providesTags: (result, error, id) => [{ type: INSTRUCTION_TAG_TYPE, id: 'LIST' }],
        }),
        getMedicationRoutes: builder.query<MedicationRoutes[], void>({
            query: () => '/medications-routes',
            transformResponse: (response: { data: MedicationRoutes[] }) => {
                return response.data;
            },
        }),
    }),
});

export const { useSearchInstructionsQuery, useGetMedicationRoutesQuery } = instructionsService;
