import { Form, FormInstance, Input, Radio, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import InputPercent from 'components/lib/formFields/InputPercent';
import InputWithLock from 'components/lib/formFields/InputWithLock';
import SelectWithLock from 'components/lib/formFields/SelectWithLock';
import { FC, useEffect, useState } from 'react';
import { RDVM_DISCOUNT_TYPE, RDVM_PRIORITY, RDVM_RELATIONSHIP_STATUS } from 'utils/consts';
import { Hospital } from 'utils/types';
import { validateFieldIsGreaterThan } from 'utils/validationFuncs';

interface RelationshipTabPaneProps {
    hospitals: Hospital[];
    userHasPrimaryLocationEditPermission: boolean;
    userHasEditPermission?: boolean;
    initialDiscountType?: string;
    form?: FormInstance;
    isCreating?: boolean;
}

const RelationshipTabPane: FC<RelationshipTabPaneProps> = ({
    hospitals,
    userHasPrimaryLocationEditPermission,
    userHasEditPermission,
    initialDiscountType,
    form,
    isCreating,
}) => {
    const [discountType, setDiscountType] = useState(initialDiscountType || null);
    let discountAmountInput;
    let discountRules: Rule[] = [];
    const locked = isCreating ? false : !userHasEditPermission;

    if (discountType === 'Percentage') {
        discountAmountInput = <InputPercent locked={locked} />;
    } else if (discountType === 'Fixed amount') {
        discountAmountInput = <InputWithLock locked={locked} type='number' min={0} />;
        discountRules = [
            {
                validator: validateFieldIsGreaterThan('Duration', 0),
            },
        ];
    } else if (discountType === 'Other') {
        discountAmountInput = <InputWithLock locked={locked} type='text' />;
    } else {
        discountAmountInput = <Input disabled />;
    }

    useEffect(() => {
        if (initialDiscountType !== discountType) {
            form?.setFieldsValue({ discount_amount: null });
        }
    }, [discountType, initialDiscountType, form]);

    return (
        <>
            <Form.Item name='associated_hospital_id' label='Primary VEG:'>
                <SelectWithLock
                    allowClear={!userHasEditPermission}
                    placeholder='Select a VEG hospital'
                    options={hospitals?.map((hosp) => ({
                        value: hosp.id,
                        label: hosp.display_name,
                    }))}
                    locked={!isCreating && !userHasPrimaryLocationEditPermission}
                />
            </Form.Item>
            <Form.Item name='discount_type' label='Discount Type:'>
                <SelectWithLock locked={locked} placeholder='Select discount type' onChange={(value) => setDiscountType(value)}>
                    {RDVM_DISCOUNT_TYPE.map((discount) => (
                        <Select.Option key={discount.value} value={discount.value}>
                            {discount.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
            <Form.Item name='discount_amount' label={discountType === 'Other' ? 'Discount:' : 'Discount Amount:'} rules={discountRules}>
                {discountAmountInput}
            </Form.Item>
            <Form.Item name='has_call_forwarding' label='Has Call Forwarding:'>
                <Radio.Group disabled={locked}>
                    <Radio value='true'>Yes</Radio>
                    <Radio value='false'>No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name='relationship_status' label='Relationship Status:'>
                <SelectWithLock locked={locked} placeholder='Select relationship status'>
                    {RDVM_RELATIONSHIP_STATUS.map((status) => (
                        <Select.Option key={status.value} value={status.value}>
                            {status.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
            <Form.Item name='priority' label='Priority:'>
                <SelectWithLock locked={locked} placeholder='Select priority'>
                    {RDVM_PRIORITY.map((priority) => (
                        <Select.Option key={priority.value} value={priority.value}>
                            {priority.name}
                        </Select.Option>
                    ))}
                </SelectWithLock>
            </Form.Item>
        </>
    );
};

export default RelationshipTabPane;
