import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { Button, SpacedContent, Table } from 'components/lib';
import { useEffect, useMemo } from 'react';
import { useLazyGetHospitalMedicinesQuery } from 'services/hospitals.service';
import { Hospital, HospitalMedicine, QueryResponse } from 'utils/types';

interface HospitalMedicineTableProps {
    hospital: Hospital | null;
}

const HospitalMedicineTable: React.FC<HospitalMedicineTableProps> = ({ hospital }) => {
    let [getHospitalMedicines, { data: medicine }] = useLazyGetHospitalMedicinesQuery<QueryResponse<HospitalMedicine[]>>();

    useEffect(() => {
        if (hospital !== null) {
            getHospitalMedicines({ hospitalId: hospital.id });
        }
    }, [hospital]);
    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                width: 200,
                defaultSortOrder: 'ascend',
            },
            {
                title: 'Brand',
                dataIndex: 'brand_name',
                key: 'brand_name',
                sorter: true,
                ellipsis: true,
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                sorter: true,
                ellipsis: true,
                width: 110,
            },
            {
                title: 'Cost',
                dataIndex: 'unit_cost',
                key: 'unit_cost',
                sorter: true,
                ellipsis: true,
            },
            {
                title: '',
                key: 'action',
                dataIndex: 'id',
                width: 100,
                render: (id: string, medicine: HospitalMedicine) => {
                    return (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item>
                                        <a target='_blank' rel='noopener noreferrer' href='https://www.antgroup.com'>
                                            <SpacedContent>
                                                Edit <EditOutlined />
                                            </SpacedContent>
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            }
                            placement='bottomRight'
                            arrow
                        >
                            <Button>
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    );
                },
            },
        ];
    }, []);
    return <Table columns={columns} data={medicine} onChange={() => {}}></Table>;
};

export default HospitalMedicineTable;
