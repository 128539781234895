import { FC, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Modal, Typography, Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import styles from './index.module.css';
import { useUploadRdvmFileMutation } from 'services/rdvm.service';
import UploadSummaryModal from './UploadSummaryModal';
import { RDVM_UPLOAD_TEMPLATE } from 'utils/consts';

const { Link, Text } = Typography;

interface Props {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
}

const UploadDraftsRdvmModal: FC<Props> = ({ isVisible, setIsVisible }) => {
    const [uploadRdvmFile, { data, isLoading }] = useUploadRdvmFileMutation();
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

    const beforeUpload = (file: File): boolean => {
        const isCsv = file.type === 'text/csv';
        if (!isCsv) {
            message.error(`${file.name} is not a csv file.`);
        }
        return isCsv;
    };

    const handleUpload = async (file: RcFile) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            await uploadRdvmFile(formData).unwrap();
            message.success('File uploaded successfully');
            setIsVisible(false);
            setIsSuccessModalVisible(true);
        } catch (error) {
            console.error('Upload error:', error);
            message.error('Upload failed');
        }
    };

    const customRequest = ({
        file,
        onSuccess,
        onError,
    }: any) => {
        handleUpload(file as RcFile)
            .then(() => onSuccess?.("ok", file))
            .catch(onError);
    };

    const uploadProps = {
        name: 'file',
        beforeUpload,
        customRequest,
        showUploadList: false,
    };

    const handleClose = (): void => {
        setIsVisible(false);
    };

    const handleSuccessModalClose = (): void => {
        setIsSuccessModalVisible(false);
    };

    return (
        <>
            <Modal destroyOnClose title='Clinic Upload' open={isVisible} onCancel={handleClose} footer={null}>
                <Text>
                    Use{' '}
                    <Link href={RDVM_UPLOAD_TEMPLATE} className={styles.templateLink}>
                        this template
                    </Link>{' '}
                    when uploading files. A clinic must include all required fields and be properly formatted to upload successfully.
                </Text>
                <Upload.Dragger {...uploadProps} className={styles.uploadDragger}>
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined className={styles.inboxIcon} />
                    </p>
                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    <p className='ant-upload-hint'>CSV files only</p>
                </Upload.Dragger>
            </Modal>

            <UploadSummaryModal
                isVisible={isSuccessModalVisible}
                onClose={handleSuccessModalClose}
                data={data}
            />
        </>
    );
};

export default UploadDraftsRdvmModal;
