import { Breadcrumb, Content, PageTitle } from 'components/lib';
import { useDependantBreadcrumbs } from 'hooks';
import { useParams } from 'react-router-dom';
import { useGetComplaintQuery } from 'services/complaints.service';
import { format } from 'utils/helpers';
import { Complaint, QueryResponse, ROUTES } from 'utils/types';
import ComplaintDifferentialsTable from './ComplaintDifferentialsTable';

const ComplaintDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    let { data: complaint } = useGetComplaintQuery<QueryResponse<Complaint>>({
        id,
    });

    let [breadcrumbs] = useDependantBreadcrumbs(
        ['Management', 'Differentials & Complaints', 'Complaints'],
        (complaint: Complaint) => format.capitalizeStr(complaint.common_name),
        complaint,
    );

    if (typeof complaint === 'undefined') {
        return null;
    }

    return (
        <>
            <Breadcrumb path={breadcrumbs} />
            <Content>
                <div>
                    <PageTitle title={format.capitalizeStr(complaint.common_name)} backRoute={ROUTES.COMPLAINTS_AND_DIFFERENTIALS} />

                    {complaint && <ComplaintDifferentialsTable {...complaint} />}
                </div>
            </Content>
        </>
    );
};

export default ComplaintDetail;
