import { Button, message, Modal } from 'antd';
import React from 'react';
import { useSetHospitalLoginCodeMutation } from 'services/hospitals.service';

interface WhiteboardResetButtonProps {
    hospitalId: string;
}

const WhiteboardResetButton: React.FC<WhiteboardResetButtonProps> = ({ hospitalId }) => {
    const { confirm } = Modal;
    const onClick = () => {
        confirm({
            title: 'Are you sure you want to reset the whiteboard link? Ressetting the whiteboard link will discontinue the previous link.',
            onOk() {
                setHospitalLoginCode(hospitalId).then((res) => {
                    if ('data' in res) {
                        message.success('Sucessfully reset the whiteboard link for the hospital.');
                    } else {
                        message.error('There was an error reseting the whiteboard link for the hospital.');
                        console.error(res.error);
                    }
                });
            },
            okText: 'Reset Link',
        });
    };

    const [setHospitalLoginCode] = useSetHospitalLoginCodeMutation();

    return (
        <>
            <Button onClick={onClick}>Reset Whiteboard Link</Button>
        </>
    );
};

export default WhiteboardResetButton;
