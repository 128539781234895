import { EditOutlined, EllipsisOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu, Typography } from 'antd';
import { Breadcrumb, Button, Content, Space, SpacedContent, Table, Title } from 'components/lib';
import { useModalWithState, useTableFilters } from 'hooks';
import { useMemo, useState } from 'react';
import { useGetMacrosQuery } from 'services/macros.service';
import { format } from 'utils/helpers';
import { NoteMacro } from 'utils/types';
import AddMacrolModal from './AddMacroModal';
import EditMacroModal from './EditMacroModal';

const MacrosListing: React.FC = () => {
    const [addMacroModalOpen, setAddMacroModalOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [currentEditMacro, editModalOpen, openEditMacroModal, closeEditMacroModal] = useModalWithState<NoteMacro>();

    const { data: macros, refetch } = useGetMacrosQuery();

    const filteredData = macros?.filter((macro) => {
        if (search === '') return macro;

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return macro.key_word.match(regex);
    });

    const { handleChange } = useTableFilters({
        onFilter: refetch,
        additional: { brand: '' },
    });

    const columns = useMemo(() => {
        return [
            {
                title: 'Label',
                dataIndex: 'label',
                key: 'label',
                sorter: true,
                defaultSortOrder: 'ascend',
            },
            {
                title: 'Keyword',
                dataIndex: 'key_word',
                key: 'key_word',
                sorter: true,
                width: 200,
                defaultSortOrder: 'ascend',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                sorter: true,
                ellipsis: true,
                render: (cat: string) => format.capitalizeStr(cat),
            },
            {
                title: 'Content',
                dataIndex: 'content',
                key: 'content',
                sorter: true,
                ellipsis: true,
            },
            {
                title: '',
                dataIndex: '',
                key: '',
                sorter: true,
                ellipsis: true,
                width: 100,
                render: (id: string, Macro: NoteMacro) => {
                    return (
                        <Dropdown
                            key={id}
                            overlay={
                                <Menu>
                                    <Menu.Item onClick={() => openEditMacroModal(Macro)}>
                                        <SpacedContent>
                                            Edit <EditOutlined />
                                        </SpacedContent>
                                    </Menu.Item>
                                </Menu>
                            }
                            placement='bottomRight'
                            arrow
                        >
                            <Button>
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    );
                },
            },
        ];
    }, []);

    if (typeof macros === 'undefined') {
        return null;
    }

    return (
        <>
            <Breadcrumb path={['Notation', 'Dashboard']} />
            <Content>
                <Title level={2}>Notation</Title>
                <Space direction='vertical'>
                    <Typography.Text>{macros.length} items</Typography.Text>
                    <SpacedContent>
                        <Space>
                            <Input
                                placeholder='Search Macros'
                                suffix={<SearchOutlined />}
                                value={search}
                                style={{ width: 280 }}
                                onChange={({ target }) => setSearch(target.value)}
                            />
                        </Space>
                        <Button onClick={() => setAddMacroModalOpen(true)}>
                            Add Macro <PlusOutlined />
                        </Button>
                    </SpacedContent>

                    <Table columns={columns} data={filteredData ?? []} onChange={handleChange} />
                </Space>
            </Content>
            <AddMacrolModal isOpen={addMacroModalOpen} setIsOpen={setAddMacroModalOpen} />
            <EditMacroModal macro={currentEditMacro} isOpen={editModalOpen} setIsOpen={closeEditMacroModal} />
        </>
    );
};

export default MacrosListing;
