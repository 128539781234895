import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { emptySplitApi } from 'services';
import { authApi } from 'services/authApi';
import instructionsApi from 'services/instructionsApi';
import { pimsApi } from 'services/pimsApi';

export const store = configureStore({
    reducer: {
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [instructionsApi.reducerPath]: instructionsApi.reducer,
        [pimsApi.reducerPath]: pimsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableStateInvariant: false,
            immutableStateInvariant: false,
        })
            .concat(emptySplitApi.middleware)
            .concat(authApi.middleware)
            .concat(instructionsApi.middleware)
            .concat(pimsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
