import { Divider, Form, FormInstance, Input, InputNumber, Radio } from 'antd';
import { HiddenInput } from 'components/lib/formFields/HiddenInput';
import ProductCategorySelect from 'components/lib/ProductCategorySelect';
import { Task } from 'services/tasks.service';
import { INSTRUCTION_FREQUENCY_REGEX } from 'utils/consts';

interface TaskTabProps {
    taskToUpdate: Task | null;
    form: FormInstance;
}

export const TaskTab = ({ taskToUpdate, form }: TaskTabProps) => {
    return (
        <>
            {taskToUpdate && <HiddenInput fieldName='id' initialValue={taskToUpdate.id} />}
            <HiddenInput fieldName='default_duration_mins' initialValue={null} />

            <Form.Item label='Name' name='name' initialValue={taskToUpdate?.name} rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Divider />

            <Form.Item label='Product Category' name='product_category_id' initialValue={taskToUpdate?.product_category_id ?? null}>
                <ProductCategorySelect
                    defaultValue={taskToUpdate?.product_category_id ?? null}
                    onChange={(value) => form.setFieldsValue({ product_category_id: value })}
                />
            </Form.Item>

            <Form.Item label='Sort Rank' name='sort_rank' initialValue={taskToUpdate?.sort_rank ?? 0} rules={[{ required: true }]}>
                <InputNumber />
            </Form.Item>

            <Form.Item
                label='Has Commission'
                name='has_commission'
                initialValue={taskToUpdate?.has_commission ?? true}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Default
            </Divider>

            <Form.Item
                label='Frequency'
                name='default_frequency'
                initialValue={taskToUpdate?.default_frequency ?? null}
                rules={[{ pattern: INSTRUCTION_FREQUENCY_REGEX }]}
            >
                <Input />
            </Form.Item>
        </>
    );
};
