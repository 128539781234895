import { Form, Input, Modal } from 'antd';
import { useSuccessMessage } from 'hooks';
import { NewComplaintParams, useCreateComplaintMutation } from 'services/complaints.service';
import { BaseModalProps } from 'utils/types';

enum NEW_COMPLAINT_FIELDS {
    COMMON_NAME = 'common_name',
    MEDICAL_NAME = 'medical_name',
}

interface NewComplaintModalProps extends BaseModalProps {}

const initialState: NewComplaintParams = {
    [NEW_COMPLAINT_FIELDS.COMMON_NAME]: '',
    [NEW_COMPLAINT_FIELDS.MEDICAL_NAME]: '',
};

const NewComplaintModal: React.FC<NewComplaintModalProps> = ({ isOpen, setIsOpen }) => {
    const [form] = Form.useForm();
    const [createComplaint, { isSuccess, isError }] = useCreateComplaintMutation();

    useSuccessMessage({
        success: {
            status: isSuccess,
            message: `New complaint'${form.getFieldValue(NEW_COMPLAINT_FIELDS.COMMON_NAME)}' has been succesfully created.`,
            callback: () => {
                handleClose();
            },
        },
        error: {
            status: isError,
            message: `It seems there has been an issue adding the new complaint '${form.getFieldValue(
                NEW_COMPLAINT_FIELDS.COMMON_NAME,
            )}', please try again.`,
        },
    });

    const handleClose = () => {
        setIsOpen(false);
        form.resetFields();
    };

    const handleSave = (values: NewComplaintParams) => {
        createComplaint(values);
    };

    const handleFail = () => {};

    return (
        <Modal title='Add New Complaint' open={isOpen} onOk={form.submit} onCancel={handleClose}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={initialState}
                onFinish={handleSave}
                onFinishFailed={handleFail}
            >
                <Form.Item
                    label='Common Name'
                    name={NEW_COMPLAINT_FIELDS.COMMON_NAME}
                    rules={[
                        {
                            required: true,
                            message: "Please ensure the complaint's common name has been completed.",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label='Medical Name' name={NEW_COMPLAINT_FIELDS.MEDICAL_NAME}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default NewComplaintModal;
