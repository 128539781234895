import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';

interface ButtonProps extends AntButtonProps {
    full?: boolean;
}
const Button: React.FC<ButtonProps> = ({ children, ...rest }) => {
    return <AntButton {...rest}>{children}</AntButton>;
};

export default Button;
