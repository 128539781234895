import { Button } from 'antd';
import { useQuery } from 'hooks/useQuery';
import { useState } from 'react';
import { AUTH_HOST } from 'utils/consts';
import SSOStyles from './SSO.module.css';

interface SSOPageProps {}

export const SSOPage: React.FC<SSOPageProps> = () => {
    const searchParams = useQuery();
    const errorMessage = searchParams.get('err');
    let [loading, setLoading] = useState(false);

    const redirectToSSO = () => {
        setLoading(!loading);
        window.location.href = `${AUTH_HOST}/sso-login?${searchParams.toString()}`;
    };

    return (
        <div className={SSOStyles.loginWrapper}>
            {!!errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <p>&lt; Insert PIMS logo and name here &gt;</p>
            <Button
                type='primary'
                style={{ backgroundColor: '#00297a' }}
                onClick={redirectToSSO}
                loading={errorMessage === null && loading}
            >
                Log in with Okta
            </Button>
            <Button type='link' href='https://veg.okta.com' target='_self'>
                Back to dashboard
            </Button>
        </div>
    );
};
