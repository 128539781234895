import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Typography } from 'antd';
import { Button, Space, SpacedContent, Table } from 'components/lib';
import { useMediaQuery } from 'hooks';
import { useMemo } from 'react';
import { Complaint } from 'utils/types';

const ComplaintDifferentialsTable: React.FC<Complaint> = ({ differentials }) => {
    const { sm } = useMediaQuery();

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                width: 200,
                defaultSortOrder: 'ascend',
            },
            {
                title: 'Medical Name',
                dataIndex: 'name',
                key: 'medical_namename',
                sorter: true,
                width: 200,

                ellipsis: true,
            },
            {
                title: '',
                key: 'action',
                dataIndex: 'id',
                render: (id: string) => {
                    return (
                        <Button danger onClick={() => {}}>
                            Remove
                        </Button>
                    );
                },
                width: 80,
            },
        ];
    }, []);

    return (
        <div>
            <Space direction='vertical'>
                <SpacedContent>
                    <Space>
                        <Typography.Text>{differentials.length} items</Typography.Text>
                        <Input
                            placeholder='Search Differentials'
                            suffix={<SearchOutlined />}
                            value={''}
                            style={{ width: 280 }}
                            onChange={({ target }) => {
                                // setSearchQuery(target.value)
                            }}
                        />
                    </Space>

                    <Button onClick={() => {}}>
                        <PlusOutlined />
                        Add Differential
                    </Button>
                </SpacedContent>
                {sm ? null : (
                    // <ClinicCards clinics={data} viewClinic={viewClinic} />
                    <Table columns={columns} data={differentials} onChange={() => {}}></Table>
                )}
            </Space>
        </div>
    );
};

export default ComplaintDifferentialsTable;
