import Sidebar from 'components/lib/Sidebar';
import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { ROUTES } from 'utils/types/enums';

interface ProtectedRouteProps {
    component: React.ReactNode;
    isUserLoggedIn: boolean;
    isUserFetching: boolean;
    path: string;
    exact?: boolean;
}

// Redirects user to login page if they are not authenticated
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, isUserLoggedIn, isUserFetching, ...rest }: any) => {
    const history = useHistory();
    const searchParams = useQuery();

    useEffect(() => {
        if (!isUserLoggedIn) {
            searchParams.append('redirect', history.location.pathname);
            const search = searchParams.toString();
            history.push({ pathname: ROUTES.SSO, search });
        }
    }, [isUserLoggedIn]);

    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <Sidebar>
                        <Component {...props} />
                    </Sidebar>
                );
            }}
        />
    );
};

export default ProtectedRoute;
