import { Divider, Form, Input, InputNumber, Radio, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { HiddenInput } from 'components/lib/formFields/HiddenInput';
import ProductCategorySelect from 'components/lib/ProductCategorySelect';
import SupplementalSelect from 'components/lib/SupplementalSelect';
import { Fluid, useListFluidsCategoriesQuery } from 'services/fluids.service';
import { roundTo } from 'utils/helpers/format';

interface FluidsTabProps {
    fluidToUpdate: Fluid | null;
    form: FormInstance;
}

export const FluidsTab = ({ fluidToUpdate, form }: FluidsTabProps) => {
    const { data: fluidCategories } = useListFluidsCategoriesQuery();

    return (
        <>
            {fluidToUpdate && <HiddenInput fieldName='id' initialValue={fluidToUpdate.id} />}
            <HiddenInput fieldName='default_duration_mins' initialValue={null} />

            <Form.Item label='Name' name='name' initialValue={fluidToUpdate?.name} rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item label='Abbreviation' name='abbreviation' initialValue={fluidToUpdate?.abbreviation ?? null}>
                <Input />
            </Form.Item>

            <Form.Item label='Fluid Category' name='category' initialValue={fluidToUpdate?.category} rules={[{ required: true }]}>
                <Select>
                    {fluidCategories?.map(({ name }) => (
                        <Select.Option key={name} value={name}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label='Supplemental'
                name='supplemental_id'
                initialValue={fluidToUpdate?.supplemental_id ?? null}
                rules={[{ required: true }]}
            >
                <SupplementalSelect
                    defaultValue={fluidToUpdate?.supplemental_id ?? null}
                    onChange={(value) => form.setFieldsValue({ supplemental_id: value })}
                />
            </Form.Item>

            <Divider />

            <Form.Item label='Product Category' name='product_category_id' initialValue={fluidToUpdate?.product_category_id ?? null}>
                <ProductCategorySelect
                    defaultValue={fluidToUpdate?.product_category_id ?? null}
                    onChange={(value) => form.setFieldsValue({ product_category_id: value })}
                />
            </Form.Item>

            <Form.Item label='Sort Rank' name='sort_rank' initialValue={fluidToUpdate?.sort_rank ?? 0} rules={[{ required: true }]}>
                <InputNumber />
            </Form.Item>

            <Form.Item
                label='Has Commission'
                name='has_commission'
                initialValue={fluidToUpdate?.has_commission ?? true}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Default
            </Divider>

            <Form.Item
                label='Rate ml/hr'
                name='default_rate_mcl_per_hr'
                initialValue={fluidToUpdate?.default_rate_mcl_per_hr ? roundTo(fluidToUpdate?.default_rate_mcl_per_hr / 1000, 2) : null}
            >
                <InputNumber />
            </Form.Item>

            <Form.Item label='Volume ml' name='default_volume_ml' initialValue={fluidToUpdate?.default_volume_ml ?? null}>
                <InputNumber />
            </Form.Item>
        </>
    );
};
