import { Dispatch, useEffect, useState } from 'react';
import { format } from 'utils/helpers';
import { Dictionary } from 'utils/types';
import useDebounce from './useDebounce';
import useQueryParams from './useQueryParams';

interface FilterQuery {
    query: string;
    order: string;
}

interface UseTableProps {
    onFilter: (queries: FilterQuery) => void;
    additional?: {};
    debounceRate?: number;
}

interface UseTableResponse extends Dictionary<any> {
    handleChange: (pagination: any, filters: any, sorter: any) => void;
    searchQuery: string;
    setSearchQuery: Dispatch<any>;
}

const useTableFilters = ({ onFilter, additional = {}, debounceRate = 400 }: UseTableProps, dependencies: any[] = []): UseTableResponse => {
    const { queries, setQuery } = useQueryParams();

    // Set any custom queries from the outset.
    useEffect(() => {
        setQuery(additional);
    }, []);

    const [searchQuery, setSearchQuery] = useState(queries.hasOwnProperty('query') ? queries.query : '');

    useDebounce(
        () => {
            setQuery({ ...queries, query: searchQuery });
        },
        [searchQuery],
        debounceRate,
    );

    useEffect(() => {
        onFilter(queries);
    }, [queries, ...dependencies]);

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        let order = '';

        if (typeof sorter.order !== 'undefined') {
            order = `${sorter.order === 'ascend' ? '-' : ''}${sorter.field}`;
        }

        setQuery({
            order,
        });
    };

    let additionalHooks = Object.keys(additional).reduce((prev, k: string) => {
        prev[`set${format.capitalizeStr(k)}Param`] = (val: any) => {
            setQuery({ ...queries, [k]: val });
        };
        return prev;
    }, {} as Dictionary<(val: any) => void>);

    return {
        handleChange,
        searchQuery,
        setSearchQuery,
        ...additionalHooks,
        ...queries,
    };
};

export default useTableFilters;
