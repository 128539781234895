import { Form, Input, Modal, Select } from 'antd';
import { useSuccessMessage } from 'hooks';
import { useGetHospitalsQuery } from 'services/hospitals.service';
import { CreateUserParams, useCreateUserMutation, useGetRolesQuery } from 'services/users.service';
import { BaseModalProps, Hospital, QueryResponse, REGULAR_EXPRESSIONS, VegRole } from 'utils/types';

enum NEW_USER_FIELDS {
    EMAIL = 'email',
    PHONE_NUMBER = 'phone_number',
    FIRST_NAME = 'first_name',
    LAST_NAME = 'last_name',
    ROLE_ID = 'role_id',
    OKTA_SUBJECT = 'okta_subject',
    PAYROLL_ID = 'payroll_id',
    HOSPITAL_BYPASS = 'hospital_bypass',
    ASSIGNED_HOSPITALS = 'assigned_hospitals',
}

interface AddUserModalProps extends BaseModalProps {}

const AddUserModal: React.FC<AddUserModalProps> = ({ isOpen, setIsOpen }) => {
    const [form] = Form.useForm<CreateUserParams>();
    const { data: userRoles } = useGetRolesQuery(null);
    const { data: hospitals } = useGetHospitalsQuery<QueryResponse<Hospital[]>>({});
    const [createUser, { isSuccess, isError, isLoading }] = useCreateUserMutation();
    const initalUserState: CreateUserParams = {
        [NEW_USER_FIELDS.EMAIL]: '',
        [NEW_USER_FIELDS.PHONE_NUMBER]: '',
        [NEW_USER_FIELDS.FIRST_NAME]: '',
        [NEW_USER_FIELDS.LAST_NAME]: '',
        [NEW_USER_FIELDS.ROLE_ID]: userRoles?.find((role) => role.name === 'Regular user')?.role_id ?? null,
        [NEW_USER_FIELDS.OKTA_SUBJECT]: null,
        [NEW_USER_FIELDS.PAYROLL_ID]: null,
        [NEW_USER_FIELDS.HOSPITAL_BYPASS]: false,
        [NEW_USER_FIELDS.ASSIGNED_HOSPITALS]: [],
    };

    const handleClose = () => {
        setIsOpen(false);
        form.resetFields();
    };

    useSuccessMessage({
        success: {
            status: isSuccess,
            message: `New user '${form.getFieldValue(NEW_USER_FIELDS.FIRST_NAME)} ${form.getFieldValue(
                NEW_USER_FIELDS.LAST_NAME,
            )}' has been succesfully created.`,
            callback: handleClose,
        },
        error: {
            status: isError,
            message: `It seems there has been an issue adding '${form.getFieldValue(NEW_USER_FIELDS.FIRST_NAME)} ${form.getFieldValue(
                NEW_USER_FIELDS.LAST_NAME,
            )}' as a user, please try again.`,
        },
    });

    const handleSave = (values: CreateUserParams) => {
        createUser({
            ...values,
            // Set phone number to null if not entered.
            phone_number: typeof values.phone_number === 'string' && !values.phone_number.length ? null : values.phone_number,
            hospital_bypass: false,
        });
    };

    return (
        <Modal title='Add User' open={isOpen} onOk={form.submit} onCancel={handleClose} okText='Save' confirmLoading={isLoading}>
            <Form
                form={form}
                name='basic'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={initalUserState}
                onFinish={handleSave}
            >
                <Form.Item
                    label='Email'
                    name={NEW_USER_FIELDS.EMAIL}
                    rules={[
                        {
                            required: true,
                            message: 'Email is required.',
                        },
                        { type: 'email', message: 'The input is not a valid email.' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='First Name'
                    name={NEW_USER_FIELDS.FIRST_NAME}
                    rules={[
                        {
                            required: true,
                            message: 'First name is required.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Last Name'
                    name={NEW_USER_FIELDS.LAST_NAME}
                    rules={[
                        {
                            required: true,
                            message: 'Last name is required.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Phone No.'
                    name={NEW_USER_FIELDS.PHONE_NUMBER}
                    rules={[
                        {
                            pattern: RegExp(REGULAR_EXPRESSIONS.PHONE_NO),
                            message: "Please ensure the user's phone number has the correct format.",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label='Role ID' name={NEW_USER_FIELDS.ROLE_ID}>
                    <Select>
                        {userRoles?.map((role: VegRole) => {
                            return (
                                <Select.Option key={role.name} value={role.role_id}>
                                    {role.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label='Locations' name={NEW_USER_FIELDS.ASSIGNED_HOSPITALS}>
                    <Select mode='multiple'>
                        {hospitals?.map((hospital: Hospital) => {
                            return (
                                <Select.Option key={hospital.display_name} value={hospital.id}>
                                    {hospital.display_name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label='Okta Subject' name={NEW_USER_FIELDS.OKTA_SUBJECT}>
                    <Input />
                </Form.Item>
                <Form.Item label='Payroll ID' name={NEW_USER_FIELDS.PAYROLL_ID}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddUserModal;
