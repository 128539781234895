import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Typography } from 'antd';
import { Breadcrumb, Button, Content, Space, SpacedContent, Table, Title } from 'components/lib';
import { useTableFilters } from 'hooks';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetHospitalsQuery } from 'services/hospitals.service';
import { format } from 'utils/helpers';
import { Hospital, QueryResponse, ROUTES } from 'utils/types';
import './HospitalsListing.css';
import ManageHospitalModal from './ManageHospitalModal';

const HospitalsListing: React.FC = () => {
    const [addHospitalModalOpen, setAddHospitalModalOpen] = useState(false);
    const [search, setSearch] = useState('');
    const history = useHistory();

    const columns = [
        {
            title: '',
            key: 'action',
            dataIndex: 'id',
            render: (id: string) => {
                return <Button onClick={() => viewHospital(id)}>View</Button>;
            },
            width: 100,
        },
        {
            title: 'Name',
            dataIndex: 'display_name',
            key: 'display_name',
            sorter: true,
            width: 200,
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Phone No.',
            dataIndex: 'phone_number',
            key: 'phone_number',
            ellipsis: true,
            width: 220,
            render: (phone_no: string) => format.formatPhoneNumber(phone_no),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
        },
    ];

    let { data, refetch } = useGetHospitalsQuery<QueryResponse<Hospital[]>>({});

    const filteredData = data?.filter((hospital) => {
        if (search === '') return hospital;

        const regex = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi');

        return hospital.display_name.match(regex);
    });

    const { handleChange } = useTableFilters({
        onFilter: refetch,
        additional: {
            state: '',
            uniq: false,
        },
    });

    const viewHospital = (id: string) => {
        history.push(ROUTES.addID(ROUTES.HOSPITAL_DETAIL, id));
    };

    if (typeof data === 'undefined') {
        return null;
    }

    return (
        <>
            <Breadcrumb path={['Hospitals', 'Dashboard']} />
            <Content>
                <Title level={2}>
                    <SpacedContent>
                        Hospitals
                        <Button onClick={() => setAddHospitalModalOpen(true)}>
                            <PlusOutlined />
                            Add Hospital
                        </Button>
                    </SpacedContent>
                </Title>
                <Space direction='vertical'>
                    <Typography.Text>{data.length} items</Typography.Text>
                    <Space>
                        <Input
                            placeholder='Search Hospitals'
                            suffix={<SearchOutlined />}
                            value={search}
                            style={{ width: 280 }}
                            onChange={({ target }) => setSearch(target.value)}
                        />
                    </Space>

                    <Table columns={columns} data={filteredData ?? []} onChange={handleChange}></Table>
                </Space>
            </Content>
            <ManageHospitalModal setIsOpen={setAddHospitalModalOpen} isOpen={addHospitalModalOpen} />
        </>
    );
};

export default HospitalsListing;
