import { PriceOverride } from 'utils/types';
import emptySplitApi, { PRICE_OVERRIDE_TAG_TYPE, TASK_TAG_TYPE } from './emptySplitApi';
import { LocationSpecificItem } from './locationSpecificItems.service';

export interface Task {
    id: number;
    name: string;
    has_commission: boolean;
    default_duration_mins: number | null;
    default_frequency: string | null;
    base_price_cents: number;
    product_category_id?: number | null;
    product_category_name?: number;
    sort_rank?: number;
    is_pricing_relative: boolean;
    avalara_tax_code: string | null;
    serial?: boolean;
    serial_hours?: number;
    base_serial_price_cents: number;
}

export interface CreateTask extends Omit<Task, 'product_category_name' | 'id'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}
export interface UpdateTask extends Omit<Task, 'product_category_name'> {
    avalara_tax_code: string | null;
    specific_locations: Array<LocationSpecificItem>;
    base_price_dollars?: string;
    base_price_dollars_serial?: string;
}

export const tasksApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getTasks: builder.query<Task[], void>({
            query: () => `task`,
            transformResponse: (response: { data: Task[] }) => {
                return response.data;
            },
            providesTags: () => [{ type: TASK_TAG_TYPE, id: 'LIST' }],
        }),
        getTaskPriceOverrides: builder.query<PriceOverride[], number>({
            query: (id) => `task/${id}/price`,
            transformResponse: (response: { data: PriceOverride[] }) => {
                return response.data;
            },
            providesTags: (result, error, id) => [{ type: PRICE_OVERRIDE_TAG_TYPE, id }],
        }),
        createTask: builder.mutation<void, CreateTask>({
            query({ base_price_cents, ...body }) {
                return {
                    url: 'task',
                    method: 'POST',
                    body: {
                        ...body,
                        base_price_cents: base_price_cents,
                    },
                };
            },
            invalidatesTags: () => [{ type: TASK_TAG_TYPE, id: 'LIST' }],
        }),
        editTask: builder.mutation<void, UpdateTask>({
            query({ id, ...body }) {
                return {
                    url: `task/${id}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: () => [{ type: TASK_TAG_TYPE, id: 'LIST' }],
        }),
        deleteTask: builder.mutation<void, number>({
            query: (task_id) => ({
                url: `task/${task_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: () => [{ type: TASK_TAG_TYPE, id: 'LIST' }],
        }),
    }),
});

export const { useGetTasksQuery, useGetTaskPriceOverridesQuery, useDeleteTaskMutation, useEditTaskMutation, useCreateTaskMutation } =
    tasksApi;
