import { RuleObject } from 'antd/lib/form';

export const validateFieldIsGreaterThan =
    (name: string, numberToCompareAgainst: number = 0) =>
    async (_: RuleObject, value: any) => {
        // This isn't a required check so if the value is not set then we shouldn't fail
        if (value === null || value === undefined || value === '') {
            return Promise.resolve();
        }
        if (isNaN(value)) {
            return Promise.reject(new Error(`${name} must be a number`));
        }
        const numberValue = parseFloat(value);
        if (numberValue > numberToCompareAgainst) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error(`${name} must be greater than ${numberToCompareAgainst}`));
        }
    };

export const validateFieldIsLessThan =
    (name: string, numberToCompareAgainst: number = 0) =>
    async (_: RuleObject, value: any) => {
        // This isn't a required check so if the value is not set then we shouldn't fail
        if (value === null || value === undefined || value === '') {
            return Promise.resolve();
        }
        if (isNaN(value)) {
            return Promise.reject(new Error(`${name} must be a number`));
        }
        const numberValue = parseFloat(value);
        if (numberValue < numberToCompareAgainst) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error(`${name} must be less than ${numberToCompareAgainst}`));
        }
    };
