import { Divider, Form, Input, InputNumber, Radio, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { HiddenInput } from 'components/lib/formFields/HiddenInput';
import ProductCategorySelect from 'components/lib/ProductCategorySelect';
import { Diagnostic, useListResultValidationQuery, useListResultWidgetQuery } from 'services/diagnostics.service';
import { INSTRUCTION_FREQUENCY_REGEX } from 'utils/consts';

interface DiagnosticTabProps {
    diagnosticToUpdate: Diagnostic | null;
    form: FormInstance;
}

export const DiagnosticTab = ({ diagnosticToUpdate, form }: DiagnosticTabProps) => {
    const { data: resultWidgetList } = useListResultWidgetQuery();
    const { data: resultValidationList } = useListResultValidationQuery();

    return (
        <>
            {diagnosticToUpdate && <HiddenInput fieldName='id' initialValue={diagnosticToUpdate.id} />}

            <Form.Item label='Name' name='name' initialValue={diagnosticToUpdate?.name} rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item label='Category' name='category' initialValue={diagnosticToUpdate?.category ?? null}>
                <Input disabled={diagnosticToUpdate?.category === 'IDEXX_REF_LAB'} />
            </Form.Item>

            <Form.Item label='Result Label' name='result_label' initialValue={diagnosticToUpdate?.result_label ?? null}>
                <Input />
            </Form.Item>

            <Form.Item label='Result Unit' name='result_unit' initialValue={diagnosticToUpdate?.result_unit ?? null}>
                <Input />
            </Form.Item>

            <Form.Item
                label='Once Per Visit'
                name='one_at_a_time'
                initialValue={diagnosticToUpdate?.one_at_a_time ?? false}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label='Result Widget'
                name='result_widget_id'
                initialValue={diagnosticToUpdate?.result_widget_id}
                rules={[{ required: true }]}
            >
                <Select>
                    {resultWidgetList?.map(({ name, id }) => (
                        <Select.Option key={id} value={id}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label='Result Validation'
                name='validator_id'
                initialValue={diagnosticToUpdate?.validator_id}
                rules={[{ required: true }]}
            >
                <Select>
                    {resultValidationList?.map(({ name, id }) => (
                        <Select.Option key={id} value={id}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Divider />

            <Form.Item label='Product Category' name='product_category_id' initialValue={diagnosticToUpdate?.product_category_id ?? null}>
                <ProductCategorySelect
                    defaultValue={diagnosticToUpdate?.product_category_id ?? null}
                    onChange={(value) => form.setFieldsValue({ product_category_id: value })}
                />
            </Form.Item>

            <Form.Item label='Sort Rank' name='sort_rank' initialValue={diagnosticToUpdate?.sort_rank ?? 0} rules={[{ required: true }]}>
                <InputNumber />
            </Form.Item>

            <Form.Item
                label='Has Commission'
                name='has_commission'
                initialValue={diagnosticToUpdate?.has_commission ?? true}
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Divider orientation='left' orientationMargin='0'>
                Default
            </Divider>

            <Form.Item
                label='Frequency'
                name='default_frequency'
                initialValue={diagnosticToUpdate?.default_frequency ?? null}
                rules={[{ pattern: INSTRUCTION_FREQUENCY_REGEX }]}
            >
                <Input />
            </Form.Item>
        </>
    );
};
