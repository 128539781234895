import { Complaint, HospitalMedicine } from 'utils/types';

export const CLINICS: {
    id: string;
    name: string;
    email: string;
    phone_no: string;
    fax_no: string;
    street: string;
    city: string;
    state: string;
    zip_code: number;
    last_marketing_interaction_date: string;
    hospital_Id: string;
    trailing_year_no_of_visits: number;
    trailing_year_revenue: number;
}[] = [];
export const EMPLOYEES: {
    id: string;
    name: string;
    company: string;
    email: string;
    phone: string;
    role: string;
}[] = [];

export const CLINIC_VISITS: {
    visit_id: string;
    hospital_id: string;
    owner_name: string;
    pet_name: string;
    visited_on: string;
    hospital_name: string;
}[] = [];

export const HOSPITALS: {
    id: string;
    slug: string;
    display_name: string;
    phone_number: string;
    street: string;
    city: string;
    state: string;
    zipcode: number;
    created_at: number;
    updated_at: number;
}[] = [];

export const HOSPITAL_MEDICINES: HospitalMedicine[] = [];

export const CURRENT_USER = {
    id: '21049ut08h20398thg49',
    firstName: 'Andrew',
    lastName: 'Phillips',
    hospitalId: '60ec6a4633622a4482e99813',
};

export const COMPLAINTS: Complaint[] = [];

export const DIFFERENTIALS: {
    id: string;
    name: string;
}[] = [];
