import { useEffect, useState } from 'react';

const BREAK_POINTS = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};

interface BreakpointState {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
    xxl: boolean;
}

const calcBreakpoints = (width: number): BreakpointState => {
    return (Object.keys(BREAK_POINTS) as Array<keyof typeof BREAK_POINTS>).reduce((previous: BreakpointState, current) => {
        let breakPoint = BREAK_POINTS[current];
        previous[current] = breakPoint > width;
        return previous;
    }, {} as BreakpointState);
};

const useMediaQuery = (): BreakpointState => {
    const [breakPoints, setBreakPoints] = useState(calcBreakpoints(window.innerWidth));

    useEffect(() => {
        const handleWindowResize = () => {
            setBreakPoints(calcBreakpoints(window.innerWidth));
        };
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return breakPoints;
};

export default useMediaQuery;
